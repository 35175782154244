<div class="container-fluid headerfixed">
  <app-header></app-header>
</div>
<div class="container-fluid contentone">
  <div class="row sidebarrow">
      <app-side showOperation=true></app-side>
  </div>
  <!-- #region -->

  <div class="container-fluid body-card">
    <div class="row row-eq-height ">
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <div class="row">
              <div class="col-6 pl-0">
                <h3   class="card-title text-dark ">Available Trips </h3>
              </div>
              <div class="col-6 text-right">
                <ion-button mat-icon-button class="text-right"  aria-label="Toggle menu" (click)="createTrip()">
                  <mat-icon>add</mat-icon>
                </ion-button>
              </div>
            </div>
            <app-current-trips></app-current-trips>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <div class="row">
              <div class="col-12 pl-0">
                <h3   class="card-title text-dark">Booked Passengers </h3>
              </div>
              <div class="col-6 text-right">
                <!-- <ion-button mat-icon-button class="text-right"  aria-label="Toggle menu" (click)="createTrip()">
                  <mat-icon>add</mat-icon>
                </ion-button> -->
              </div>
            </div>
            <app-trip-passengers></app-trip-passengers>
          </div>
        </div>
      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <!-- <div class="row">
              <div class="col-12">
                <h3   class="card-title text-dark">Bookings </h3>
              </div>
              <div class="col-6 text-right">
              </div>
            </div> -->
            <app-bookings-table></app-bookings-table>
          </div>
        </div>
      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <!-- <div class="row">
              <div class="col-12">
                <h3   class="card-title text-dark">Closed Trips</h3>
              </div>
              <div class="col-6 text-right">
              </div>
            </div> -->
            <app-closed-trips></app-closed-trips>
          </div>
        </div>
      </div>

    </div>
  </div>





