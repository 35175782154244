import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import { DataServicesService } from 'src/app/services/data-services.service';
import { UUID } from 'angular2-uuid';

import Swal from 'sweetalert2'


import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-view-vehicle-type',
  templateUrl: './view-vehicle-type.component.html',
  styleUrls: ['./view-vehicle-type.component.scss']
})
export class ViewVehicleTypeComponent implements OnInit {

  isUpdate = false;
  fileName = '';
  vehicleType = '';
  vehicleModel = '';

    // Seat Configuration
 totalPassengers = 48;  // Total number of passengers
 seatsPearRow = 4;     // Normal seat per row
 seatNumBeforeSpace = [];  // Seat number before empty spaces, put all the seats number in array
 columnBeforeIsle = 3;    //Column number before Isle(Walking space)
 numberOfFrontSeats = 1;
 beforeStart = 2; // this.seatsPearRow - this.numberOfFrontSeats;      // Number of space before seat number 1 (Driver seat + empty seat before passenger);
 seatsLayout = [];
 unusedSeat = 0;
 vehicle = {
   vehicleModel: '',
   vehicleType: '',
   totalPassengers: 0,
   seatColSize: 3,
   seatsLayout: [],
   id: UUID.UUID(),
   image: '',
   log: [],
   stationId:'',
   enrolledDate:0

 };
 noBeforeEmpty = '0';
 loading = false;
 importantNotes;
// Used in the template for col size
 seatColSize = 11 / this.seatsPearRow;
 documents = [];
 public managerPermission;

 form: FormGroup = new FormGroup({
  vehicleModel: new FormControl('', Validators.required),
  vehicleType: new FormControl('', Validators.required),
  totalPassengers: new FormControl(0, Validators.required),
  seatsPearRow: new FormControl(0, Validators.required),
  numberOfFrontSeats: new FormControl(0, Validators.required),
  columnBeforeIsle: new FormControl(),
  noBeforeEmpty: new FormControl(''),
  importantNotes: new FormControl(''),
  unusedSeat:new FormControl(0),

});

  constructor( public dialogRef: MatDialogRef<ViewVehicleTypeComponent>, public dataService: DataServicesService,private storage: AngularFireStorage, private authService: AuthServicesService) { }

  ngOnInit(): void {
    this.managerPermission = this.authService.userDetails.permissions;
    if(this.dataService.openedVehicleType){
      this.vehicle = this.dataService.openedVehicleType
    }

    if(this.dataService.openedVehicleType){
      this.vehicleModel = this.vehicle.vehicleModel;
      this.vehicleType = this.vehicle.vehicleType;
      this.totalPassengers = this.vehicle.totalPassengers;
      this.seatsLayout = this.vehicle.seatsLayout;
      this.seatColSize = this.vehicle.seatColSize;
      this.isUpdate = true;
    }
  }

  cardCol(size){
    // console.log(size)
    const col = 'col-' + Math.round(size) + ' text-center';
    return col.toString() ;
  }

  onClose() {
    this.dialogRef.close();
  }

  createSeats($evt) {
    // console.log($evt)
    this.beforeStart = this.seatsPearRow - this.numberOfFrontSeats;
    this.seatColSize = 11 / this.seatsPearRow;
    const numOfSeats = this.totalPassengers;
    const seatsArray = [];
    const beforeEmptySpace = {};
    this.seatsLayout = [];
    let emptyCount = 0;
    this.seatNumBeforeSpace = [];
    if (this.noBeforeEmpty){
      // console.log(this.noBeforeEmpty);
      const empty = this.noBeforeEmpty.split(',');
      empty.forEach(no => {
        this.seatNumBeforeSpace.push(Number(no));
      });
    }

    this.seatNumBeforeSpace.forEach(seatNum => {
      beforeEmptySpace[seatNum] = true;
    });
    const beforeStart = this.beforeStart;
    let colNum = 0;
    let isleAfter = false;
    for (let seat = 0; seat < numOfSeats + beforeStart + this.seatNumBeforeSpace.length; seat++ ) {
      colNum++;
      // console.log('Col ', colNum  );
      if (colNum === this.columnBeforeIsle) {
        isleAfter = true;
        // console.log('Before Isle ', seat + 1 - beforeStart  );
      } else {
        isleAfter = false;
      }
      if (colNum  === this.seatsPearRow) {
        colNum = 0;
      }

      if (seat < beforeStart) {
        seatsArray.push({
          seatNumber: 'emptySpace',
          isleAfter
        });

      }  else if (beforeEmptySpace[seat - beforeStart]) {
        seatsArray.push({
          seatNumber: 'emptySpace',
          isleAfter
        });
        emptyCount++;
      } else if ((this.unusedSeat > 0 ) &&((seat + 1 - beforeStart - emptyCount) >= this.unusedSeat)){
        seatsArray.push({
          seatNumber: seat + 1 - beforeStart - emptyCount + 1 ,
          seatCode: '',
          passengerName: '',
          isleAfter,
          index: seat,
          status: 'Available'
        });
      } else {
        seatsArray.push({
          seatNumber: seat + 1 - beforeStart - emptyCount ,
          seatCode: '',
          passengerName: '',
          isleAfter,
          index: seat,
          status: 'Available'
        });
      }

    }
    this.seatsLayout = seatsArray;
    // console.log(this.vehicle)
    this.vehicle['seatColSize'] = this.seatColSize;
    this.vehicle['seatsLayout'] = seatsArray;
    this.vehicle['totalPassengers'] = this.totalPassengers;
  }

  SaveImageRef(filePath, file) {
    return {
      task: this.storage.upload(filePath, file)
      , ref: this.storage.ref(filePath)
    };
  }
  chooseImage($event){
    const datefolder = new Date().toISOString().substr(0, 10).replace('-', '');
    // console.log($event);
    this.loading = true;
    // this.util.openInfLoader();
    const pickedFile = (event.target as HTMLInputElement).files[0];
    if (pickedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(pickedFile);
      // For Preview Of Image
      reader.onload = (e:any) => { // called once readAsDataURL is completed
        // For Uploading Image To Firebase
        const fileraw = pickedFile;
        // console.log(fileraw);
        // this.imageFile = fileraw.name;
        const filePath = '/vehicledocuments/' + this.authService.userDetails.stationId + '/vehicleImage_' + Date.now();
        const result = this.SaveImageRef(filePath, fileraw);
        const ref = result.ref;
        result.task.then(a => {
          ref.getDownloadURL().subscribe( a => {

            this.documents.push({
              name: this.fileName,
              link: a
            });
            this.vehicle['image'] = a;
            this.loading = false;
            // this.util.closeLoading();
            this.fileName = '';
          });

        });
      }, error => { alert('Error')
      // this.util.closeLoading();
    }
    }

  }


  async chageSeatNum(seatIndex) {
    Swal.fire({
      // title: 'Change the Current Seat Number?',
      inputLabel: 'New Seat Number',
      input: 'number',
      // inputAttributes: {
      //   autocapitalize: 'off'
      // },
      showCancelButton: true,
      confirmButtonText: 'Change',
      confirmButtonColor:  '#4d9301',
      width:'15%',
      background:'#d6dde0',
      showLoaderOnConfirm: true,
      preConfirm: (newSeatNo) => {
        return this.seatsLayout[seatIndex].seatNumber = Number(newSeatNo);
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log('newSeatNo')

    })


  }

  async chageSeatNum2(index){
    const { value: newIndex } = await Swal.fire({
      title: 'Enter your IP address',
      input: 'text',
      inputLabel: 'Your IP address',
      // inputValue: inputValue,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }
      }
    })

    if (newIndex) {
      this.seatsLayout[index].seatNumber = Number(newIndex);
    }
  }

  async addVehicleType(){
    const update = {
      date: Date.now(),
      action: 'Enrollment from dashboard',
      userId: this.dataService.userDetails.id,
      userEmail: this.dataService.userDetails.email,
      userName: this.dataService.userDetails.username
    }
    this.vehicle['vehicleModel'] = this.vehicleModel;
    this.vehicle['vehicleType']= this.vehicleType;

    this.vehicle['log'].push(update);
    this.vehicle['stationId'] = this.dataService.userDetails.stationId;
    this.vehicle['enrolledDate'] = Date.now();


    const b1 = await this.dataService.create('vehicleType', this.vehicle);
    this.dataService.getFleet();
    this.dataService.getVehicleType();
    this.onClose();

   }

   async updateVehicleType(){
    const update = {
      date: Date.now(),
      action: 'Update from dashboard',
      userId: this.dataService.userDetails.id,
      userEmail: this.dataService.userDetails.email,
      userName: this.dataService.userDetails.username
    }

    this.vehicle['vehicleModel'] = this.vehicleModel;
    this.vehicle['vehicleType']= this.vehicleType;
    this.vehicle['log'].push(update);
    const b1 = await this.dataService.create('vehicleType', this.vehicle);
    this.dataService.getFleet();
    this.dataService.getVehicleType();
    this.onClose();

   }


}
