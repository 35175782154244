<div class="container-fluid headerfixed">
  <app-header></app-header>
</div>
<div class="container-fluid contentone">
  <div class="row sidebarrow">
      <app-side showConfiguration=true></app-side>
  </div>


  <div class="container-fluid body-card">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <!-- <h3   class="card-title text-dark">Company Profile </h3> -->
              </div>
              <div class="col-6 text-right">

              </div>
            </div>
            <app-station-data></app-station-data>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary mat-elevation-z8"  style="height: 100%">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
              </div>
              <div class="col-6 text-right">

              </div>
            </div>
            <app-terminal-table></app-terminal-table>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary mat-elevation-z8"  style="height: 100%">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
              </div>
              <div class="col-6 text-right">

              </div>
            </div>
            <app-station-managers></app-station-managers>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary mat-elevation-z8"  style="height: 100%">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
              </div>
              <div class="col-6 text-right">

              </div>
            </div>
            <app-route-table></app-route-table>
          </div>
        </div>
      </div>



    </div>
  </div>
