import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataServicesService } from 'src/app/services/data-services.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import Swal from 'sweetalert2'

import * as firebase from 'firebase/compat/app';
import { NotificationService } from 'src/app/services/notification.service';



@Component({
  selector: 'app-add-terminal',
  templateUrl: './add-terminal.component.html',
  styleUrls: ['./add-terminal.component.scss']
})
export class AddTerminalComponent implements OnInit {

  form: FormGroup = new FormGroup({
    // $key: new FormControl(null),
    stationName: new FormControl('', Validators.required),
    stationAddress: new FormControl('', Validators.required),
    stationPhone: new FormControl('08000000000', Validators.required)
  })

  terminals ;
  constructor(public dialogRef: MatDialogRef<AddTerminalComponent>, public dataService: DataServicesService,private authService: AuthServicesService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.dataService.stationData.subscribe(stationData => {
      if(stationData['stations']){
        // console.log(stationData['stations'])
        this.terminals = stationData['stations']

      }
  })
}

  onClose() {

    this.dialogRef.close();
  }

  onAdd(){

    Swal.fire({
      title: 'Add Terminal',
      text: "Are you sure you want to add this new terminal?",
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor:  '#4d9301',
      width:'15%',
      confirmButtonText: 'Confirm',
      background:'#d6dde0',
    }).then(async (result) => {
      if (result.isConfirmed) {

        const newStation = {
          stationName: this.form.value.stationName,
            stationAddress: this.form.value.stationAddress,
            stationPhone: this.form.value.stationPhone,
            addDate: Date.now(),
            manager: this.authService.userDetails.username
        }


        this.terminals.push(newStation)
        console.log(this.terminals)
        const accessToken = await firebase.default.auth().currentUser.getIdToken();

        this.form.reset()
        this.dataService.updateTerminal(this.terminals, accessToken).subscribe(
          async (res) => {
           console.log(res);
          //  this.util.confirmDialogPassingparameter(res['paymentStatus'], res['message']);
           this.notificationService.success('::'+res['status']+' - '+res['message'] );
           this.onClose();
          });

      }
    })
  }

}
