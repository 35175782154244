import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color,Label ,MultiDataSet} from 'ng2-charts';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-vehicles-analytics-chart',
  templateUrl: './vehicles-analytics-chart.component.html',
  styleUrls: ['./vehicles-analytics-chart.component.scss']
})
export class VehiclesAnalyticsChartComponent implements OnInit {



  color = ['#ed681d', '#F8CA0F', '#a32727' , '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600','#743411','#0c5922',
            '#bea413','#668d1c','#2a778d','#a9c413','#9c5935','#f4359e','#b91383','#16d620','#b77322','#3b3eac','#5574a6','#329262','#651067',
            '#8b0707','#e67300','#6633cc','#aaaa11','#22aa99','#994499','#3366cc','#316395','#b82e2e','#66aa00','#dd4477','#0099c6','#990099'];

  public barChartColors: Color[] = [
    {
      borderColor: ['#ed681d', '#F8CA0F', '#a32727' , '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'],
      backgroundColor: ['#ed681d', '#F8CA0F', '#a32727' , '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'],
      borderWidth: 3
    }
  ];


  doughnutChartLabels: Label[] = [];
  doughnutChartData: MultiDataSet = [
    []
  ];
  doughnutChartType: ChartType = 'doughnut';


  constructor(private dataService: DataServicesService) { }

  ngOnInit(): void {
    this.dataService.fleetAnalyticsCard.subscribe(data =>{
      if(data){
        const analytics = Object.entries(data)
        analytics.forEach((entry,ind) =>{
          this.doughnutChartLabels[ind]=entry[0]
          this.doughnutChartData[0][ind]=entry[1]
        })
      }
    })
  }

}
