import { registerLocaleData } from '@angular/common';
import { Component, OnInit,AfterViewInit ,ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-expense-summary-table',
  templateUrl: './expense-summary-table.component.html',
  styleUrls: ['./expense-summary-table.component.scss']
})
export class ExpenseSummaryTableComponent implements OnInit,AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;


  bookingRevenue = [];
  bookingCount = [];

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['entry_date','expense_amount','view'];

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 10;
  constructor(private dataService: DataServicesService) { }

  ngOnInit(): void {

    // this.dataService.annualBookingRevenueData_.subscribe(rec  =>{
    //   this.bookingRevenue = rec;
    // });
    this.dataService.expenseSummary.subscribe(rec=>{
      // this.bookingCount = rec;
      this.tableData = [];
      rec.forEach((val,index)=>{
        this.tableData.push(val)
      })
      // console.log(this.tableData)
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;


    })




  }

  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  startDateChange($evt){

  }

  endDateChange($evt){

  }

  openExpense(expenses){

  }


  download(expenses){
    let entryDate ;
    const headerList = ['Entry Date','Expense Date', 'Vehicle' ,'Station', 'Manager','Category','Description','Amount']
      const objArray = [];
      // let passenger = '';
      let station = '';
      let description = '';
      let manager = '';

      expenses.forEach(req => {
        // passenger = req.passengerName;
        // console.log(req)
        station = req.expenseStation;
        description = req.expenseDescription
        manager = req.requesterName

        // passenger = passenger.replace(/,/g, ';')
        // tripTitle = tripTitle.replace(/,/g, ';')
        description = description.replace(/,/g, ';')
        manager = manager.replace(/,/g, ';')
        station = station.replace(/,/g, ';')

        const oneEntryDate = new Date(req.extryDate);

        const oneExpenseDate = new Date(req.expenseDate);
        const entryDateComponents = oneEntryDate.toLocaleString().split(' ');
        const expenseDateComponents = oneExpenseDate.toLocaleString().split(' ');
        // console.log(dateComponents)
        // const tripDay = dateComponents[0] + ', ' + dateComponents[1] + ' ' + dateComponents[2] + ' ' + dateComponents[3];
        entryDate = new Date(Number(req.expenseDate)).toDateString()
        let oneRequest = {
          'Entry Date': entryDateComponents[1] + ' '+ new Date(Number(req.extryDate)).toDateString(),
          'Expense Date': new Date(Number(req.expenseDate)).toDateString(),
          'Vehicle': req.vehicleReg+' - '+ req.fleetId,
          'Station' : station,
          'Manager' : manager,
          'Category':req.expenseType,
          'Description' : description,
          'Amount' : req.expenseAmount,

        }
        objArray.push(oneRequest)
      })

      if(objArray.length){
        const data = this.ConvertToCSV(objArray,headerList);
        const fileName = 'Expenses For '+entryDate;
        this.downloadFile(data,fileName)
      }

  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
     row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
     let line = (i+1)+'';
     for (let index in headerList) {
      let head = headerList[index];
      line += ',' + array[i][head];
     }
     str += line + '\r\n';
    }
    return str;
   }

  downloadFile(csvData, filename) {
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

}
