import { Component, OnInit,OnDestroy } from '@angular/core';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import { DataServicesService } from 'src/app/services/data-services.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit,OnDestroy {

  title = 'New Dashboard'
  bookings;
  amountPaid = 0;
  todayAmountPaid = 0;
  activeRoute = 0;
  todayActiveRoute = 0;
  totalPassegers = 0;
  todayTotalPassegers = 0;
  totalTrip  = 0;
  todayTotalTrip  = 0;
  returningPassegers = 0;
  todayReturningPassegers = 0;

  miniCardData = [];
  reportStartTime = 0;
  reportEndTime = Date.now();
  spinner  = true;
  colorCount = 0;
  today = false;

  private authSubscription$: Subscription;
  private bookingSubscription$: Subscription;
  private spinnerSubscription$: Subscription;

  constructor(public  dataService: DataServicesService, private authService: AuthServicesService) { }

  ngOnInit(): void {
    this.minicardData();
    console.log('Called Init')
    if (!this.dataService.bookingsData || this.dataService.bookingsData.length === 0 ) {
      this.authSubscription$ = this.authService.userData.subscribe( userData => {
        if (userData){
          // console.log(userData)
          const stationId = 'stationId';
          const Id = userData[stationId];
          this.dataService.getBookings();
          this.dataService.getOtherInitialData();
        }
      });
    }

    this.spinnerSubscription$  = this.dataService.spinnerBehaviour.subscribe(res=>{
      // console.log(res, Date.now())
      this.spinner  = res
    })
  }

  public ngOnDestroy(): void {
    // this.authSubscription$.unsubscribe();
    // this.bookingSubscription$.unsubscribe();
    // this.spinnerSubscription$.unsubscribe();
  }


  minicardData(){


   this.dataService.miniCards.subscribe(cards => {
    if(cards){
      this.colorCount=0;
      // console.log( 'Minicards', cards)
      this.miniCardData = cards;
      // console.log(this.miniCardData[0])
      this.dataService.spinnerBehaviour.next( false);
      this.today = false;
      this.miniCardData.forEach(data =>{
        if(data.today){
          this.today = true;
          console.log(data.today)
        }
      })
      console.log(this.today)
    }

    })

  }

  cardStyle(color) {
    // if (color && this.colorCount<4) {
    //   console.log(color)
    //   this.colorCount+=1;
    //   return { 'background-color': color }
    // }
  }



 bookingRevenueDownload(){
  const annualBookingRevenueData = this.dataService.annualBookingRevenueData;
  const annualBookingCountData = this.dataService.annualBookingCountData;

  const headerList = ['Booking Date','Booking Count','Revenue']

  const objArray = [];
  annualBookingRevenueData.forEach((req,index) => {
    let oneDay = {
      'Booking Date': req[0],
      'Revenue': Number(req[1]),
      'Booking Count':  Number(annualBookingCountData[index][1])
    }
    objArray.push(oneDay)
  })

  if(objArray.length){
    const data = this.ConvertToCSV(objArray,headerList);
    const fileName =  'Booking Revenue';//+new Date().toLocaleTimeString();
    this.downloadFile(data,fileName)
  }


 }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
     row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
     let line = (i+1)+'';
     for (let index in headerList) {
      let head = headerList[index];
      line += ',' + array[i][head];
     }
     str += line + '\r\n';
    }
    return str;
   }

  downloadFile(csvData, filename) {
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }



}
