import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { DataServicesService } from 'src/app/services/data-services.service';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-closed-trips',
  templateUrl: './closed-trips.component.html',
  styleUrls: ['./closed-trips.component.scss']
})
export class ClosedTripsComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 5;

  public tripList = [];
  public userDetails;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'vehicleType',
    'tripTitle',
    'originDetails',
    // 'destinationDetails',
    'totalSeats',
    'availableSeats',
    'paidPassengers',
    'amount',
    'createdDate',
    'downloadBtn'
  ];

  reportStartTime = 0;
  reportEndTime = Date.now();
  originalTripList = [];

  constructor(private dataService: DataServicesService, private authService: AuthServicesService, private notificationService: NotificationService){

  }

  ngOnInit() {
    this.authService.userData.subscribe(userDetails => {
      if (userDetails){
        this.userDetails = userDetails;
        this.dataService.getClosedTripDefault(this.userDetails.location).subscribe(closedTrips => {
        // this.dataService.closedTrips.subscribe(closedTrips => {
          if (closedTrips){
            this.tripList = [];
            closedTrips.forEach((data) => {
              if(data){
                // console.log(data)
                const oneTrip = data.payload.doc.data();
                this.tripList.push(oneTrip);
              }
            });

            this.tableData  = this.tripList;
            this.originalTripList = this.tripList;
            this.dataSource = new MatTableDataSource(this.tableData);
            this.dataLength = this.tableData.length;
            this.dataSource.paginator = this.paginator;
            // console.log(this.tripList[0])
          }
        });

      }
    });
  }

  ngAfterViewInit() {
    if (this.tableData){
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  startDateChange($event){
    if ($event) {
      // this.tripList = this.originalTripList;
      this.reportStartTime = new Date($event.value).valueOf();
      // const sortedTrip = this.tripList.filter(el=> {
      //   return (el.triptDate> this.reportStartTime) && (el.tripDate< this.reportEndTime)
      // })
      // this.tableData = sortedTrip;
      // this.dataSource = new MatTableDataSource(this.tableData);
      // this.dataLength = this.tableData.length;
      // this.dataSource.paginator = this.paginator;

    }
  }

  endDateChange($event){
    if ($event){
      // this.tripList = this.originalTripList;
      this.reportEndTime = new Date($event.value).valueOf() + (24 * 60 * 60 * 1000);
      // const sortedTrip = this.tripList.filter(el=> {
      //   return (el.tripDate> this.reportStartTime) && (el.tripDate< this.reportEndTime)
      // })
      // this.tableData = sortedTrip;
      // this.dataSource = new MatTableDataSource(this.tableData);
      // this.dataLength = this.tableData.length;
      // this.dataSource.paginator = this.paginator;


      this.dataService.getClosedTripRange(this.userDetails.location,this.reportStartTime,this.reportEndTime).subscribe(closedTrips => {
          if (closedTrips){
            this.tripList = [];
            closedTrips.forEach((data) => {
              if(data){
                const oneTrip = data.payload.doc.data();
                this.tripList.push(oneTrip);
              }
            });

            this.tableData  = this.tripList;
            this.originalTripList = this.tripList;
            this.dataSource = new MatTableDataSource(this.tableData);
            this.dataLength = this.tableData.length;
            this.dataSource.paginator = this.paginator;
          }
        });
    }
  }


  downloadManifest(trip){
    const agentConfirmation = (this.userDetails.terminalAddress === trip.originStationAddress) && ((this.userDetails.permissions.terminalAgent === true) || (this.userDetails.permissions.terminalLoadingAgent === true));

    const cutOffTime = trip.tripDate + (2 * 24 * 60 * 60 * 1000);

    if (((trip.paidPassengers.length > 0) && (cutOffTime > Date.now()) && (this.userDetails.status === 'active'))||((trip.paidPassengers.length > 0) && agentConfirmation && (this.userDetails.status === 'active')) || this.userDetails.permissions.superAdmin )  {

      const headerList = ['Passenger','Seat', 'Passenger Address' ,'Phone', 'Next of Kin','NOK Address','NOK Phone','Vehicle Id','Dest']
      const objArray = [];
      let passenger = '';
      let nok = '';
      let nokAddress = '';
      let nokPhone = '';
      let passengerAddr = '';
      trip.paidPassengers.forEach(req => {
        passenger = req.passengerName;
        passengerAddr = req.passengerAddress;
        nok = req.nokName
        nokAddress = req.nokAddress
        nokPhone = req.nokPhone
        passenger = passenger.replace(/,/g, ';')
        passengerAddr = passengerAddr.replace(/,/g, ';')
        nok = nok.replace(/,/g, ';')
        nokAddress = nokAddress.replace(/,/g, ';')
        nokPhone = nokPhone.replace(/,/g, ';')
        //  console.log(req.tripTitle.split('-')[1])
        // console.log('2',new Date(Number(req.requestTimeNum)))
        // console.log('3',new Date(Number(req.requestTimeNum)).toDateString())
        let oneRequest = {
          'Travel Date': new Date(Number(req.tripDate)).toDateString(),
          'Passenger': passenger,
          'Seat' : req.seatNumber,
          'Passenger Address' : passengerAddr,
          'Phone' : req.passengerPhone,
          'Next of Kin' : nok,
          'NOK Address' : nokAddress,
          'NOK Phone' : nokPhone,
          'Vehicle Id' : req.fleetId,
          'Dest':req.tripTitle.split('-')[1]
          // 'LogAgg Premium': this.getOther(req.amountPaid,req.providerCost,req.excessInsurance).toFixed(2),
          // 'Net Provider Payout': this.payable(req.providerCost).toFixed(2)
        }
        objArray.push(oneRequest)
      })

      if(objArray.length){
        const data = this.ConvertToCSV(objArray,headerList);
        const fileName = trip.originStation+'_'+trip.tripTitle+'_'+trip.originStationAddress+'_'+trip.vehicleReg+ '_'+new Date(trip.tripDate).toDateString()+ '_'+new Date(trip.tripDate).toLocaleTimeString();
        this.downloadFile(data,fileName)
      }

    } else {
      this.notificationService.warn('!! Permission Denied');

    }
    }

    ConvertToCSV(objArray, headerList) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = 'S.No,';
      for (let index in headerList) {
       row += headerList[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
       let line = (i+1)+'';
       for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
       }
       str += line + '\r\n';
      }
      return str;
     }

    downloadFile(csvData, filename) {
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
}
