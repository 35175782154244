import { Component, OnInit } from '@angular/core';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-station-data',
  templateUrl: './station-data.component.html',
  styleUrls: ['./station-data.component.scss']
})
export class StationDataComponent implements OnInit {

  stationData;
  constructor(private dataService: DataServicesService) {

  }

  ngOnInit(): void {
    this.dataService.stationData.subscribe(stationData => {
      this.stationData = stationData;
    })
  }

  cardStyle() {

    return { 'background-color': this.stationData.color };
  }

}
