<mat-toolbar class="top-bar">
  <span class="page-title">Online Bookings - {{bookings[0].paymentDate | date:'mediumDate'}}  </span>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>
<mat-dialog-content>

  <!-- <div class="hor-seperator "></div> -->

  <div class="row ml-1 mr-1 mt-1">
    <div class="col text-left" >
        <h4 class="title-text"> Passenger </h4>
    </div>
    <div class="col">
        <!-- <h4 class="table-text"> Cash </h4> -->
        <h4 class="title-text"> Amount Paid</h4>
    </div>
    <div class="col">
        <!-- <h4 class="table-text"> Cash Amount(N)</h4> -->
        <h4 class="title-text"> Trip </h4>

    </div>
    <div class="col-4">
      <h4 class="title-text"> Origin Address </h4>
    </div>
    <div class="col">
        <!-- <h4 class="table-text"> POS </h4> -->
        <h4 class="title-text"> Trip Date</h4>
    </div>
</div>
<div class="hor-seperator "></div>

  <div *ngFor="let item of bookings; let i=index;" >
    <div class="row ml-1 mr-1">
        <div class="col text-left" >
            <h4 class="table-main-text2">{{1+i}}. {{ item.passengerName}} </h4>
        </div>
        <div class="col">
            <!-- <h4 class="table-text"> Cash </h4> -->
            <h4 class="table-main-text2"> {{item.amountPaid | number: '0.2-2'}}</h4>
        </div>
        <div class="col">
            <!-- <h4 class="table-text"> Cash Amount(N)</h4> -->
            <h4 class="table-main-text2"> {{item.tripTitle }} </h4>
            <h4 class="table-text"> {{item.vehicleReg}}</h4>

        </div>
        <div class="col-4">
          <h4 class="table-main-text2"> {{item.originStationAddress }} </h4>
        </div>
        <div class="col">
            <!-- <h4 class="table-text"> POS </h4> -->
            <h4 class="table-main-text2"> {{item.tripDate | date :'medium'}}</h4>
        </div>
    </div>
    <div class="hor-seperator2 "></div>
</div>

</mat-dialog-content>
