import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataServicesService } from 'src/app/services/data-services.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import Swal from 'sweetalert2'

import * as firebase from 'firebase/compat/app';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-route',
  templateUrl: './add-route.component.html',
  styleUrls: ['./add-route.component.scss']
})
export class AddRouteComponent implements OnInit {

  routes ;
  public states = [];
  public allCities = [];
  public listedCitiesOrigin = [];
  public listedCities = []
  public terminals = []


  form: FormGroup = new FormGroup({
    // $key: new FormControl(null),
    destinationState: new FormControl('', Validators.required),
    originState: new FormControl('', Validators.required),
    destinationCity: new FormControl('', Validators.required),
    originCity: new FormControl('', Validators.required),
    originTerminal: new FormControl('', Validators.required),
    stops: new FormControl('', Validators.required),

  })

  constructor(public dialogRef: MatDialogRef<AddRouteComponent>, public dataService: DataServicesService,private authService: AuthServicesService, private notificationService: NotificationService) {

  }

  ngOnInit(): void {
    this.dataService.stationData.subscribe(stationData => {
      if(stationData['stations']){
        // console.log(stationData['stations'])
        this.routes = stationData['routes']

      }
  })
    this.states = this.dataService.states;
    this.allCities = this.dataService.cities;
    this.terminals = this.dataService.terminals;

    // console.log(this.terminals)
  }

  onClose() {

    this.dialogRef.close();
  }



  statechangeOrigin($event) {
    this.form.value.originCity = '';
    const state = $event.value;
    this.listedCitiesOrigin = [];
    console.log($event.value);
    for (const city of this.allCities) {
      if (city.state === state) {
        this.listedCitiesOrigin.push(city);
      }
    }
  }

  statechange($event) {
    this.form.value.destinationCity = '';
    const state = $event.value;
    this.listedCities = [];
    console.log($event.value);
    for (const city of this.allCities) {
      if (city.state === state) {
        this.listedCities.push(city);
      }
    }
  }

  onAdd(){

    Swal.fire({
      title: 'Add Route',
      text: "Are you sure you want to add this new route?",
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor:  '#4d9301',
      width:'15%',
      confirmButtonText: 'Confirm',
      background:'#d6dde0',
    }).then(async (result) => {
      if (result.isConfirmed) {

        const newRoute = {
            originState: this.form.value.originState,
            originCity: this.form.value.originCity,
            destinationState: this.form.value.destinationState,
            destinationCity: this.form.value.destinationCity,
            originTerminal: this.form.value.originTerminal,
            originStation: this.authService.userDetails.location,
            stops:this.form.value.stops,
            addDate: Date.now(),
            manager: this.authService.userDetails.username
        }


        this.routes.push(newRoute)
        // console.log(this.terminals)
        const accessToken = await firebase.default.auth().currentUser.getIdToken();

        this.form.reset()
        this.dataService.updateRoutes(this.routes, accessToken).subscribe(
          async (res) => {
           console.log(res);
          //  this.util.confirmDialogPassingparameter(res['paymentStatus'], res['message']);
           this.notificationService.success('::'+res['status']+' - '+res['message'] );
           this.onClose();
          });

      }
    })
  }


}
