<!-- <ngx-spinner bdColor = "rgba(190,195,179,0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner> -->
<div class="small-table" [class.isPrinting]="printService.isPrinting">

    <div class="row row-header">
      <div class="col pl-0">
        <h3 class="title-text">Bookings</h3>
       </div>
       <div class="col-4">
        <mat-form-field  >
          <mat-label>Select Date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date"  (dateChange)="startDateChange($event)">
            <input matEndDate placeholder="End date"  (dateChange)="endDateChange($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
       </div>
      <div class="col-4 ">
          <mat-form-field *ngIf="dataSource">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Name" #input>
        </mat-form-field>
      </div>
    </div>

  <mat-table [dataSource]="dataSource" class="full-width-table" aria-label="Elements" *ngIf="dataSource" >
    <!-- Id Column -->
    <ng-container matColumnDef="bookingDate">
      <mat-header-cell *matHeaderCellDef [ngClass]="'th-center w-10'"> Date</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'w-10'">{{row.bookingDate | date }}</mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="bookingId">
      <mat-header-cell *matHeaderCellDef [ngClass]="'th-center'">Booking ID</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text">{{row.bookingId }}</mat-cell>
    </ng-container>


    <ng-container matColumnDef="passengerName">
      <mat-header-cell *matHeaderCellDef [ngClass]="'th-center'">Passenger</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text">
        <div>
          {{row.passengerName }}
          <p  class="vehicle-text">
            <span *ngIf="!row.agencyPayment"> {{row.paymentMode}}</span>
            <span *ngIf="row.agencyPayment">{{row.agency}}</span>
          </p>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tripTitle">
      <mat-header-cell *matHeaderCellDef [ngClass]="'th-center'">Trip Route</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text">
        <div>
          {{row.tripTitle }}
          <h4 class="vehicle-text">{{row.tripDate | date:'EEE, MMM d, h:mm a'}} </h4>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="originStation">
      <mat-header-cell *matHeaderCellDef [ngClass]="'th-center'">Origin</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text">
        <!-- {{row.originStation }} -->
        {{ (row.originStation.length>25)? (row.originStation | slice:0:24)+'..':(row.originStation) }}

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vehicleType">
      <mat-header-cell *matHeaderCellDef [ngClass]="'th-center w-10'">Vehicle</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'w-10'">
        <div>
          {{row.vehicleType }}({{row.fleetId}})
          <p class="vehicle-text"> {{row.vehicleReg }}</p>
        </div>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef [ngClass]="'th-center w-10'">Fare</mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="openBooking(row)" class="table-main-text" [ngClass]="'w-10'">{{row.amountPaid|number }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="print">
      <mat-header-cell *matHeaderCellDef  [ngClass]="'w-10'"></mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="onPrintInvoice(row)" class="table-main-text" [ngClass]="'w-10'">
        <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
          <mat-icon>print</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </mat-table>

  <mat-paginator paginator [length]="dataLength" [pageIndex]="0" [pageSize]="pageSize" [pageSizeOptions]="[5, 10,15,20,25]" showFirstLastButtons></mat-paginator>

</div>
