<div >
  <div class="row">
    <div class="col-6">
      <h3 class="mat-h2 title-text">Routes</h3>
     </div>
    <div class="col-6">
        <mat-form-field *ngIf="dataSource">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Name" #input>
      </mat-form-field>
    </div>
  </div>
  <mat-table class="full-width-table" [dataSource]="dataSource"  matSort aria-label="Elements" *ngIf="dataSource">
    <!-- Id Column -->
    <ng-container matColumnDef="origin">
      <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-150'">Origin</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'" [ngClass]="'w-150'">
        <div>
          {{row.originCity}}
          <p class="table-sub-text">{{row.originState}}</p>
        </div>

      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="destination">
      <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-150'">Destination</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'" [ngClass]="'w-150'">
        <div>
          {{row.destinationCity}}
          <p class="table-sub-text">{{row.destinationState}}</p>
        </div>

      </mat-cell>
    </ng-container>

     <!-- Name Column -->
     <ng-container matColumnDef="stops">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Bus Stops</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'">{{row.stops}}</mat-cell>
    </ng-container>

     <!-- Name Column -->
     <ng-container matColumnDef="delBtn">
      <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-75'"></mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'w-75'">
        <i class="fas fa-1.5x fa-trash-alt icon-margin" slot="icon-only" (click)="deleteTerminal(row)"></i>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <div class="row">
    <div class="col-4  mt-2 align-content-center">
      <button   mat-stroked-button class="btn-submit" (click)="openAddRoute()" >Add Route</button>
    </div>
  </div>
  <div class="col-8 text-right" >
  <mat-paginator paginator [length]="dataLength" [pageIndex]="0" [pageSize]="pageSize" [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
</div>
</div>




