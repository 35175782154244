import { Component, OnInit,ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { PrintService } from 'src/app/services/print.service';

import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-trip-passengers',
  templateUrl: './trip-passengers.component.html',
  styleUrls: ['./trip-passengers.component.scss']
})
export class TripPassengersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  displayedColumns = ['passengerName','seatNumber', 'bookingDate', 'bookingSource','printBtn'];

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 10;

  tripTitle;
  tripDate;

  constructor(private dataService: DataServicesService, public printService: PrintService) { }

  ngOnInit(): void {
    this.dataService.currentSelectedTrip.subscribe(trip=>{
      if(trip){
        this.dataService.getSingleTrip(trip['id']).subscribe(tripDetails => {
          if(tripDetails){
            // console.log(tripDetails.payload.data())
            this.tripTitle = tripDetails.payload.data()['tripTitle'] +' '+tripDetails.payload.data()['fleetId']
            this.tripDate = tripDetails.payload.data()['tripDate']
            const paidPassengers = tripDetails.payload.data()['paidPassengers']
            this.dataSource = new MatTableDataSource(paidPassengers);
            this.dataLength = paidPassengers.length;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });

      }

    });

  }

  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onPrintInvoice(booking) {
    // console.log(booking)
    const invoiceIds = [booking.id];
    this.printService
      .printDocument('invoice', invoiceIds);
  }

}
