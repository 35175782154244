

<div class="row">
  <div class="col-4">
    <h3 class="mat-h2 title-text">Vehicle Type</h3>
   </div>
  <div class="col-6">
      <mat-form-field *ngIf="dataSource">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Name" #input>
    </mat-form-field>
  </div>
  <div class="col-2 text-right add-button">
    <ion-button mat-icon-button class="text-right"  aria-label="Toggle menu" (click)="openAddVehicleType()">
      <mat-icon>add</mat-icon>
    </ion-button>
  </div>
</div>
<mat-table #table [dataSource]="dataSource" *ngIf="dataSource">

  <ng-container matColumnDef="vehicleType">
    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.vehicleType}}
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="vehicleModel">
    <mat-header-cell *matHeaderCellDef> Model </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.vehicleModel}}
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="passengerCapacity">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-15'"> Capacity </mat-header-cell>
    <mat-cell *matCellDef="let element"  [ngClass]="'w-15'">
      {{element.totalPassengers}}
       </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="image">
    <mat-header-cell *matHeaderCellDef> Image </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a target="_blank" href="{{element.image}}"></a>
       </mat-cell>
  </ng-container> -->

  <ng-container matColumnDef="editBtn">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-10'">  </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'w-10'">
      <ion-button>
        <mat-icon button (click)="editVehicleType(element)">more_vert</mat-icon>
      </ion-button>

       </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>



<mat-paginator #paginator
[pageSize]="7"
[pageSizeOptions]="[7,10,14,21,28]"
[showFirstLastButtons]="true" >
</mat-paginator>



