import { AfterViewInit, Component, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataServicesService } from 'src/app/services/data-services.service';
import {MatTableDataSource} from '@angular/material/table';
import * as firebase from 'firebase/compat/app';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';

import Swal from 'sweetalert2'


import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from 'src/app/services/notification.service';
import { AddRouteComponent } from 'src/app/modals/add-route/add-route.component';

@Component({
  selector: 'app-route-table',
  templateUrl: './route-table.component.html',
  styleUrls: ['./route-table.component.scss']
})
export class RouteTableComponent implements AfterViewInit, OnInit,OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData;
  pageSize = 5;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'origin',
    'destination',
    'stops',
    'delBtn',
  ];

  routes;
  stationData;
  private bookingSubscription$: Subscription;
  constructor(private dataService: DataServicesService, private notificationService: NotificationService,private dialog: MatDialog){}

  ngOnInit() {
    this.bookingSubscription$ =this.dataService.stationData.subscribe(stationData => {
      if(stationData){
        if(stationData['stations']){
          // console.log(stationData['stations'])
          this.stationData = stationData;
          this.routes = stationData['routes'];
          this.dataService.routes = this.routes;
          this.tableData  = this.routes;
          this.dataSource = new MatTableDataSource(this.routes);
          this.dataLength = this.routes.length;
          this.dataSource.paginator = this.paginator;

        }
      }
    })
  }

  public ngOnDestroy(): void {
    this.bookingSubscription$.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.tableData){
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  deleteTerminal(route){
    Swal.fire({
      title: 'Delete Route',
      text: "Are you sure you want to delete this route?",
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor:  '#4d9301',
      width:'15%',
      confirmButtonText: 'Confirm',
      background:'#d6dde0',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.stationData.routes = this.routes.filter((el) => {
          return route !== el;
        });
        const accessToken = await firebase.default.auth().currentUser.getIdToken();

        this.dataService.updateRoutes(this.stationData.routes, accessToken).subscribe(
          async (res) => {
           console.log(res);
          //  this.util.confirmDialogPassingparameter(res['paymentStatus'], res['message']);
           this.notificationService.success('::'+res['status']+' - '+res['message'] );

          });

      }
    })

  }

  openAddRoute(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(AddRouteComponent, dialogConfig);

  }
}
