<div class="">
  <div class="row">
    <div class="col-6">
      <h3 class="mat-h2 title-text">Terminals</h3>
     </div>
    <div class="col-6 ">
        <mat-form-field *ngIf="dataSource">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Name" #input>
      </mat-form-field>
    </div>
  </div>
  <mat-table mat-table class="full-width-table" [dataSource]="dataSource"  matSort aria-label="Elements" *ngIf="dataSource">
    <!-- Id Column -->
    <ng-container matColumnDef="terminalName">
      <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-25'">Terminal</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text"   [ngClass]="'w-25'">{{row.stationName}}</mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="terminalAddress">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Terminal Address</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text" >{{row.stationAddress}}</mat-cell>
    </ng-container>

     <!-- Name Column -->
     <ng-container matColumnDef="terminalPhone">
      <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-20'">Phone Num</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text"  [ngClass]="'w-20'">{{row.stationPhone}}</mat-cell>
    </ng-container>

     <!-- Name Column -->
     <ng-container matColumnDef="delBtn">
      <mat-header-cell *matHeaderCellDef mat-sort-header  [ngClass]="'w-10'" ></mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text"  [ngClass]="'w-10'"  >
        <i class="fas fa-1.5x fa-trash-alt icon-margin" slot="icon-only" (click)="deleteTerminal(row)"></i>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <div class="row">
    <div class="col-4  mt-2 align-content-center">
      <button   mat-stroked-button class="btn-submit" (click)="openAddTerminal()" >Create Terminal</button>
    </div>
    <div class="col-8 text-right" >
      <mat-paginator paginator [length]="dataLength" [pageIndex]="0" [pageSize]="pageSize" [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
    </div>
    </div>


</div>
