import { Component, OnInit } from '@angular/core';
import { DataServicesService } from 'src/app/services/data-services.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-manager-booking',
  templateUrl: './manager-booking.component.html',
  styleUrls: ['./manager-booking.component.scss']
})
export class ManagerBookingComponent implements OnInit {


  managerBookings = [];
  currentBookingDay;
  constructor(private dataService: DataServicesService,public dialogRef: MatDialogRef<ManagerBookingComponent>) { }

  ngOnInit(): void {

    this.dataService.currentOfficeBooking.subscribe(currentBookingDay => {
      if(currentBookingDay){
        this.currentBookingDay = currentBookingDay
        this.managerGroupings(currentBookingDay['bookings']);
      }
    })

  }

  managerGroupings(bookings) {
    const managerBookings = { };
    const cash = 'cash';
    const cashCount = 'cashCount';
    const pos = 'pos';
    const posCount = 'posCount';
    const name = 'name';
    const total = 'total';
    const invoices = 'invoices'

    for (const book in bookings) {
      if (bookings.hasOwnProperty(book)) {
        const booking = bookings[book];
        const mode = booking.paymentMode;
        const paymentAmount = Number(booking.amountPaid);
        if(booking.agencyPayment){
          if (managerBookings[booking.agencyId]) {
            managerBookings[booking.agencyId].total = managerBookings[booking.agencyId].total + paymentAmount;
            managerBookings[booking.agencyId].invoices.push(booking)
            if ( mode.includes('Cash')) {
              managerBookings[booking.agencyId].cash = managerBookings[booking.agencyId].cash + paymentAmount;
              managerBookings[booking.agencyId].cashCount = managerBookings[booking.agencyId].cashCount + 1;
            } else {
              managerBookings[booking.agencyId].pos = managerBookings[booking.agencyId].pos + paymentAmount;
              managerBookings[booking.agencyId].posCount = managerBookings[booking.agencyId].posCount + 1;
            }

          } else {
            const bookingObject = {};
            bookingObject[name] = booking.agency;
            bookingObject[cash] = 0;
            bookingObject[cashCount] = 0;
            bookingObject[pos] = 0;
            bookingObject[posCount] = 0;
            bookingObject[invoices] = []
            bookingObject[invoices][0]=booking

            if ( mode.includes('Cash')) {
              bookingObject[cash] = paymentAmount;
              bookingObject[cashCount] = 1;
            } else {
              bookingObject[pos] = paymentAmount;
              bookingObject[posCount] = 1;
            }
            bookingObject[total] = paymentAmount;
            managerBookings[booking.agencyId] = bookingObject;

          }

        } else {
          if (managerBookings[booking.officeCollectorId]) {
            managerBookings[booking.officeCollectorId].total = managerBookings[booking.officeCollectorId].total + paymentAmount;
            if ( mode.includes('Cash')) {
              managerBookings[booking.officeCollectorId].cash = managerBookings[booking.officeCollectorId].cash + paymentAmount;
              managerBookings[booking.officeCollectorId].cashCount = managerBookings[booking.officeCollectorId].cashCount + 1;
            } else {
              managerBookings[booking.officeCollectorId].pos = managerBookings[booking.officeCollectorId].pos + paymentAmount;
              managerBookings[booking.officeCollectorId].posCount = managerBookings[booking.officeCollectorId].posCount + 1;
            }

          } else {
            const bookingObject = {};
            bookingObject[name] = booking.officeCollectorName;
            bookingObject[cash] = 0;
            bookingObject[cashCount] = 0;
            bookingObject[pos] = 0;
            bookingObject[posCount] = 0;
            if ( mode.includes('Cash')) {
              bookingObject[cash] = paymentAmount;
              bookingObject[cashCount] = 1;
            } else {
              bookingObject[pos] = paymentAmount;
              bookingObject[posCount] = 1;
            }
            bookingObject[total] = paymentAmount;
            managerBookings[booking.officeCollectorId] = bookingObject;

          }
        }
        // if (managerBookings[booking.officeCollectorId]) {
        //   managerBookings[booking.officeCollectorId].total = managerBookings[booking.officeCollectorId].total + paymentAmount;
        //   if ( mode.includes('Cash')) {
        //     managerBookings[booking.officeCollectorId].cash = managerBookings[booking.officeCollectorId].cash + paymentAmount;
        //     managerBookings[booking.officeCollectorId].cashCount = managerBookings[booking.officeCollectorId].cashCount + 1;
        //   } else {
        //     managerBookings[booking.officeCollectorId].pos = managerBookings[booking.officeCollectorId].pos + paymentAmount;
        //     managerBookings[booking.officeCollectorId].posCount = managerBookings[booking.officeCollectorId].posCount + 1;
        //   }

        // } else {
        //   const bookingObject = {};
        //   bookingObject[name] = booking.officeCollectorName;
        //   bookingObject[cash] = 0;
        //   bookingObject[cashCount] = 0;
        //   bookingObject[pos] = 0;
        //   bookingObject[posCount] = 0;
        //   if ( mode.includes('Cash')) {
        //     bookingObject[cash] = paymentAmount;
        //     bookingObject[cashCount] = 1;
        //   } else {
        //     bookingObject[pos] = paymentAmount;
        //     bookingObject[posCount] = 1;
        //   }
        //   bookingObject[total] = paymentAmount;
        //   managerBookings[booking.officeCollectorId] = bookingObject;

        // }

      }
    }
    this.managerBookings = Object.entries(managerBookings);
    // console.log(this.managerBookings)
  }

  onClose() {
    this.dialogRef.close();
  }



  download(item){
    // console.log(item)
    const bookings = item.invoices
    const headerList = ['Payment Date','Passenger','Trip', 'Travel Date' ,'Vehicle', 'Manager','Payment Mode','Amount']
      const objArray = [];
      let passenger = '';
      let vehicle = '';
      let tripTitle = '';
      let manager = '';

      bookings.forEach(req => {
        passenger = req.passengerName;
        tripTitle = req.tripTitle;
        vehicle = req.vehicleReg
        manager = req.officeCollectorName || req.agency

        passenger = passenger.replace(/,/g, ';')
        tripTitle = tripTitle.replace(/,/g, ';')
        vehicle = vehicle.replace(/,/g, ';')
        manager = manager.replace(/,/g, ';')

        const oneBookingDate = new Date(req.tripDate);
        const dateComponents = oneBookingDate.toLocaleString().split(' ');
        // console.log(dateComponents)
        const tripDay = dateComponents[0] + ', ' + dateComponents[1] + ' ' + dateComponents[2] + ' ' + dateComponents[3];
        let oneRequest = {
          'Travel Date': dateComponents[1] + ' '+ new Date(Number(req.tripDate)).toDateString(),
          'Passenger': passenger,
          'Trip' : tripTitle,
          'Vehicle' : vehicle ,
          'Manager' : manager,
          'Amount' : req.amountPaid,
          'Payment Mode' : req.paymentMode,
          'Payment Date':  new Date(Number(req.paymentDate)).toDateString()

        }
        objArray.push(oneRequest)
      })

      if(objArray.length){
        const data = this.ConvertToCSV(objArray,headerList);
        const fileName = manager + ' Office Booking For '+this.currentBookingDay.day;
        this.downloadFile(data,fileName)
      }

  }



  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
     row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
     let line = (i+1)+'';
     for (let index in headerList) {
      let head = headerList[index];
      line += ',' + array[i][head];
     }
     str += line + '\r\n';
    }
    return str;
   }

  downloadFile(csvData, filename) {
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

}
