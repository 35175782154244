import { Component, OnInit } from '@angular/core';
import { ViewVehicleTypeComponent } from 'src/app/modals/view-vehicle-type/view-vehicle-type.component';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';

import { AuthServicesService } from 'src/app/services/auth-services.service';
import { DataServicesService } from 'src/app/services/data-services.service';
import { ViewVehicleComponent } from 'src/app/modals/view-vehicle/view-vehicle.component';

@Component({
  selector: 'app-fleets',
  templateUrl: './fleets.component.html',
  styleUrls: ['./fleets.component.scss']
})
export class FleetsComponent implements OnInit {

  miniCardData = [];
  constructor(private dialog: MatDialog,private dataService: DataServicesService, private authService: AuthServicesService) { }

  ngOnInit(): void {
    this.authService.userData.subscribe(userData => {
      // console.log(userData)
      if (userData){
        this.dataService.getVehicleAnalytics();
        this.dataService.getFleet();
        this.dataService.getVehicleType();
        this.dataService.getStates();
      }

    });

    this.dataService.miniFleetCardData.subscribe(miniCardData => {
      if(miniCardData){
        this.miniCardData = miniCardData;
      }
    })
  }

  cardStyle(color) {
    if (color) {
      // console.log(color)
      return { 'background-color': color }
    }
  }

  openAddVehicleType() {
    console.log('Open dialog')
    this.dataService.openedVehicleType = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(ViewVehicleTypeComponent, dialogConfig);

  }

  openAddVehicle() {
    console.log('Open dialog')
    this.dataService.openedVehicle = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(ViewVehicleComponent, dialogConfig);

  }

}
