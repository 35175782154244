// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cloudfunctionurl: 'https://us-central1-arrive-saas.cloudfunctions.net/',
  containerurl:'https://arrive-services-og762lkzrq-ew.a.run.app/',
  config : {
    apiKey: 'AIzaSyC-iB8qr-xyX4wtepVK0N8nsA49OoDCrlY',
    authDomain: 'arrive-saas.firebaseapp.com',
    databaseURL: 'https://arrive-saas.firebaseio.com',
    projectId: 'arrive-saas',
    storageBucket: 'arrive-saas.appspot.com',
    messagingSenderId: '446895761755',
    appId: '1:446895761755:web:6fc223adda824b4806127e',
    measurementId: 'G-B8ZSBJ65G5'
},
  dbPrefix: 'dev_'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
