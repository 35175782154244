import { Component, OnInit } from '@angular/core';
import { DataServicesService } from 'src/app/services/data-services.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-terminal-bookings',
  templateUrl: './terminal-bookings.component.html',
  styleUrls: ['./terminal-bookings.component.scss']
})
export class TerminalBookingsComponent implements OnInit {


  terminalBookings = [];
  currentBookingDay;
  constructor(private dataService: DataServicesService,public dialogRef: MatDialogRef<TerminalBookingsComponent>) { }

  ngOnInit(): void {

    this.dataService.currentOfficeBooking.subscribe(currentBookingDay => {
      if(currentBookingDay){
        this.currentBookingDay = currentBookingDay
        this.terminalGroupings(currentBookingDay['bookings']);
      }
    })
  }

  terminalGroupings(bookings) {
    // console.log(bookings)
    const managerBookings = { };
    const cash = 'cash';
    const cashCount = 'cashCount';
    const pos = 'pos';
    const posCount = 'posCount';
    const name = 'name';
    const total = 'total';
    const invoices = 'invoices'

    for (const book in bookings) {
      if (bookings.hasOwnProperty(book)) {
        const booking = bookings[book];
        const mode = booking.officeMode;
        const paymentAmount = Number(booking.amountPaid);
        if (managerBookings[booking.originStationAddress]) {
          managerBookings[booking.originStationAddress].total = managerBookings[booking.originStationAddress].total + paymentAmount;
          managerBookings[booking.originStationAddress].invoices.push(booking)
          if ( mode === 'Cash') {
            managerBookings[booking.originStationAddress].cash = managerBookings[booking.originStationAddress].cash + paymentAmount;
            managerBookings[booking.originStationAddress].cashCount = managerBookings[booking.originStationAddress].cashCount + 1;
          } else {
            managerBookings[booking.originStationAddress].pos = managerBookings[booking.originStationAddress].pos + paymentAmount;
            managerBookings[booking.originStationAddress].posCount = managerBookings[booking.originStationAddress].posCount + 1;
          }

        } else {
          const bookingObject = {};
          bookingObject[name] = booking.originStationAddress;
          bookingObject[cash] = 0;
          bookingObject[cashCount] = 0;
          bookingObject[pos] = 0;
          bookingObject[posCount] = 0;
          bookingObject[invoices] = []
          bookingObject[invoices][0]=booking
          if ( mode === 'Cash') {
            bookingObject[cash] = paymentAmount;
            bookingObject[cashCount] = 1;
          } else {
            bookingObject[pos] = paymentAmount;
            bookingObject[posCount] = 1;
          }
          bookingObject[total] = paymentAmount;
          managerBookings[booking.originStationAddress] = bookingObject;

        }

      }
    }
    this.terminalBookings = Object.entries(managerBookings);
    // console.log(this.managerBookings)
  }

  onClose() {
    this.dialogRef.close();
  }

  download(item){
    // console.log(item)
    const headerList = ['Payment Date','Passenger','Trip', 'Travel Date' ,'Vehicle', 'Manager','Payment Mode','Amount']
      const objArray = [];
      let passenger = '';
      let vehicle = '';
      let tripTitle = '';
      let manager = '';
      let terminal = ''
      const bookings = item.invoices
      bookings.forEach(req => {
        passenger = req.passengerName;
        tripTitle = req.tripTitle;
        vehicle = req.vehicleReg
        manager = req.officeCollectorName || req.agency
        terminal = req.originStationAddress

        passenger = passenger.replace(/,/g, ';')
        tripTitle = tripTitle.replace(/,/g, ';')
        vehicle = vehicle.replace(/,/g, ';')
        manager = manager.replace(/,/g, ';')
        terminal = terminal.replace(/,/g, '-')

        const oneBookingDate = new Date(req.tripDate);
        const dateComponents = oneBookingDate.toLocaleString().split(' ');
        // console.log(dateComponents)
        const tripDay = dateComponents[0] + ', ' + dateComponents[1] + ' ' + dateComponents[2] + ' ' + dateComponents[3];
        let oneRequest = {
          'Travel Date': dateComponents[1] + ' '+ new Date(Number(req.tripDate)).toDateString(),
          'Passenger': passenger,
          'Trip' : tripTitle,
          'Vehicle' : vehicle ,
          'Manager' : manager,
          'Amount' : req.amountPaid,
          'Payment Mode' : req.officeMode || req.paymentMode,
          'Payment Date':  new Date(Number(req.paymentDate)).toDateString()

        }
        objArray.push(oneRequest)
      })

      if(objArray.length){
        const data = this.ConvertToCSV(objArray,headerList);
        const fileName = terminal + ' Office Booking For '+this.currentBookingDay.day;
        this.downloadFile(data,fileName)
      }

  }



  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
     row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
     let line = (i+1)+'';
     for (let index in headerList) {
      let head = headerList[index];
      line += ',' + array[i][head];
     }
     str += line + '\r\n';
    }
    return str;
   }

  downloadFile(csvData, filename) {
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }


}
