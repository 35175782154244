import { Component, OnInit,AfterViewInit ,ViewChild} from '@angular/core';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ViewVehicleComponent } from 'src/app/modals/view-vehicle/view-vehicle.component';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-fleet-table',
  templateUrl: './fleet-table.component.html',
  styleUrls: ['./fleet-table.component.scss']
})
export class FleetTableComponent implements OnInit,AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 5;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'fleetId',
    'vehicleType',
    'onboardDate',
    'capital',
    'expenses',
    'owner',
    'driver',
    'view'

    ];

    miniFleetCardData = [];
    fleetCount = 0;
    fleetTotalCost = 0;
    fleetTotalExpeses = 0;
    fleetTotalEarings = 0;
    fleetTotalCapacity = 0;
    tripCount = 0;

    fleetTrips;

    vehicleAnalytics =[];

  constructor(private dataService: DataServicesService ,private dialog: MatDialog) { }

  async ngOnInit() {
    await this.getAnalytics();
    this.dataService.fleet.subscribe(fleet => {
      if (fleet){
        const tableData = [];
        this.fleetCount = 0;
        this.fleetTotalCost = 0;
        this.fleetTotalExpeses = 0;
        this.fleetTotalCapacity = 0;
        this.miniFleetCardData = [];
        for (const vehicle in fleet) {
          if (fleet.hasOwnProperty(vehicle)){
            this.fleetCount += 1;
            const oneVehicle = fleet[vehicle];
            const fleetId1 = 'fleetId';
            const fleetId = oneVehicle[fleetId1];
            const regNum1 = 'regNum';
            const regNum = oneVehicle[regNum1];
            // console.log(regNum);
            const regState1 = 'regState';
            const regState = oneVehicle[regState1];
            const initialCapital1 = 'initialCapital';
            const capital = oneVehicle[initialCapital1];
            this.fleetTotalCost = this.fleetTotalCost + capital;
            const dateEnlisted1 = 'dateEnlisted';
            const dateEnlisted = oneVehicle[dateEnlisted1];
            const totalPassengers = 'totalPassengers';
            const passegerCapacity = oneVehicle[totalPassengers];
            this.fleetTotalCapacity = this.fleetTotalCapacity + passegerCapacity;
            const vehicleType1 = 'vehicleType';
            const vehicleType = oneVehicle[vehicleType1];
            // console.log(vehicleType.vehicleType);
            const owner1 = 'owner';
            const owner = oneVehicle[owner1];
            const currentDriver = 'currentDriver';
            const driver = oneVehicle[currentDriver];
            const expenses1 = 'expenses';
            const expenses = this.getExpenses(oneVehicle[expenses1]);
            this.fleetTotalExpeses = this.fleetTotalExpeses + expenses;
            const tableEntry = {
              fleetId,
              regNum,
              regState,
              capital,
              dateEnlisted,
              passegerCapacity,
              vehicleType,
              owner,
              driver,
              expenses,
              vehicle: oneVehicle
            };
            tableData.push(tableEntry);
          }
        }
        this.tableData  = tableData;
        this.dataService.sortedVehicleTableData = tableData;
        this.dataSource = new MatTableDataSource(tableData);
        this.dataLength = tableData.length;
        this.dataSource.paginator = this.paginator;
        // this.miniCardFunction();

      }

      });

  }

  ngAfterViewInit() {
    if (this.dataSource){
      // this.dataSource = new MatTableDataSource(this.tableData);
      // this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
      // this.miniCardFunction();
    }
  }

  getExpenses(expenses){
    let totalExpense = 0;
    for (const exp in expenses){
      if (expenses.hasOwnProperty(exp)){
        const oneExpense = expenses[exp];
        const expenseAmount = 'expenseAmount';
        // console.log(oneExpense[expenseAmount]);
        totalExpense = totalExpense + oneExpense[expenseAmount];
      }
    }
    return totalExpense;
  }

  getAnalytics(){
    this.dataService.vehicleAnalytics.subscribe(data=> {
      if(data){
        this.vehicleAnalytics = data
        this.tripCount = 0;
        this.fleetTotalEarings = 0;
        this.vehicleAnalytics.forEach(rec =>{
        this.tripCount+=rec.total_trips
        this.fleetTotalEarings+=Number(rec.total_income);
      })
      this.miniCardFunction();

      if(this.fleetTotalCost &&  this.fleetTotalEarings){  //this.fleetTotalExpeses &&
        this.dataService.fleetAnalyticsCard.next({
          'Fleet Cost':this.fleetTotalCost,
          'Fleet Expeses': this.fleetTotalExpeses,
          'Fleet Income': this.fleetTotalEarings
      });
      }
      }
    })

  }

  miniCardFunction(){
    this.miniFleetCardData = [];
    console.log('In Minicard')
    const totalFleet = {
      icon: 'commute',
      title: 'Total Fleet Count',
      value: this.fleetCount,
      color: '#4d9301',
      isCurrency: false,
      today: 0
    };

    const fleetTotalCost = {
      icon: 'payment',
      title: 'Total Fleet Cost',
      value: this.fleetTotalCost,
      color: '#5797fc',
      isCurrency: true,
      today: 0
    };

    const fleetTotalExpeses = {
      icon: 'credit_card',
      title: 'Total Fleet Expenses',
      value: this.fleetTotalExpeses,
      color: '#ffc107',
      isCurrency: true,
      today: 0
    };

    const fleetTrip = {
      icon: 'commute',
      title: 'Total Trip',
      value: this.tripCount,
      color: '#6c757d',
      isCurrency: false,
      today: 0
    };
    this.miniFleetCardData.push(totalFleet);
    this.miniFleetCardData.push(fleetTotalCost);
    this.miniFleetCardData.push(fleetTotalExpeses);
    this.miniFleetCardData.push(fleetTrip);
    if(this.fleetTotalCost && this.fleetTotalExpeses && this.fleetTotalEarings){
      this.dataService.fleetAnalyticsCard.next({
        'Fleet Cost':this.fleetTotalCost,
        'Fleet Expeses': this.fleetTotalExpeses,
        'Fleet Income': this.fleetTotalEarings
    });
    }



    this.dataService.miniFleetCardData.next(this.miniFleetCardData);


  }



  editVehicle(vehicle) {
    console.log('Open dialog')
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dataService.openedVehicle = vehicle.vehicle;
    this.dialog.open(ViewVehicleComponent, dialogConfig);

  }

  openAddVehicle() {
    console.log('Open dialog')
    this.dataService.openedVehicle = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(ViewVehicleComponent, dialogConfig);

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
