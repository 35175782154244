<div class="row">
  <div class="col-4">
    <h3 class="mat-h2 title-text">Vehicles</h3>
   </div>
  <div class="col-6">
      <mat-form-field *ngIf="dataSource">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Name" #input>
    </mat-form-field>
  </div>
  <div class="col-2 text-right add-button">
    <ion-button mat-icon-button class="text-right"  aria-label="Toggle menu" (click)="openAddVehicle()">
      <mat-icon>add</mat-icon>
    </ion-button>
  </div>
</div>

  <mat-table #table [dataSource]="dataSource" *ngIf="dataSource">
    <!-- Id Column -->
    <ng-container matColumnDef="fleetId">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-10'">Vehicle</mat-header-cell>
      <mat-cell *matCellDef="let row" [ngClass]="'w-10'">{{row.fleetId}}
        <!-- <p class="table-text">{{row.regNum}}</p> -->
      </mat-cell>
    </ng-container>



    <ng-container matColumnDef="vehicleType" >
      <mat-header-cell *matHeaderCellDef >Model</mat-header-cell>
      <mat-cell *matCellDef="let row" >{{row.vehicleType.vehicleType}}
        <!-- <p class="table-text-2">{{row.passegerCapacity}}</p> -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="onboardDate" >
      <mat-header-cell *matHeaderCellDef >Date</mat-header-cell>
      <mat-cell *matCellDef="let row" >{{row.dateEnlisted | date: 'mediumDate'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="capital">
      <mat-header-cell *matHeaderCellDef > Capital</mat-header-cell>
      <mat-cell *matCellDef="let row" >{{row.capital | currency:'N':'symbol':'0.0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="expenses">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-10'">Expenses</mat-header-cell>
      <mat-cell *matCellDef="let row"  [ngClass]="'w-10'">{{row.expenses| currency:'N':'symbol':'0.0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="owner">
      <mat-header-cell *matHeaderCellDef >Owner</mat-header-cell>
      <mat-cell *matCellDef="let row" >{{row.owner}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="driver">
      <mat-header-cell *matHeaderCellDef  >Driver</mat-header-cell>
      <mat-cell *matCellDef="let row" >{{row.driver}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="view">
        <mat-header-cell *matHeaderCellDef  [ngClass]="'w-5'"></mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="'w-5'">
            <ion-button  (click)="editVehicle(row)">
              <mat-icon>more_vert</mat-icon>
            </ion-button>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </mat-table>

  <mat-paginator paginator [length]="dataLength" [pageIndex]="0" [pageSize]="pageSize" [pageSizeOptions]="[5,7,10,15,20,25]" showFirstLastButtons></mat-paginator>

