<div class="example-header">
  <div class="row">
    <div class="col-5">
      <button mat-button mat-raised-button  (click)="getCountGraph()" *ngIf="type==='amount'" class="statusbtn">Count</button>
    <button mat-button mat-raised-button  (click)="getRevenueGraph()" *ngIf="type==='count'" class="statusbtn">Revenue</button>
    </div>
    <div class="col-2"></div>

    <div class="col-5">
      <mat-form-field class="text-full-width-1">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate matInput placeholder="Start date" (dateChange)="startDateChange($event)">
          <input matEndDate matInput placeholder="End date" (dateChange)="endDateChange($event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <!-- <div class="col-1"></div> -->
  </div>
</div>

<div class="mb-4 ml-4" >
  <div class="mb-4 ml-4">
    <div style="display: block">
      <canvas baseChart
        [data]="doughnutChartData"
        [labels]="doughnutChartLabels"
        [chartType]="doughnutChartType"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [legend]="pieChartLegend"
        [colors]="pieChartColors"
        >
      </canvas>
    </div>
  </div>
</div>


