<div class="container-fluid loginbox">
    <div class="row">
      <div class="card">
        <img class="logo" src="./assets/images/logo.png" >
        <form>
            <mat-form-field appearance="emil">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email Id" type="email" [(ngModel)]="email" name="email">
            </mat-form-field>

            <mat-form-field appearance="emil" *ngIf="!resetPass">
              <mat-label>Password</mat-label>
              <input matInput placeholder="Password" [type]="passwordType" [(ngModel)]="password" name="password">
              <mat-icon matSuffix (click)="togglePassword()" >remove_red_eye</mat-icon>
              <mat-hint (click)="resetPassword1()" button>Reset Password</mat-hint>
          </mat-form-field>
            <p class="cancel" style="color:red;margin:5px;" *ngIf="errorMessage"> {{errorMessage}}</p>
          <button mat-button (click)="login()" *ngIf="!resetPass">Login</button>
          <button mat-button (click)="resetPassword()" *ngIf="resetPass">Request Reset</button>

        </form>
        <!-- <h4><span>or Sign up using</span></h4>
        <div class="buttonslog">
          <ion-button style="background-image:url('./assets/home/google.png')" (click)="googleLogin()"></ion-button>
          <ion-button style="background-image:url('./assets/home/apple.png')" (click)="appleLogin()"></ion-button>
          <ion-button style="background-image:url('./assets/home/facebook.png')" (click)="facebookLogin()"></ion-button>
        </div> -->
      </div>
    </div>
</div>
