import { Component, OnInit ,ViewChild,OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-trip-route-table',
  templateUrl: './trip-route-table.component.html',
  styleUrls: ['./trip-route-table.component.scss']
})
export class TripRouteTableComponent implements OnInit,OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;


  displayedColumns = ['trip_date','trip_title', 'trip_count', 'trip_amount'];

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 10;
  tripRoutes;

  private bookingSubscription$: Subscription;
  constructor(private dataService: DataServicesService) { }

  ngOnInit(): void {

    this.bookingSubscription$ =this.dataService.tripRoute.subscribe(tripRoutes => {
      if (tripRoutes){
        this.tripRoutes  = tripRoutes;
        this.dataSource = new MatTableDataSource(this.tripRoutes);
        this.dataLength = this.tripRoutes.length;
        this.dataSource.paginator = this.paginator;
        // console.log(tripRoutes)
      }

    });

  }

  public ngOnDestroy(): void {
    this.bookingSubscription$.unsubscribe();
  }

  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
