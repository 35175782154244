
<div *ngIf="!dataSource">
  <h4>Select an Available Trip</h4>

</div>
<div *ngIf="dataSource">
  <div class="row">
    <div class="col-6">
      <h4 class="title-font">{{tripTitle}} </h4>
    </div>
    <div class="col-6">
      <mat-form-field *ngIf="dataSource">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Name" #input>
      </mat-form-field>
    </div>

    </div>

  <mat-table #table [dataSource]="dataSource" *ngIf="dataSource" matSort>

    <ng-container matColumnDef="passengerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element.passengerName}}
         </mat-cell>
    </ng-container>

    <ng-container matColumnDef="seatNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Seat </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element.seatNumber}}
         </mat-cell>
    </ng-container>

    <ng-container matColumnDef="bookingDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element.bookingDate|date:'dd MMM yy, HH:mm'}}
         </mat-cell>
    </ng-container>

    <ng-container matColumnDef="bookingSource">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Payment </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element.officeMode}}
         </mat-cell>
    </ng-container>

    <ng-container matColumnDef="printBtn">
      <mat-header-cell *matHeaderCellDef ></mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="onPrintInvoice(row)" class="table-main-text">
        <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
          <mat-icon>print</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>



  <mat-paginator #paginator
  [pageSize]="7"
  [pageSizeOptions]="[7,10,14,21,28]"
  [showFirstLastButtons]="true" >
  </mat-paginator>

</div>
