import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { DataServicesService } from 'src/app/services/data-services.service';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import * as firebase from 'firebase/compat/app';

import { UUID } from 'angular2-uuid';


import Swal from 'sweetalert2'


@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit {


  selectedManager;
  adminPermission;
  managerPermission;
  terminals = [];
  log =[];
  constructor(public dialogRef: MatDialogRef<ManagerComponent>, private notificationService: NotificationService, private dataService: DataServicesService, private authService: AuthServicesService) {

  }

  ngOnInit(): void {

    this.authService.userData.subscribe(userDetails => {
      if(userDetails) {
        this.adminPermission = userDetails['permissions'];
      }
    })
    this.selectedManager = this.dataService.selectedManager;
    // console.log(this.authService.userDetails)
    this.managerPermission = this.selectedManager.permissions||{};
    this.log = this.selectedManager.log  || []
    this.terminals = this.dataService.terminals;
  }

  onClose() {
    this.dataService.selectedManager = null;
    this.dialogRef.close();
  }

  changeTermial($evnt){
    this.selectedManager.terminal = $evnt.value.stationName;
    this.selectedManager.terminalAddress = $evnt.value.stationName + ' - ' + $evnt.value.stationAddress;
    // console.log(this.newAgent.terminalAddress);

  }

  updateManager(){
    Swal.fire({
      title: 'Update Manager',
      text: "Are you sure you want to update this user's permission? The user will have all rights based on selected permissions",
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor:  '#4d9301',
      width:'15%',
      confirmButtonText: 'Confirm',
      background:'#d6dde0',
    }).then(async (result) => {
      if (result.isConfirmed) {

        const accessToken = await firebase.default.auth().currentUser.getIdToken();
        this.log.push(
          {
            id: UUID.UUID(),
            action: 'Station Manager update',
            userId: this.authService.userDetails.id,
            userName: this.authService.userDetails.username,
            date: Date.now(),
            details: {
              action: 'update manager - ' + this.selectedManager.username,
              response: Date.now()
            }
          }
        )
        this.selectedManager.log = this.log;
        this.selectedManager.permissions=this.managerPermission;

        this.dataService.updateManager(this.selectedManager, accessToken).subscribe(
          async (res) => {
           console.log(res);
           this.notificationService.success('::'+res['status']+' - '+res['message'] );
           this.dataService.getStationManagers();
           this.onClose()

          });

      }
    })

  }

  activateManager(){
    Swal.fire({
      title: 'Activate Manager',
      text: "Are you sure you want to activate this user? The user will have all rights based on selected permissions",
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor:  '#4d9301',
      width:'15%',
      confirmButtonText: 'Confirm',
      background:'#d6dde0',
    }).then(async (result) => {
      if (result.isConfirmed) {

        const accessToken = await firebase.default.auth().currentUser.getIdToken();
        this.selectedManager.status='active',

        this.log.push(
          {
            id: UUID.UUID(),
            action: 'Station Manager Activate',
            userId: this.authService.userDetails.id,
            userName: this.authService.userDetails.username,
            date: Date.now(),
            details: {
              action: 'activate manager - ' + this.selectedManager.username,
              response: Date.now()
            }
          }
        )
        this.selectedManager.log = this.log;

        this.dataService.updateManager(this.selectedManager, accessToken).subscribe(
          async (res) => {
           console.log(res);
           this.notificationService.success('::'+res['status']+' - '+res['message'] );
           this.dataService.getStationManagers();
           this.onClose()
          });

      }
    })

  }

  deactivateManager(){
    Swal.fire({
      title: 'De-activate Manager',
      text: "Are you sure you want to de-activate this user? The user will be denied rights on the platform",
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor:  '#4d9301',
      width:'15%',
      confirmButtonText: 'Confirm',
      background:'#d6dde0',
    }).then(async (result) => {
      if (result.isConfirmed) {

        this.log.push(
          {
            id: UUID.UUID(),
            action: 'Station Manager deactivate',
            userId: this.authService.userDetails.id,
            userName: this.authService.userDetails.username,
            date: Date.now(),
            details: {
              action: 'deativate manager - ' + this.selectedManager.username,
              response: Date.now()
            }
          }
        )
        this.selectedManager.log = this.log;

        const accessToken = await firebase.default.auth().currentUser.getIdToken();
        this.selectedManager.status='inactive'
        this.dataService.updateManager(this.selectedManager, accessToken).subscribe(
          async (res) => {
           console.log(res);
           this.notificationService.success('::'+res['status']+' - '+res['message'] );
           this.dataService.getStationManagers();
           this.onClose()
          });

      }
    })

  }

}
