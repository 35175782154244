import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss']
})
export class SideComponent implements OnInit {

  @Input() showHome;
  @Input() showOperation;
  @Input() showAccounting;
  @Input() showFleet;
  @Input() showUsers;
  @Input() showConfiguration;

  permission={};

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goDashboard(){
    this.router.navigate(['/dashboard'])
  }

  goOperations(){
    this.router.navigate(['/operations'])
  }

  goFinance(){
    this.router.navigate(['/accounting'])
  }

  goFleet(){
    this.router.navigate(['/fleet'])
  }

  goConfiguration(){
    this.router.navigate(['/configurations'])
  }

  goUser(){
    this.router.navigate(['/users'])

  }

}
