
  <div class="row p-flex-jc-sb">
    <div class="col">
     <h3 class="mat-h2 title-text">Expense Summary</h3>
    </div>
    <div class="col date-align">
     <!-- <mat-form-field >
       <mat-label>Select Date</mat-label>
       <mat-date-range-input [rangePicker]="picker">
         <input matStartDate placeholder="Start date"  (dateChange)="startDateChange($event)">
         <input matEndDate placeholder="End date"  (dateChange)="endDateChange($event)">
       </mat-date-range-input>
       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
       <mat-date-range-picker #picker></mat-date-range-picker>
     </mat-form-field> -->
    </div>
  </div>


<mat-table #table [dataSource]="dataSource" *ngIf="dataSource">

  <ng-container matColumnDef="entry_date">
    <mat-header-cell *matHeaderCellDef> Entry Date </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element[0]}}
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expense_amount">
    <mat-header-cell *matHeaderCellDef> Total </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element[1].amount|number}}
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="view">
    <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-stroked-button (click)="download(element[1].entries)" class="btn-submit-p"><mat-icon>download</mat-icon></button>
      <!-- <button mat-stroked-button (click)="download(element[1].entries)" class="btn-submit-p"> View</button> -->
      <!-- {{element.vehicleReg}}({{element.fleetId}}) -->
       </mat-cell>
  </ng-container>









  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
[pageSize]="7"
[pageSizeOptions]="[5,7,10,14,21,28]"
             [showFirstLastButtons]="true" >
</mat-paginator>
