
 <mat-table #table [dataSource]="dataSource" *ngIf="dataSource">

  <ng-container matColumnDef="trip_date">
    <mat-header-cell *matHeaderCellDef> Trip Date </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.trip_date}}
       </mat-cell>
  </ng-container>
  <!-- Position Column -->
  <ng-container matColumnDef="trip_revenue">
    <mat-header-cell *matHeaderCellDef> Revenue </mat-header-cell>
    <mat-cell *matCellDef="let element">
      N{{element.trip_revenue|number}}
       </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="trip_count">
    <mat-header-cell *matHeaderCellDef> Bookings </mat-header-cell>
    <mat-cell *matCellDef="let element">
       {{element.trip_count|number}}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
[pageSize]="7"
[pageSizeOptions]="[5,7,10,14,21,28]"
             [showFirstLastButtons]="true" >
</mat-paginator>
