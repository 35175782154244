import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServicesService } from './auth-services.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {

  constructor(private authService: AuthServicesService, private router: Router ){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // // console.log('In Guard')
      //  this.authService.authInfo$.subscribe(user => {
      //   // console.log('Inside Guard', user)
      //   if (user['$uid']) {
      //     return this.router.navigate(['/dashboard']);
      //   } else {
      //    return  this.router.navigate(['/login']);
      //   }
      // });
      // return;
      return this.authService.checkAuth().then(user => {
        // console.log(user)
        if (user) {
          return true;
        } else {
          this.router.navigate(['/login']);
        }
      });
  }

}
