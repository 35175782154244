import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { DataServicesService } from 'src/app/services/data-services.service';
import { AuthServicesService } from 'src/app/services/auth-services.service';

import { NgForm } from '@angular/forms';

import { MatDialog, MatDialogConfig} from '@angular/material/dialog'
import { OnlineBookingsComponent } from 'src/app/modals/online-bookings/online-bookings.component';

@Component({
  selector: 'app-online-payments',
  templateUrl: './online-payments.component.html',
  styleUrls: ['./online-payments.component.scss']
})
export class OnlinePaymentsComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 5;

  reportStartTime = 0;
  reportEndTime = Date.now();
  stationData;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'date',
    'totalBooking',
    'amountPaid',
    // 'amountDue',
    'btnView',
    'btnDownload'

    ];

  financialPermissions = false;
  transactions;
  displayedTransactions;

  allOnlineBooking: {}[];
  sortedOnlinePayments = [];
  constructor(private dataService: DataServicesService, private authService: AuthServicesService, private dialog: MatDialog){

  }

  async ngOnInit() {
    this.dataService.getAllOnlineBooking();
    this.dataService.stationData.subscribe(stationData => {
      this.stationData = stationData;
      // console.log(this.stationData)
      const viewFinancials = 'viewFinancials';
      const permissions = 'permissions';
      this.authService.userData.subscribe(userData => {
        if (userData){
          this.financialPermissions = userData[permissions].viewFinancials;
          if (this.financialPermissions) {
            // this.dataService.onlinePayments.subscribe(onlineTransactions => {
            //   this.allOnlineBooking = onlineTransactions;
            //   this.sortOnlineBooking(onlineTransactions);
            // });  allOnlineBooking
            this.dataService.allOnlineBooking.subscribe(onlineTransactions => {
              // console.log(onlineTransactions)
              if(onlineTransactions){
                this.allOnlineBooking = onlineTransactions;
                this.sortOnlineBooking(onlineTransactions);
              } else {

              }

            })
          }
        }
      });
    });
  }

  ngAfterViewInit() {
    if (this.sortedOnlinePayments){
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  sortOnlineBooking(onlineTransactions){
    // const sortedPayment = {};
    this.tableData = [];
    // onlineBooking.sort((b, a) => a['paymentDate'] <= b['paymentDate'] ? -1 : 1);
    // for (const booking in onlineBooking) {
    //   if (onlineBooking.hasOwnProperty(booking)) {
    //     const oneBooking = onlineBooking[booking];
    //     const oneBookingDate = new Date(parseInt(oneBooking['paymentDate'], 10));
    //     const dateComponents = oneBookingDate.toDateString().split(' ');
    //     const paymentDay = dateComponents[0] + ', ' + dateComponents[1] + ' ' + dateComponents[2] + ' ' + dateComponents[3];
    //     const paymentAmount = oneBooking['amountPaid'];
    //     const tripCost = oneBooking['tripCost'];
    //     const totalBooking = 'totalBooking';
    //     const totalAmount = 'totalAmount';
    //     const bookings = 'bookings';
    //     const amountDue = 'amountDue'


    //     const commissionType = 'commissionType'
    //     let transporterDue = 0;

    //     if(oneBooking[commissionType]==='Fixed'){
    //       if((paymentAmount===tripCost) ||( oneBooking['arriveDiscount'])){
    //         transporterDue = tripCost - oneBooking['onlineFixedCommission'] - oneBooking['onlineFixedCommission2']

    //       } else {
    //         transporterDue = paymentAmount - oneBooking['onlineFixedCommission'] - oneBooking['onlineFixedCommission2']

    //       }


    //     } else {
    //       if((paymentAmount===tripCost) ||( oneBooking['arriveDiscount'])){
    //         transporterDue = tripCost - (oneBooking['onlineCommission']) || tripCost - (tripCost * this.stationData.onlineCommision)
    //       } else {
    //         transporterDue = paymentAmount - (oneBooking['onlineCommission']) || paymentAmount - (paymentAmount * this.stationData.onlineCommision)
    //       }

    //     }

    //     if (sortedPayment[paymentDay]) {
    //       sortedPayment[paymentDay].totalBooking = sortedPayment[paymentDay].totalBooking + 1;
    //       sortedPayment[paymentDay].totalAmount = sortedPayment[paymentDay].totalAmount + paymentAmount;
    //       sortedPayment[paymentDay].amountDue = sortedPayment[paymentDay].amountDue + transporterDue
    //       sortedPayment[paymentDay].bookings.push(oneBooking);
    //     } else {
    //       const bookingObject = {};
    //       bookingObject[totalBooking] = 1;
    //       bookingObject[totalAmount] = paymentAmount;
    //       bookingObject[bookings] = [oneBooking];
    //       bookingObject[amountDue] = transporterDue;

    //       sortedPayment[paymentDay] = bookingObject;

    //     }
    //   }
    // }
    this.sortedOnlinePayments = onlineTransactions;//Object.entries(sortedPayment);
    this.sortedOnlinePayments.forEach(el => {
      this.tableData.push({
        date: el[0],
        totalBooking: el[1].totalBooking,
        amountPaid: el[1].totalAmount,
        amountDue: el[1].amountDue,//(el[1].totalAmount) * (1-this.stationData.onlineCommision),
        bookings: el[1].bookings
      })
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;

    })

  }



  startDateChange($event){
    if ($event) {
      this.reportStartTime = new Date($event.value).valueOf();
      // this.dataService.onlinePayments.subscribe(onlineTransactions => {
      //   const sortedPayment = onlineTransactions.filter(el=> {
      //     return (el['paymentDate']> this.reportStartTime) && (el['paymentDate']< this.reportEndTime)
      //   })
      //   this.sortOnlineBooking(sortedPayment);
      // })

    }
  }

  endDateChange($event){
    if ($event){
      this.reportEndTime = new Date($event.value).valueOf() + (24 * 60 * 60 * 1000);
      // this.dataService.onlinePayments.subscribe(onlineTransactions => {
      //   const sortedPayment = onlineTransactions.filter(el=> {
      //     return (el['paymentDate']> this.reportStartTime) && (el['paymentDate']< this.reportEndTime)
      //   })
      //   this.sortOnlineBooking(sortedPayment);
      // })
      if(this.reportStartTime && this.reportEndTime){
        this.dataService.getAllOnlineBooking(this.reportStartTime,this.reportEndTime)
      }
    }
  }
  viewBookings(bookings){
    // console.log(bookings.bookings)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig. panelClass ='my-custom-dialog-class';
    this.dataService.dateOnlineBooking = bookings.bookings;
    this.dialog.open(OnlineBookingsComponent,dialogConfig)
  }

  download(bookings){
    const headerList = ['Passenger','Trip', 'Travel Date' ,'Vehicle', 'Origin Park','Amount Paid', 'Amount Remitted']
      const objArray = [];
      let passenger = '';
      let vehicle = '';
      let tripTitle = '';
      let manager = '';
      let originStation = ''
      bookings.bookings.forEach(req => {
        passenger = req.passengerName;
        tripTitle = req.tripTitle;
        vehicle = req.vehicleReg
        originStation = req.originStationAddress
        passenger = passenger.replace(/,/g, ';')
        tripTitle = tripTitle.replace(/,/g, ';')
        vehicle = vehicle.replace(/,/g, ';')
        originStation  = originStation.replace(/,/g, ';')
        console.log(req.amountPaid)
        const oneBookingDate = new Date(req.tripDate);
        const dateComponents = oneBookingDate.toLocaleString().split(' ');
        // console.log(dateComponents)
        const tripDay = dateComponents[0] + ', ' + dateComponents[1] + ' ' + dateComponents[2] + ' ' + dateComponents[3];
        let oneRequest = {
          'Travel Date': dateComponents[1] + ' '+ new Date(Number(req.tripDate)).toDateString(),
          'Passenger': passenger,
          'Trip' : tripTitle,
          'Vehicle' : vehicle ,
          'Origin Park' : originStation,
          'Amount Paid' : 'N '+req.amountPaid,
          'Amount Remitted' :'N '+ req.amountPaid * (1-this.stationData.onlineCommision),

        }
        objArray.push(oneRequest)
      })

      if(objArray.length){
        const data = this.ConvertToCSV(objArray,headerList);
        const fileName = 'Online Booking For '+bookings.date;
        this.downloadFile(data,fileName)
      }

  }



  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
     row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
     let line = (i+1)+'';
     for (let index in headerList) {
      let head = headerList[index];
      line += ',' + array[i][head];
     }
     str += line + '\r\n';
    }
    return str;
   }

  downloadFile(csvData, filename) {
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

}
