import { Component, OnInit,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';

import { FormControl, FormGroup } from '@angular/forms';



import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color,Label } from 'ng2-charts';
import { DataServicesService } from 'src/app/services/data-services.service';
import { RoutesRecognized } from '@angular/router';


@Component({
  selector: 'app-trip-route-chart',
  templateUrl: './trip-route-chart.component.html',
  styleUrls: ['./trip-route-chart.component.scss']
})
export class TripRouteChartComponent implements OnInit,OnDestroy {

  private bookingSubscription$: Subscription;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  color = ['#ed681d', '#F8CA0F', '#a32727' , '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600','#743411','#0c5922',
            '#bea413','#668d1c','#2a778d','#a9c413','#9c5935','#f4359e','#b91383','#16d620','#b77322','#3b3eac','#5574a6','#329262','#651067',
            '#8b0707','#e67300','#6633cc','#aaaa11','#22aa99','#994499','#3366cc','#316395','#b82e2e','#66aa00','#dd4477','#0099c6','#990099'];

  public barChartColors: Color[] = [
    {
      borderColor: ['#ed681d', '#F8CA0F', '#a32727' , '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'],
      backgroundColor: ['#ed681d', '#F8CA0F', '#a32727' , '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'],
      borderWidth: 3
    }
  ];

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Select Route(s)' },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  // availableRoutes =[];
  type = 'amount';
  tripRoutes;
  displayItem = [];

  routes; // = new FormControl();
  availableRoutes: string[] = [];

  reportStartTime = 0;//Date.now() - (24*60*60*1000);
  reportEndTime = Date.now() + 2*(24 * 60 * 60 * 1000);
  constructor(private dataService: DataServicesService,private alertCtrl: AlertController) { }

  ngOnInit(): void {

    this.bookingSubscription$ = this.dataService.routesArray.subscribe(routes=>{
      if(routes.length){
        // console.log(routes)
        this.displayItem[0] = routes[0].tripTitle;
        if(routes.length>1){
          this.displayItem[1] = routes[1].tripTitle;
        }
      }
    });

    this.dataService.tripRoute.subscribe(tripRoutes => {
      if (tripRoutes){
        // console.log(tripRoutes)
        this.tripRoutes  = tripRoutes;
        this.getRevenueGraph();
      }

    });
  }

  public ngOnDestroy(): void {
    if(this.bookingSubscription$){
      this.bookingSubscription$.unsubscribe();
    }

  }

  getRevenueGraph(){
    const routeObject = {}
    this.availableRoutes = [];
    const availableRoutes={};
    this.type = 'amount';
    this.barChartLabels  = []
    this.barChartData[0].data = []
    this.tripRoutes.forEach(record =>{
      if(record){
      let trip_title = '';
      let trip_count = 0;
      let trip_amount = 0;
      if(record.tripTitle){
        // console.log('title',record.tripTitle)
        trip_title = record.tripTitle;
      }
      if(record.trip_count){
        trip_count = record.trip_count
      }
      if(record.trip_amount){
        trip_amount = record.trip_amount
      }
      if(routeObject[record.tripDate.value]){

        routeObject[record.tripDate.value].push({
          trip_title,
          trip_count ,
          trip_amount ,
        })
      } else {
        routeObject[record.tripDate.value]=[];
        routeObject[record.tripDate.value].push({
          trip_title,
          trip_count ,
          trip_amount  ,
        })
      }
      availableRoutes[record.tripTitle]=1;
    }
    });


    this.availableRoutes = Object.keys(availableRoutes);
    // console.log('keys',this.availableRoutes)

    const routeObjectKeys = Object.keys(routeObject)
    const routeObjectElements = Object.values(routeObject)
    let  day ;

    // console.log('Display items', this.displayItem)
    this.displayItem.forEach((item,ind) => {
      this.barChartData[ind]=  { data: [], label: '' },
      routeObjectKeys.forEach((dailyItem,index) =>{
        if(ind===0){
          this.barChartLabels.push(dailyItem);
        }
        // this.barChartLabels.push(dailyItem);
        day = routeObjectElements[index];
        const dayTrip = day.find(el=>el.trip_title===item)
        if(dayTrip){
          this.barChartData[ind].data.push(dayTrip['trip_amount']);
        } else {
          this.barChartData[ind].data.push(0);
        }

      })
      this.barChartData[ind].label = item
      this.barChartData[ind].backgroundColor = this.color[ind] || '#000000'
    });

  }

  getCountGraph(){
    const routeObject = {}
    this.availableRoutes = [];
    const availableRoutes={};
    this.type = 'count';
    this.barChartLabels  = []
    this.barChartData[0].data = []
    this.tripRoutes.forEach(record =>{
      if(record){
      let trip_title = '';
      let trip_count = 0;
      let trip_amount = 0;
      if(record.tripTitle){
        trip_title = record.tripTitle;
      }
      if(record.trip_count){
        trip_count = record.trip_count
      }
      if(record.trip_amount){
        trip_amount = record.trip_amount
      }
      if(routeObject[record.tripDate.value]){

        routeObject[record.tripDate.value].push({
          trip_title,
          trip_count ,
          trip_amount ,
        })
      } else {
        routeObject[record.tripDate.value]=[];
        routeObject[record.tripDate.value].push({
          trip_title,
          trip_count ,
          trip_amount  ,
        })
      }
      availableRoutes[record.tripTitle]=1;
    }
    });


    this.availableRoutes = Object.keys(availableRoutes);

    const routeObjectKeys = Object.keys(routeObject)
    const routeObjectElements = Object.values(routeObject)
    let  day ;
    this.displayItem.forEach((item,ind) => {
      this.barChartData[ind]=  { data: [], label: '' },
      routeObjectKeys.forEach((dailyItem,index) =>{
        if(ind===0){
          this.barChartLabels.push(dailyItem);
        }
        // this.barChartLabels.push(dailyItem);
        day = routeObjectElements[index];
        const dayTrip = day.find(el=>el.trip_title===item)
        if(dayTrip){
          this.barChartData[ind].data.push(dayTrip['trip_count']);
        } else {
          this.barChartData[ind].data.push(0);
        }

      })
      this.barChartData[ind].label = item
      this.barChartData[ind].backgroundColor = this.color[ind] || '#000000'
    });
  }

  // async selectItems(){
  //   const displayItems = [];
  //   this.availableRoutes.forEach(item=>{
  //     if(item){
  //       displayItems.push({
  //         name: item,
  //         type: 'checkbox',
  //         label: item,
  //         value: item
  //       });
  //     }
  //   });

  //   console.log(this.availableRoutes)
  //   const alert = await this.alertCtrl.create({
  //     header: 'Select Routes to View',
  //     inputs: displayItems,
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: () => {
  //           console.log('Confirm Cancel');
  //         }
  //       }, {
  //         text: 'Ok',
  //         cssClass: 'primary',
  //         handler: async  (data) => {
  //           if(data.length){
  //             if(data){
  //               this.displayItem = data;
  //               this.getRevenueGraph();
  //             }
  //           }
  //         }
  //       }
  //     ]

  //   })
  //   await alert.present();
  // }


  routeChange($event){
    // console.log($event.value)
    this.displayItem.forEach((dis,index)=>{
      this.barChartData[index]=  { data: [], label: '' }
    });
    this.displayItem = $event.value;
    this.getRevenueGraph();

  }

  startDateChange($event){
    if ($event) {
      this.reportStartTime = new Date($event.value).valueOf();
    }

  }

  endDateChange($event){
    if ($event){
      // const lastTime = this.reportEndTime;
      this.reportEndTime = new Date($event.value).valueOf() + 2*(24 * 60 * 60 * 1000);
      this.dataService.getTripRoute(this.reportStartTime,this.reportEndTime);

    }

  }


  routeDownload(){

    const headerList = ['Booking Date','Route','Booking Count','Revenue']

    const objArray = [];
    this.tripRoutes.forEach((req,index) => {
      let oneDay = {
        'Booking Date': req.tripDate.value,
        'Route': req.tripTitle,
        'Revenue': Number(req.trip_amount),
        'Booking Count':  Number(req.trip_count)
      }
      objArray.push(oneDay)
    })

    if(objArray.length){
      const data = this.ConvertToCSV(objArray,headerList);
      const fileName =  'Booking Routes';//+new Date().toLocaleTimeString();
      this.downloadFile(data,fileName)
    }


   }

    ConvertToCSV(objArray, headerList) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = 'S.No,';
      for (let index in headerList) {
       row += headerList[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
       let line = (i+1)+'';
       for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
       }
       str += line + '\r\n';
      }
      return str;
     }

    downloadFile(csvData, filename) {
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }




}
