import { Component, OnInit,Input } from '@angular/core';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() showHome;

  loggedInInitials='EA';
  displayName = 'Development';
  transporter = ''
  reportStartTime = 0;
  reportEndTime = Date.now();
  userData;
  constructor(private dataService: DataServicesService,private authServe: AuthServicesService) { }

  ngOnInit(): void {
    this.authServe.userData.subscribe(user=> {
      if(user){
        this.userData = user;
        this.displayName = user['username'];
        this.transporter = user['transporter']
        // this.phoneNumber = user.phoneNumber||'';
        // this.email = user.email;


        const nameArray = this.displayName.split(' ');
        this.loggedInInitials = '';
        nameArray.forEach(element => {
          if(element[0]){
            this.loggedInInitials = this.loggedInInitials + element[0]||'';
          }
        });
        // this.createUser()

        // console.log(user)
      }

    });
  }

  logout(){
    this.authServe.logout();
  }

  startDateChange($event){
    if ($event.value) {
      console.log('start')
      // console.log($event.value)
      // this.dataService.spinnerBehaviour.next( true);
      // console.log('Start Spinner', Date.now())
      this.reportStartTime = new Date($event.value).valueOf();
      // this.dataService.timeRangeChange(this.reportStartTime, this.reportEndTime);
    }
  }

  endDateChange($event){
    if ($event.value){
      console.log('End', )
      this.dataService.spinnerBehaviour.next( true);
      this.reportEndTime = new Date($event.value).valueOf() + (24 * 60 * 60 * 1000);
      this.dataService.timeRangeChange(this.reportStartTime, this.reportEndTime);
    }
  }

}
