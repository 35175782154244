import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UUID } from 'angular2-uuid';

import { AuthServicesService } from 'src/app/services/auth-services.service';
import { DataServicesService } from 'src/app/services/data-services.service';

import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-view-vehicle',
  templateUrl: './view-vehicle.component.html',
  styleUrls: ['./view-vehicle.component.scss']
})
export class ViewVehicleComponent implements OnInit {

  vehicleTypes = [];
  states = [];
  selectedVehicleType;
  isUpdate = false;
  totalPassengers;
  loading = false;
  documents = [];
  public managerPermission;
  dateEnlisted1 = new Date().valueOf();
  image;
  importantNotes = '';

  form: FormGroup = new FormGroup({
    // $key: new FormControl(null),
    regNum: new FormControl('', Validators.required),
    regState: new FormControl('', Validators.required),
    owner: new FormControl('', Validators.required),
    fleetId: new FormControl('', Validators.required),
    dateEnlisted: new FormControl(),
    totalPassengers: new FormControl(0),
    initialCapital: new FormControl('', Validators.required),
    currentDriver: new FormControl('', Validators.required),
    importantNotes: new FormControl('', Validators.required),
    vehicleType: new FormControl('', Validators.required)
  });
  constructor(public dialogRef: MatDialogRef<ViewVehicleComponent>, public dataService: DataServicesService,private storage: AngularFireStorage, private authService: AuthServicesService) {
    if (this.dataService.openedVehicle){
      this.isUpdate = true;
      this.selectedVehicleType = this.dataService.openedVehicle.vehicleType;
    }
   }

  ngOnInit(): void {
    this.dataService.vehicleType.subscribe(vehicleTypes => {
      if (vehicleTypes){
        this.vehicleTypes = vehicleTypes;
      }
    });
    this.managerPermission = this.authService.userDetails.permissions;
    this.states = this.dataService.states;
    if (this.dataService.openedVehicle){
      // console.log(this.dataService.openedVehicle);
      this.isUpdate = true;
      this.form.get('regNum').setValue(this.dataService.openedVehicle.regNum);
      this.form.get('regState').setValue(this.dataService.openedVehicle.regState);
      this.form.get('owner').setValue(this.dataService.openedVehicle.owner);
      this.form.get('totalPassengers').setValue(this.dataService.openedVehicle.totalPassengers);
      this.form.get('currentDriver').setValue(this.dataService.openedVehicle.currentDriver);
      this.form.get('vehicleType').setValue(this.dataService.openedVehicle.vehicleType);
      this.form.get('importantNotes').setValue(this.dataService.openedVehicle.importantNotes);
      this.form.get('fleetId').setValue(this.dataService.openedVehicle.fleetId);
      // this.form.get('dateEnlisted').setValue(new Date(this.dataService.openedVehicle.dateEnlisted));
      this.form.get('initialCapital').setValue(this.dataService.openedVehicle.initialCapital);
      this.totalPassengers = this.dataService.openedVehicle.totalPassengers;
      this.documents = this.dataService.openedVehicle.documents || [];
      this.image = this.dataService.openedVehicle.image;
      // this.dateEnlisted = new FormControl(new Date(this.dataService.openedVehicle.dateEnlisted))
      this.importantNotes = this.dataService.openedVehicle.importantNotes;
    }
  }

  onClose() {

    // console.log(this.form.value);
    this.dataService.openedVehicle = null;
    this.dialogRef.close();
  }

  selectVehicle($event) {
    this.selectedVehicleType = $event.value;
    this.form.value.totalPassengers = $event.value.totalPassengers;
    this.form.value.image = $event.value.image;
    this.totalPassengers = $event.value.totalPassengers;
  }

  cardCol(size){
    const col = 'col-' + Math.round(size) + ' text-center';
    return col.toString() ;
  }

  SaveImageRef(filePath, file) {
    return {
      task: this.storage.upload(filePath, file)
      , ref: this.storage.ref(filePath)
    };
  }

  chooseImage($event,image?){
    console.log(image)
    const datefolder = new Date().toISOString().substr(0, 10).replace('-', '');
    // console.log($event);
    this.loading = true;
    // this.util.openInfLoader();
    const pickedFile = (event.target as HTMLInputElement).files[0];
    if (pickedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(pickedFile);
      // For Preview Of Image
      reader.onload = (e:any) => { // called once readAsDataURL is completed
        // For Uploading Image To Firebase
        const fileraw = pickedFile;
        // console.log(fileraw);
        // this.imageFile = fileraw.name;
        const fileame = Date.now()
        const filePath = '/vehicledocuments/' + this.dataService.stationId + '/' + fileame;
        const result = this.SaveImageRef(filePath, fileraw);
        const ref = result.ref;
        result.task.then(a => {
          ref.getDownloadURL().subscribe( a => {
            // console.log(a);

            if (image){
              this.image = a;
            } else {
              this.documents.push({
                name: fileame,
                link: a
              });
            }
            this.loading = false;
            // this.util.closeLoading();
            // this.fileName = '';
          });

        });
      }, (error) => {
        alert('Error'),
        this.loading = false;
      // this.util.closeLoading();
    }
    }

   }

   async addVehicle(){
    const update = {
      date: Date.now(),
      action: 'Enrollment from dashboard',
      userId: this.dataService.userDetails.id,
      userEmail: this.dataService.userDetails.email,
      userName: this.dataService.userDetails.username
    }
    const newVehicle = {
      id: UUID.UUID(),
      regNum: this.form.value.regNum,
      regState: this.form.value.regState,
      dateEnlisted: this.dateEnlisted1,
      status: 'New',
      drivers: [],
      documents: this.documents,
      currentDriver: this.form.value.currentDriver,
      currentDriverStart: Date.now(),
      expenses: [],
      maintenance: [],
      insurance: [],
      initialCapital: this.form.value.initialCapital || 0,
      vehicleType: this.form.value.vehicleType,
      updateLog: [],
      totalPassengers: this.totalPassengers,
      importantNotes: this.form.value.importantNotes || '',
      stationId: this.dataService.stationId,
      stationName: this.dataService.userDetails.transporter,
      owner: this.form.value.owner,
      fleetId: this.form.value.fleetId,
      image: this.image || ''

    };
    newVehicle.updateLog.push(update);

    const b1 = await this.dataService.create('fleet', newVehicle);
    this.dataService.getFleet();
    this.onClose();

   }

   startDateChange($event){
    if ($event) {
      console.log($event.value)
      this.dateEnlisted1 = new Date($event.value).valueOf();
      console.log(this.dateEnlisted1)
    }
  }

   cancel(){
    console.log(JSON.stringify(this.form.value));
   }

   async updateVehicle(){
     console.log(JSON.stringify(this.form.value));
     const update = {
      date: Date.now(),
      action: 'Update from dashboard',
      userId: this.dataService.userDetails.id,
      userEmail: this.dataService.userDetails.email,
      userName: this.dataService.userDetails.username,
      oldValue: this.dataService.openedVehicle
    };

     const updatedVehicle = this.dataService.openedVehicle;
     updatedVehicle.updateLog = this.dataService.openedVehicle.updateLog.push(update);
     updatedVehicle.regNum =  this.form.value.regNum;
     updatedVehicle.regState = this.form.value.regState;
     updatedVehicle.documents = this.documents;
     updatedVehicle.currentDriver = this.form.value.currentDriver;
     if (this.dataService.openedVehicle.currentDriver !== this.form.value.currentDriver){
       updatedVehicle.currentDriverStart = Date.now();
     }
     updatedVehicle.vehicleType =  this.selectedVehicleType;
     if (this.dataService.openedVehicle.importantNotes !== this.form.value.importantNotes){
      updatedVehicle.importantNotes = this.dataService.openedVehicle.importantNotes + ' || ' + this.form.value.importantNotes;
    }
     updatedVehicle.image = this.image;
     updatedVehicle.owner = this.form.value.owner,
     updatedVehicle.fleetId = this.form.value.fleetId;
     updatedVehicle.initialCapital = this.form.value.updatedVehicle;

     const b1 = await this.dataService.create('fleet', updatedVehicle);
     this.dataService.getFleet();
     this.onClose();

  }

  attachDocument(){

  }

  selectSeat(seat){}

  bookedSeat(){}

  selectedSeat(){}

  unAvailableSeat(){
  }


}
