
<div class="row">
  <div class="col-6">
    <mat-form-field  >
      <mat-label>Select Date</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date"  (dateChange)="startDateChange($event)">
        <input matEndDate placeholder="End date"  (dateChange)="endDateChange($event)">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="col-6">
    <mat-form-field *ngIf="dataSource">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Name" #input>
    </mat-form-field>
  </div>

  </div>

<mat-table #table [dataSource]="dataSource" *ngIf="tableData.length">

  <ng-container matColumnDef="vehicleType">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-15'"> Vehicle </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'w-15'">
      <div (click)="selectTrip(element)" >
        {{element.vehicleType.vehicleType}}
        <!-- <p class="sub" >{{element.vehicleReg}} - {{element.fleetId}} </p> -->
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="tripTitle">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-20'"> Route </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'w-20'">
      <div  (click)="selectTrip(element)">
        {{element.tripTitle}}
        <h4 class="sub">{{element.tripDate | date:'EEE, MMM d, HH:mm'}} </h4>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="originDetails">
    <mat-header-cell *matHeaderCellDef >Origin</mat-header-cell>
    <mat-cell *matCellDef="let element"  [ngClass]="'th-left'">
      <div  (click)="selectTrip(element)">
        {{ (element.originStationAddress.length>15)? (element.originStationAddress | slice:0:12)+'..':(element.originStationAddress) }}
        <!-- {{element.originStationAddress}} -->
        <!-- <h4 class="sub"> Stops: {{element.stops}} </h4> -->
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="seats">
    <mat-header-cell *matHeaderCellDef >Seats/Vacant</mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="'th-center'">
      <div (click)="selectTrip(row)">
        {{row.totalPassengers}} /{{row.totalPassengers - row.paidPassengers.length}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="availableSeats">
    <mat-header-cell *matHeaderCellDef >Available Seats</mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text">{{row.totalPassengers - row.paidPassengers.length}}
    </mat-cell>
  </ng-container> -->

  <ng-container matColumnDef="paidPassengers">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-10'">Paid</mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="'w-10'">{{row.paidPassengers.length}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-10'">Fare</mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="'w-10'">{{row.tripCost|number}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="bookingBtn">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-10'"></mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="'w-10'">
        <button mat-stroked-button (click)="open(row)" [disabled]="!(row.totalPassengers - row.paidPassengers.length)" class="btn-submit">
          <span *ngIf="(row.totalPassengers - row.paidPassengers.length)"> Book</span>
          <span *ngIf="!(row.totalPassengers - row.paidPassengers.length)" >Filled</span>
        </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="view">
    <mat-header-cell *matHeaderCellDef   [ngClass]="'w-10'"></mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="'w-10'">
        <ion-button  >
          <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
        </ion-button>
        <mat-menu #menu="matMenu" >
          <ion-button mat-menu-item (click)="closeTrip(row)" [disabled]="!closeTripPermission">
             Close Trip
          </ion-button>
          <button mat-menu-item (click)="downloadManifest(row)" >
            Manifest
          </button>
        </mat-menu>
    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="closeBtn" >
    <mat-header-cell *matHeaderCellDef ></mat-header-cell>
    <mat-cell *matCellDef="let row">
        <button mat-stroked-button (click)="closeTrip(row)" class="btn-close" [disabled]="!closeTripPermission">Close Trip</button>
    </mat-cell>
  </ng-container> -->

  <!-- <ng-container matColumnDef="downloadBtn">
    <mat-header-cell *matHeaderCellDef ></mat-header-cell>
    <mat-cell *matCellDef="let row">
        <button mat-stroked-button (click)="downloadManifest(row)">Manifest</button>
    </mat-cell>
  </ng-container> -->






<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<!-- <mat-paginator #paginator
[pageSize]="7"
[pageSizeOptions]="[7,10,14,21,28]"
             [showFirstLastButtons]="true" >
</mat-paginator> -->

<div class="row">
  <div class="col-4  mt-2 align-content-center">
    <button   mat-stroked-button class="btn-submit" (click)="addTrip()" *ngIf="createTripPermission">Create Trip</button>
  </div>
  <div class="col-8 text-right" *ngIf="tableData.length">
    <mat-paginator #paginator
[pageSize]="7"
[pageSizeOptions]="[7,10,14,21,28]"
             [showFirstLastButtons]="true" >
</mat-paginator>
</div>


