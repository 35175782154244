<div class="div-padding">
  <!-- <div [ngStyle]="cardStyle()" >
    <img [src]="stationData?.logo" *ngIf="stationData?.logo" alt="Logo" class="img-park"/>
  </div> -->
  <div class="row mt-2">
    <div class="col">
      <h4 class="table-main-text">
        Company Name
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.name}}
      </h4>
    </div>
    <div class="col">
      <h4 class="table-main-text">
        Office Address
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.address}}
      </h4>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col">
      <h4 class="table-main-text">
        Contact Name
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.contactName}}
      </h4>
    </div>
    <div class="col">
      <h4 class="table-main-text">
        Phone Number
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.phone}}
      </h4>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-6">
      <h4 class="table-main-text">
        Official Email
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.email}}
      </h4>
    </div>
    <div class="col-6">
      <h4 class="table-main-text">
        State
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.state}}
      </h4>
    </div>
  </div>

  <div class="hor-seperator"></div>

  <div class="row mt-2">
    <div class="col">
      <h4 class="table-main-text">
        Account Name
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.accountName}}
      </h4>
    </div>
    <div class="col">
      <h4 class="table-main-text">
        Bank Name
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.splitPaymentData?.bank_name
        }}
      </h4>
    </div>

    <div class="col">
      <h4 class="table-main-text">
        Account Number
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.splitPaymentData?.account_number
        }}
      </h4>
    </div>
  </div>

  <div class="hor-seperator"></div>

  <div class="row mt-2">
    <div class="col">
      <h4 class="table-main-text">
        Completed Trips
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.trips}}
      </h4>
    </div>
    <div class="col">
      <h4 class="table-main-text">
        Bookings
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.bookings}}
      </h4>
    </div>
    <div class="col">
      <h4 class="table-main-text">
        Available Routes
      </h4>
      <h4 class="table-main-text2">
        {{stationData?.routes?.length}}
      </h4>
    </div>
  </div>


</div>
