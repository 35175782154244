import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NotificationService } from 'src/app/services/notification.service';

import { UUID } from 'angular2-uuid';
import { environment } from 'src/environments/environment';
import { DataServicesService } from 'src/app/services/data-services.service';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import * as firebase from 'firebase/compat/app';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-add-trip',
  templateUrl: './add-trip.component.html',
  styleUrls: ['./add-trip.component.scss']
})
export class AddTripComponent implements OnInit {

  selectedVehicleType;

  public stationData;
  public routes = [];
  public terminals = []
  public fleets;
  public vehicleAC = [];


  dislayRoute
  managerPermissions
  originStationAddress;
  route;
  stops;
  public originStation;
  originTown;
  originState;
  tripTitle;
  uid;
  tripDate;
  creationDate;
  tripCost;
  commissionType = 'Percentage'
  officeFixedCommission = 0;
  officeFixedCommission2 = 0;
  onlineFixedCommission = 0;
  onlineFixedCommission2 = 0;
  vehicleReg;
  driverName;
  vehicleType;
  totalPassengers;
  selectedFleet;
  fleetId;
  features;
  luggageMessage = 'Fares are not refundable after payment. You are allowed maximum of two bags not more than 12Kg each'


  minDate = new Date();
  hour = 8;
  minutes = 0;
  date;
  dataSubmitted = false;

  form: FormGroup = new FormGroup({
    // $key: new FormControl(null),
    originaddr: new FormControl('', Validators.required),
    route: new FormControl(Validators.required),
    stops: new FormControl('', Validators.required),
    date: new FormControl( Validators.required),
    hour: new FormControl(0, Validators.required),
    minutes: new FormControl(0, Validators.required),
    tripCost: new FormControl(0, Validators.required),
    vehicletype: new FormControl('', Validators.required),
    vehicleType: new FormControl( Validators.required),
    vehiclefeature: new FormControl('', Validators.required),
    totalPassengers: new FormControl(0, Validators.required),
    vehicleReg: new FormControl('', Validators.required),
    driverName: new FormControl('', Validators.required),
    luggageMessage: new FormControl('', Validators.required),




  })
  constructor(public dialogRef: MatDialogRef<AddTripComponent>, private notificationService: NotificationService, private dataService: DataServicesService, private authService: AuthServicesService,) { }

  ngOnInit(): void {
    this.dataService.stationData.subscribe(stationData => {
      if(stationData){
        this.stationData = stationData;
        this.routes = this.stationData.routes;
        this.terminals = this.stationData.stations
        // console.log(this.terminals)
        this.managerPermissions = this.authService.userDetails.permissions;
      }
    })

    this.dataService.getFleet();

    this.dataService.fleet.subscribe(fleet => {
      if (fleet){
        this.fleets = fleet;
      }
    })

    this.dataService.constants.subscribe(constants => {
      const vehicleAC = 'vehicleAC';
      this.vehicleAC = constants[vehicleAC];
    });

  }

  onClose(){
    this.dialogRef.close();

  }

  cardCol(size){
    // console.log(size)
    const col = 'col-' + Math.round(size) + ' text-center';
    return col.toString() ;
  }

  async toggleSeatNum(i) {

    const seat = this.vehicleType.seatsLayout[i];
    const seatStatus = seat.status;
    if ((seatStatus === 'Available') || (!seatStatus)) {


      /* inputOptions can be an object or Promise */
    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          'Make Unavailable': 'Unavailable',
          'Make Booked' : 'Booked',
        })
      }, 1000)
    })

    console.log(this.totalPassengers)
    await Swal.fire({
      // title: 'Change the Current Seat Number?',
      titleText: 'Change Seat Status',
      input: 'radio',
      inputOptions: {
        'Unavailable': 'Make Unavailable',
        'Booked' : 'Make Booked',
      },
      // inputAttributes: {
      showCancelButton: true,
      confirmButtonText: 'Change',
      confirmButtonColor:  '#4d9301',
      width:'25%',
      // background:'#d6dde0',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to choose something!'
        } else {
          // console.log(value)
          if (value === 'Unavailable'){
            this.vehicleType.seatsLayout[i].status = value;
            this.totalPassengers--;
          } else {
            this.vehicleType.seatsLayout[i].status = value;
          }
          // console.log(this.totalPassengers)
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      // console.log('newSeatNo')

    })

    } else if (seatStatus === 'Unavailable') {

      await Swal.fire({
        // title: 'Change the Current Seat Number?',
        titleText: 'Change Seat Status',
        input: 'radio',
        inputOptions: {
          'Available': 'Make Available',
          'Booked' : 'Make Booked',
        },
        // inputAttributes: {
        showCancelButton: true,
        confirmButtonText: 'Change',
        confirmButtonColor:  '#4d9301',
        width:'25%',
        // background:'#d6dde0',
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to choose something!'
          } else {
            console.log(value)
            if (value === 'Booked'){
              this.vehicleType.seatsLayout[i].status = value;
            } else {
              this.vehicleType.seatsLayout[i].status = value;
                this.totalPassengers++;
            }
            console.log(this.totalPassengers)
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        console.log('newSeatNo')

      })

    } else if (seatStatus === 'Booked') {
      await Swal.fire({
        // title: 'Change the Current Seat Number?',
        titleText: 'Change Seat Status',
        input: 'radio',
        inputOptions: {
          'Available': 'Make Available',
          'Unavailable' : 'Make Unavailable',
        },
        // inputAttributes: {
        showCancelButton: true,
        confirmButtonText: 'Change',
        confirmButtonColor:  '#4d9301',
        width:'25%',
        // background:'#d6dde0',
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to choose something!'
          } else {
            console.log(value)
            if (value === 'Available'){
              this.vehicleType.seatsLayout[i].status = value;
            } else {
              this.vehicleType.seatsLayout[i].status = value;
              this.totalPassengers--;
            }
            console.log(this.totalPassengers)
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        console.log('newSeatNo')

      })

    }

  }

  terminalChange($evnt){}

  selectRoute($event) {

    const in_route = $event.value;
    console.log(in_route)

   const route = this.routes.find(_route =>{
     return _route.originCity + ' - '+  _route.destinationCity === in_route
   })

    // console.log(route)
    const originStation = 'originStation';
    this.originStation = route[originStation];
    const originCity = 'originCity';
    this.originTown = route[originCity];
    const originState = 'originState';
    this.originState = route[originState];
    this.uid = this.authService.getUserId();
    const stops = 'stops';
    this.stops = route[stops];
    const destinationCity = 'destinationCity';
    this.tripTitle = route[originCity] + ' - ' + route[destinationCity];
    this.route = route;
    this.dislayRoute = route.originCity +'['+route.originState+']'+ '-' +route.destinationCity+'['+route.destinationState+']'

    // console.log(this.tripTitle)
   }

   tripChange() {
    // console.log(this.date)
    this.tripDate = Date.parse(this.date) + this.hour * 60 * 60 * 1000 + this.minutes * 60 * 1000;
    this.creationDate = Date.now();

  }

  updateCommission($event){
    if (this.stationData.commissionType === 'Fixed') {
      // console.log(this.trip.tripCost);
      this.commissionType = 'Fixed'
      const commisionTable = this.stationData.commissionTable;
      for (const comm in commisionTable) {
        if (comm) {
          const commission = commisionTable[comm]
          // console.log('Comm...', commission)
          if (this.tripCost >= commission['lower'] && this.tripCost < commission['higher'] ){
            // console.log('Commision is ', commission['arriveCommission']);
            this.officeFixedCommission = commission['arriveCommission'];
            this.officeFixedCommission2 = commission['marketingCommision'];

            this.onlineFixedCommission = commission['arriveCommission'];
            this.onlineFixedCommission2 = commission['marketingCommision'];

          }
        }
      }
    } else {
      this.commissionType = 'Percentage'
    }
  }

  fleetChange(fleet) {
    this.selectedVehicleType = fleet.value.vehicleType;
    // console.log(fleet)
    this.vehicleReg = fleet.value.regNum;
    this.driverName = fleet.value.currentDriver;
    this.vehicleType = fleet.value.vehicleType;
    this.totalPassengers = fleet.value.totalPassengers;
    this.selectedFleet = fleet.value.regState - fleet.value.regNum;
    this.fleetId = fleet.value.fleetId;
  }


  async addTrip(){

    const newTrip = {
      id: UUID.UUID(),
      uid: this.authService.userDetails.id,
      originStation: this.originStation,
      originTown: this.originTown,
      originState: this.originState,
      route: this.route,
      tripDate: this.tripDate,
      creationDate: this.creationDate,
      tripCost: this.tripCost,
      totalPassengers: this.totalPassengers,
      paidPassengers: [],
      bookedPassengers: [],
      driverName: this.driverName,
      vehicleType: this.vehicleType,
      vehicleReg: this.vehicleReg,
      tripDuration: 0,
      stops: this.stops,
      tripStart: 0,
      tripStop: 0,
      tripTitle: this.tripTitle,
      remmitance:  0,
      remmitanceDate: 0,
      remmitanceNote:  '',
      createdEmail: this.authService.userDetails.email,
      createdId: this.authService.userDetails.id,
      createdUser: this.authService.userDetails.username,
      subaccount_id: this.dataService.stationDetails.subaccount_id,
      stationId: this.dataService.stationDetails.id,
      originStationAddress: this.originStationAddress,
      fleetId: this.fleetId,
      transporter: this.authService.userDetails.transporter || this.authService.userDetails.location,
      image: this.dataService.stationDetails.logo,
      color: this.dataService.stationDetails.color || 'transparent',
      features:this.features,
      luggageMessage: this.luggageMessage,
      officeCommision: this.dataService.stationDetails.officeCommision,
      onlineCommision: this.dataService.stationDetails.onlineCommision,
      changedVehicle: [],
      log: [{
        id: UUID.UUID(),
        action: 'Trip Added',
        userId: this.authService.userDetails.id,
        userName: this.authService.userDetails.username,
        date: Date.now(),
        details: {
          trip: this.tripTitle,
          tripDate: this.tripDate,
          tripOrigin: this.originStation
        }
      }],
      commissionType: this.commissionType,
      officeFixedCommission: this.officeFixedCommission,
      onlineFixedCommission: this.onlineFixedCommission,
      officeFixedCommission2: this.officeFixedCommission2,
      onlineFixedCommission2: this.onlineFixedCommission2,
      subaccount_id2: this.dataService.stationDetails.subaccount_id2 || '',

    };
    console.log(newTrip)
    this.dataSubmitted = true;
    const accessToken = await firebase.default.auth().currentUser.getIdToken();
    this.dataService.createTrip(newTrip, accessToken).subscribe(
      async (res) => {
       console.log(res);
       this.notificationService.success('::'+res['status']+' - '+res['message'] );
       this.onClose()

      });

  }


}
