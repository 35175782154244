<mat-toolbar class="top-bar">
  <span class="title-text">Booking Per Terminal - {{currentBookingDay?.day}}  </span>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>
<div class="row mt-3">
  <div class="col">
    <h4 class="table-main-text" > Cash Booking </h4>
    <h4 class="table-text"> {{currentBookingDay?.cashCount}}  </h4>
  </div>
  <div class="col">
    <h2 class="table-main-text"> Cash Amount</h2>
    <p class="table-text"> {{currentBookingDay?.cash}}</p>

  </div>
  <div class="col">
    <h2 class="table-main-text"> POS Booking</h2>
    <p class="table-text"> {{currentBookingDay?.posCount}}</p>
  </div>
  <div class="col">
      <h2 class="table-main-text"> POS Amount</h2>
      <p class="table-text"> {{currentBookingDay?.pos}}</p>
    </div>
    <div class="col">
      <h4 class="table-main-text"> <strong>Total Booking</strong>  </h4>
      <h4 class="table-text"> {{currentBookingDay?.bookings.length}} </h4>
    </div>
  <div class="col">
    <h4 class="table-main-text"> <strong>Total Amount</strong>  </h4>
    <h4 class="table-text"> {{currentBookingDay?.total}} </h4>
  </div>
</div>
<div class="hor-seperator"></div>

<mat-dialog-content>
<div *ngFor="let item of terminalBookings; let i=index;" >
  <div class="row ml-1 mr-1">
      <div class="col-4 text-left" >
          <h4 class="table-main-text2">{{1+i}}. {{ item[1].name}} </h4>
      </div>
      <div class="col-1">
          <h4 class="table-text"> Cash </h4>
          <h4 class="payment-col"> {{item[1].cashCount}}</h4>
      </div>
      <div class="col-2">
          <h4 class="table-text"> Cash Amount(N)</h4>
          <h4 class="payment-col"> {{item[1].cash | number: '0.2-2'}} </h4>
      </div>
      <div class="col-1">
          <h4 class="table-text"> POS </h4>
          <h4 class="payment-col"> {{item[1].posCount}}</h4>
      </div>
      <div class="col-2">
          <h4 class="table-text"> POS Amount (N)</h4>
          <h4 class="payment-col"> {{item[1].pos | number: '0.2-2'}} </h4>
      </div>
      <div class="col-2">
          <button mat-stroked-button class="btn-submit-b" (click)="download(item[1])">Download</button>
      </div>
  </div>
  <div class="hor-seperator2 "></div>
</div>
</mat-dialog-content>
