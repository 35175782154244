import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataServicesService } from 'src/app/services/data-services.service';
import {MatTableDataSource} from '@angular/material/table';
import * as firebase from 'firebase/compat/app';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';


import Swal from 'sweetalert2'


import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from 'src/app/services/notification.service';
import { AddTerminalComponent } from 'src/app/modals/add-terminal/add-terminal.component';


@Component({
  selector: 'app-terminal-table',
  templateUrl: './terminal-table.component.html',
  styleUrls: ['./terminal-table.component.scss']
})
export class TerminalTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData;
  pageSize = 5;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'terminalName',
    'terminalAddress',
    'terminalPhone',
    'delBtn',
    ];


  terminals;
  stationData;
  constructor(private dataService: DataServicesService, private notificationService: NotificationService,private dialog: MatDialog){}


  ngOnInit() {
    this.dataService.stationData.subscribe(stationData => {
      if(stationData){
        if(stationData['stations']){
          // console.log(stationData['stations'])
          this.stationData = stationData;
          this.terminals = stationData['stations'];
          this.dataService.terminals = this.terminals;
          this.tableData  = this.terminals;
          this.dataSource = new MatTableDataSource(this.terminals);
          this.dataLength = this.terminals.length;
          this.dataSource.paginator = this.paginator;

        }

      }
    })
  }

  ngAfterViewInit() {
    if (this.tableData){
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
    }
  }


  deleteTerminal(station){
    Swal.fire({
      title: 'Delete Terminal',
      text: "Are you sure you want to delete this terminal?",
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor:  '#4d9301',
      width:'15%',
      confirmButtonText: 'Confirm',
      background:'#d6dde0',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.stationData.stations = this.terminals.filter((el) => {
          return station !== el;
        });
        const accessToken = await firebase.default.auth().currentUser.getIdToken();

        this.dataService.updateTerminal(this.stationData.stations, accessToken).subscribe(
          async (res) => {
           console.log(res);
          //  this.util.confirmDialogPassingparameter(res['paymentStatus'], res['message']);
           this.notificationService.success('::'+res['status']+' - '+res['message'] );

          });

      }
    })

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openAddTerminal(){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(AddTerminalComponent, dialogConfig);

  }
}
