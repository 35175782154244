<mat-toolbar class="top-bar">
  <span class="title-text" >Add Terminal </span>
  <!-- <span class="title-text" *ngIf="isUpdate">{{dataService.openedVehicle.fleetId}} - {{dataService.openedVehicle.regNum}}</span> -->
  <span class="fill-remaining-space"></span>
  <!-- <span *ngIf="isUpdate" class="title-text"> Enlisted - {{dataService.openedVehicle.dateEnlisted|date}} </span> -->
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>

<div  class="mb-1">
  <form [formGroup]="form" class="normal-form">
    <div class="controles-container">
      <mat-form-field>
        <input formControlName="stationName" matInput placeholder="Station Name" >
        <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input formControlName="stationAddress" matInput placeholder="Station Address" >
        <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input formControlName="stationPhone" matInput placeholder="Station Contact" maxlength="11" minlength="11">
        <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="row p-flex-jc-sb" >
    <div col-3></div>

    <div class="button-row col-3" >
      <button mat-raised-button  (click)="onClose()" color="warn" >Cancel</button>
    </div>

    <div class="button-row col-3" >
      <button mat-raised-button  (click)="onAdd()" class="btn-submit" [disabled]="!(form.valid)" >Add</button>
    </div>
    <div col-3></div>
  </div>
</div>
