<mat-toolbar class="top-bar">
  <span class="title-text" *ngIf="!isUpdate">Add Vehicle Type</span>
  <span class="title-text" *ngIf="isUpdate">{{dataService.openedVehicleType.vehicleModel}} - {{dataService.openedVehicleType.vehicleType}}</span>
  <span class="fill-remaining-space"></span>
  <span *ngIf="isUpdate" class="title-text"> Enlisted - {{dataService.openedVehicleType.enrolledDate|date}} </span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>


<div class="row">
  <div class="col-6">
    <mat-dialog-content >
        <form [formGroup]="form" class="normal-form">
            <div cols="2" rowHeight="300px">
                <div>
                  <div class="controles-container">
                    <div class="row">
                      <div class="col-6">
                        <mat-form-field>
                          <input formControlName="vehicleModel" matInput placeholder="Vehicle Model"  [(ngModel)]="vehicleModel" >
                          <mat-error>This field is mandatory.</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-6">
                        <mat-form-field>
                          <input formControlName="vehicleType" matInput placeholder="Vehicle Type" [(ngModel)]="vehicleType" >
                          <mat-error>This field is mandatory.</mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div  class="col-6">
                        <mat-form-field>
                          <input formControlName="totalPassengers"  type="number" maxlength="3" matInput placeholder="Passenger Capacity"  (keyup)="createSeats($event)" (change)="createSeats($event)" [(ngModel)]="totalPassengers">
                          <mat-error>This field is mandatory.</mat-error>
                        </mat-form-field>
                      </div>
                      <div  class="col-6">
                        <mat-form-field>
                          <input formControlName="numberOfFrontSeats"  type="number" maxlength="3" matInput placeholder="No of Front Seat(s)" (keyup)="createSeats($event)" (change)="createSeats($event)" [(ngModel)]="numberOfFrontSeats">
                          <mat-error>This field is mandatory.</mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                      <mat-form-field>
                        <input formControlName="noBeforeEmpty"  type="text"  matInput placeholder="Seat Before Empty(e.g 3, 7)" (keyup)="createSeats($event)" (change)="createSeats($event)" [(ngModel)]="noBeforeEmpty">
                        <mat-error>This field is mandatory.</mat-error>
                      </mat-form-field>


                  </div>
                </div>

                <div>
                  <div class="controles-container">
                      <div  class="row">
                        <div class="col-6">
                          <mat-form-field>
                            <input formControlName="seatsPearRow"  type="number" maxlength="3" matInput placeholder="Seats Per Row" (keyup)="createSeats($event)" (change)="createSeats($event)" [(ngModel)]="seatsPearRow">
                            <mat-error>This field is mandatory.</mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                          <mat-form-field>
                            <input formControlName="columnBeforeIsle"  type="number" maxlength="3" matInput placeholder="Seat No Before Aisle" (keyup)="createSeats($event)" (change)="createSeats($event)" [(ngModel)]="columnBeforeIsle">
                            <mat-error>This field is mandatory.</mat-error>
                          </mat-form-field>
                        </div>
                      </div>


                      <mat-form-field>
                        <input formControlName="unusedSeat"  type="number" maxlength="3" matInput placeholder="Unused Seat No"  (keyup)="createSeats($event)" (change)="createSeats($event)" [(ngModel)]="unusedSeat">
                        <mat-error>This field is mandatory.</mat-error>
                      </mat-form-field>


                      <mat-form-field>
                        <input formControlName="importantNotes"  type="text"  matInput placeholder="Important Notes" [(ngModel)]="importantNotes">
                        <mat-error>This field is mandatory.</mat-error>
                      </mat-form-field>

                      <div class="mt-4 mb-2">
                        <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="chooseImage($event)" />
                        <button mat-button  (click)="csvInput.click()" class="btn-transition2" >Upload Image</button>
                      </div>

                  </div>
                </div>
              </div>

        </form>

    </mat-dialog-content>
  </div>



  <div class="col-6">
    <mat-dialog-content >
    <div class="div-seating" >
      <div class="row" >
        <div [class]="cardCol(seatColSize)" *ngFor="let seat of seatsLayout; let i=index" [ngStyle]="{'border-right': seat.isleAfter ? 'border: 1px solid #0000;' : '0' }"  >
          <div *ngIf="i===0">
              <img src="../../../assets/svgs/driver.png"  height="50px"   />
          </div>
          <div *ngIf="seat.seatNumber!='emptySpace'">
            <img src="../../../assets/svgs/car-seat-with-seatbelt_2.svg"  height="50px"  *ngIf="seat.status=='Available' || !seat.status" (click)="chageSeatNum(i)" />
            <img src="../../../assets/svgs/car-seat-with-seatbelt_1.svg"  height="50px"  *ngIf="seat.status=='Booked'" (click)="chageSeatNum(i)" />
            <img src="../../../assets/svgs/car-seat-with-seatbelt_4.svg"  height="50px"  *ngIf="seat.status=='Selected'" (click)="chageSeatNum(i)" />
            <img src="../../../assets/svgs/car-seat-with-seatbelt.svg"  height="50px" *ngIf="seat.status=='Unavailable'"  (click)="chageSeatNum(i)" />
            <p class="seatNum">{{seat.seatNumber}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" >
      <div class="col-3 text-center" >
        <img src="../../../assets/svgs/car-seat-with-seatbelt_1.svg"  height="50px"  />
        <p class="seatNum">Booked</p>
      </div>
      <div class="col-3 text-center">
        <img src="../../../assets/svgs/car-seat-with-seatbelt_2.svg"  height="50px"  />
        <p class="seatNum">Available</p>
      </div>
      <div class="col-3 text-center">
        <img src="../../../assets/svgs/car-seat-with-seatbelt_4.svg"  height="50px"  />
        <p class="seatNum">Selected</p>
      </div>
      <div class="col-3 text-center">
        <img src="../../../assets/svgs/car-seat-with-seatbelt.svg"  height="50px"  />
        <p class="seatNum">Unavailable</p>
      </div>
    </div>
  </mat-dialog-content>
  </div>
</div>

<div class="hor-seperator"></div>
<div class="row p-flex-jc-sb" >
<div col-3></div>
<div class="button-row col-3" >
    <button mat-raised-button  (click)="onClose()" class="btn-transition" >Cancel</button>
</div>
<div class="button-row col-3">
    <button mat-raised-button  (click)="addVehicleType()" class="btn-transition2" [disabled]="!(managerPermission?.addFleet && vehicleModel &&vehicleType&&totalPassengers)" *ngIf="!isUpdate">Add Vehicle</button>
    <button mat-raised-button  (click)="updateVehicleType()" class="btn-transition2" [disabled]="!(managerPermission?.addFleet && vehicleModel &&vehicleType&&totalPassengers)" *ngIf="isUpdate">Update Vehicle</button>

</div>
<div col-3></div>
</div>
<mat-spinner *ngIf="loading" class="text-center"></mat-spinner>

