import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvoiceComponent } from './common/invoice/invoice.component';
import { PrintLayoutComponent } from './common/print-layout/print-layout.component';
import { AccountingComponent } from './pages/accounting/accounting.component';
import { ConfigurationsComponent } from './pages/configurations/configurations.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FleetsComponent } from './pages/fleets/fleets.component';
import { LoginComponent } from './pages/login/login.component';
import { OperationsComponent } from './pages/operations/operations.component';
import { TripmanagementComponent } from './pages/tripmanagement/tripmanagement.component';
import { UsersComponent } from './pages/users/users.component';
import { AuthguardGuard } from './services/authguard.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthguardGuard]},
  { path: 'tripmanagement', component: TripmanagementComponent, canActivate: [AuthguardGuard]},
  { path: 'operations', component: OperationsComponent, canActivate: [AuthguardGuard]},
  { path: 'accounting', component: AccountingComponent, canActivate: [AuthguardGuard]},
  { path: 'fleet', component: FleetsComponent, canActivate: [AuthguardGuard]},
  { path: 'users', component: UsersComponent, canActivate: [AuthguardGuard]},
  { path: 'configurations', component: ConfigurationsComponent, canActivate: [AuthguardGuard]},
  { path: 'print',
  outlet: 'print',
  component: PrintLayoutComponent,
  children: [
    { path: 'invoice/:invoiceIds', component: InvoiceComponent }
  ]
},



  { path: 'login', component: LoginComponent},
  
  { path: "**", redirectTo: "/" }

//   { path: 'home', component: HomeComponent,canActivate: [AuthguardGuard]
// },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
