<mat-toolbar class="top-bar">
  <span class="title-text">{{tripDetails?.tripTitle}} [{{tripDetails?.originStationAddress}}] </span>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>

<div class="row mt-3" [class.isPrinting]="printService.isPrinting">
  <div class="col">
    <h4 class="table-main-text" ><strong> Vehicle Type </strong></h4>
    <h4 class="table-text"> {{tripDetails?.vehicleType?.vehicleType}}  </h4>
  </div>
  <div class="col">
    <h4 class="table-main-text"> <strong>Stops </strong></h4>
    <p class="table-text"> {{tripDetails?.stops}}</p>

  </div>
  <div class="col">
    <h4 class="table-main-text"> <strong>N {{tripDetails?.tripCost}}</strong></h4>
    <p class="table-text"> {{tripDetails?.paidPassengers.length}} Paid</p>
  </div>
  <div class="col">
    <h4 class="table-main-text"> <strong>Available Seats</strong>  </h4>
    <h4 class="table-text"> {{tripDetails?.totalPassengers - tripDetails?.paidPassengers.length}} </h4>
  </div>
  <div class="col">
    <h4 class="table-main-text"> <strong>Departure</strong>  </h4>
    <h4 class="table-text"> {{tripDetails?.tripDate | date:'EEE, MMM d, h:mm a'}} </h4>
  </div>
</div>
<div class="hor-seperator"></div>
<div class="row">
  <div class="col-8">
<mat-dialog-content *ngIf="addMore && booking">
  <form [formGroup]="form" class="normal-form" >
      <mat-grid-list cols="2" rowHeight="300px">
        <mat-grid-tile>
          <div class="controles-container">
            <input type="hidden" formControlName="$key">
            <mat-form-field>
              <input formControlName="passengerName" matInput placeholder="Passenger Name" >
              <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="passengerEmail" matInput placeholder="Passenger Email" >
              <mat-error>Invalid email address.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="passengerPhone" matInput placeholder="Passenger Phone" >
              <mat-error *ngIf="form.controls['passengerPhone'].errors?.required">This field is mandatory.</mat-error>
              <mat-error *ngIf="form.controls['passengerPhone'].errors?.minlength">Minimum 11 charactors needed.</mat-error>
              <mat-error *ngIf="form.controls['passengerPhone'].errors?.maxlength">Maximum length exceeded.</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="passengerAddress" matInput placeholder="Passenger Address" >
            </mat-form-field>
            <mat-form-field>
              <input formControlName="seatNumber" matInput placeholder="Passenger Seat" [(ngModel)]="seatNumber" readonly>
              <mat-error>Kindly select a seat.</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="controles-container">
              <mat-form-field >
                  <mat-label>Passenger Gender</mat-label>
                  <mat-select  formControlName="passengerGender">
                    <mat-option *ngFor="let gender of genderList ; let i = index" [(value)]="genderList[i]" >
                      {{gender}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              <mat-form-field>
                  <input formControlName="nokName" matInput placeholder="Next of Kin" >
                  <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
              <mat-form-field>
                  <input formControlName="nokPhone" matInput placeholder="Next of Kin Phone" type="tel">
                  <mat-error *ngIf="form.controls['nokPhone'].errors?.required">This field is mandatory.</mat-error>
                  <mat-error *ngIf="form.controls['nokPhone'].errors?.minlength">Minimum 11 charactors needed.</mat-error>
                  <mat-error *ngIf="form.controls['nokPhone'].errors?.maxlength">Maximum length exceeded.</mat-error>
              </mat-form-field>
              <mat-form-field>
                  <input formControlName="nokAddress" matInput placeholder="Next of Kin Address" >
                </mat-form-field>
                <p class="empty">.</p>
                <p class="empty">.</p>

          </div>
        </mat-grid-tile>

      </mat-grid-list>
    </form>
  </mat-dialog-content>
  </div>
  <div class="col-4">
    <mat-dialog-content *ngIf="addMore && booking">
      <div class="div-seating" >
      <div class="row" >
        <div [class]="cardCol(tripDetails?.vehicleType?.seatColSize)" *ngFor="let seat of tripDetails?.vehicleType?.seatsLayout; let i=index" [ngStyle]="{'border-right': seat.isleAfter ? 'border: 1px solid #0000;' : '0' }"  >
            <div *ngIf="i===0">
                <img src="../../../assets/svgs/driver.png"  height="50px"   />
            </div>
          <div *ngIf="seat.seatNumber!='emptySpace'">
              <img src="../../../assets/svgs/car-seat-with-seatbelt_2.svg"  height="50px"  *ngIf="seat.status=='Available' || !seat.status" (click)="selectSeat(i)" />
              <img src="../../../assets/svgs/car-seat-with-seatbelt_1.svg"  height="50px"  *ngIf="seat.status=='Booked'" (click)="bookedSeat()" />
              <img src="../../../assets/svgs/car-seat-with-seatbelt_4.svg"  height="50px"  *ngIf="seat.status=='Selected'" (click)="selectedSeat()" />
              <img src="../../../assets/svgs/car-seat-with-seatbelt.svg"  height="50px" *ngIf="seat.status=='Unavailable'"  (click)="unAvailableSeat()" />
              <p class="seatNum">{{seat.seatNumber}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" >
      <div class="col-3 text-center" >
          <img src="../../../assets/svgs/car-seat-with-seatbelt_1.svg"  height="30px"  />
          <p class="seatNum">Booked</p>
      </div>
      <div class="col-3 text-center">
          <img src="../../../assets/svgs/car-seat-with-seatbelt_2.svg"  height="30px"  />
          <p class="seatNum">Available</p>
      </div>
      <div class="col-3 text-center">
          <img src="../../../assets/svgs/car-seat-with-seatbelt_4.svg"  height="30px"  />
          <p class="seatNum">Selected</p>
      </div>
      <div class="col-3 text-center">
          <img src="../../../assets/svgs/car-seat-with-seatbelt.svg"  height="30px"  />
          <p class="seatNum">NA</p>
      </div>
  </div>
    </mat-dialog-content>
  </div>
</div>

<mat-grid-list cols="2" rowHeight="50px" *ngIf="addMore && booking">

  <mat-grid-tile>
    <div class="controles-container">
      <div class="button-row p-flex-jc-sb">
          <button mat-raised-button class="btn-submit-add" (click)="addMorePassenger()" [disabled]="form.invalid">Add More Passengers</button>

         <button mat-raised-button color="success" (click)="noMoreAdding()" [disabled]="!bookings.length">Cancel</button>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
      <div class=" button-row p-flex-jc-sb">
          <button mat-raised-button color="warn" (click)="checkOutBooking()" [disabled]="form.invalid">Checkout</button>
      </div>
    </mat-grid-tile>
</mat-grid-list>


<div class="div-checkout mt-1" *ngIf="checkOut && bookings.length">
<div *ngFor="let item of bookings; let i=index;"  >
  <div class="row">
    <div class="col-9 text-center" >
      <h2 class="destination-text ml-1">{{ item.passengerName}} </h2>
      <p class="passenger-phone ml-1"> {{item.passengerPhone}}  | {{item.seatNumber}} </p>
    </div>
    <!-- class="ion-align-self-center ion-align-items-center" -->
    <div class="col-3 text-center"  >
      <!-- <button> -->
          <i class="fas fa-1.5x fa-user-edit icon-margin" slot="icon-only" (click)="editBookingItem(item,i)"></i>
          <i class="fas fa-1.5x fa-trash-alt icon-margin" slot="icon-only" (click)="deleteBookingItem(item,i)"></i>
      <!-- </button> -->
  </div>
  </div>
</div>
<div class="hor-seperator"></div>
<div class="row">
<div class="col">
  <h4 class="table-text"> Kindly note that you might be charged extra fee for excess luggage at the park </h4>
</div>
</div>
<div class="hor-seperator"></div>

<div class="row">
<div class="col-9">
  <h2 class="destination-text">Total trip fare: </h2>
</div>
<div class="col-3">
  <h2 class="destination-text"> {{tripDetails.tripCost * bookings.length | currency:'N'}} </h2>
</div>
</div>

<div class="hor-seperator"></div>
<div class="row p-flex-jc-sb"  *ngIf="!loadSpinner">
<div class="button-row col-3" >
<button mat-raised-button  (click)="addMorePassenger2()" class="btn-submit" > <mat-icon>add</mat-icon>Add Passenger</button>

</div>
<div class="button-row col-3" >
    <button mat-raised-button  (click)="payPOS()" color="warn" [disabled]="!(!isUpdate&&managerPermission.cashBooking)">POS Payment</button>
</div>
<div class="button-row col-3">
    <button mat-raised-button  (click)="payCash()" color="primary" [disabled]="!(!isUpdate&&managerPermission.cashBooking)">Cash Collection</button>
</div>
<div class="button-row col-3">
  <button mat-raised-button  (click)="payViaPromise()" class="btn-submit" [disabled]="!(!isUpdate)">Pay Online</button>
</div>
</div>
<div class="row p-flex-jc-sb">
<div class="button-row col-4" ></div>
<div class="button-row col-4" >
  <mat-spinner *ngIf="loadSpinner" class="text-center"></mat-spinner>
</div>
<div class="button-row col-4" ></div>

</div>
</div>


