
<div class="container-fluid headerfixed">
  <app-header showHome=true></app-header>
</div>
<div class="container-fluid contentone">
  <div class="row sidebarrow">
      <app-side showHome=true></app-side>
  </div>
  <!-- <div class="row withoutsidebarrow">
      <ion-row class="mainrow model2">
        <ion-col size="6" size-md="3" *ngFor="let item of miniCardData" >
          <div class="boxes" [ngStyle]="cardStyle(item.color)" >
            <ion-row  class="iconic" >
            <ion-col size="12" >
              <p class="num">
                <span>{{item.value | number}}</span>
              </p>
              <p class="store">{{item.title}}</p>
            </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
  </div> -->

</div>

<div class="container-fluid body-card">
  <p class="range-date">{{dataService.startTime | date}} - {{dataService.endTime | date}} </p>
<div class="row">
  <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 mt-2" *ngFor="let item of miniCardData">
    <div class="card card-counter bg-gradient-secondary card-bg" [ngStyle]="cardStyle(item.color)">
      <div class="card-body"  >
        <h3 class="card-title text-white" >{{item.title}}</h3>
        <div class="row">
          <div class="col-3">
            <mat-icon class="mt-3 mb-0 text-white icon">{{item.icon}}</mat-icon>
          </div>
          <div class="col-9 text-center" *ngIf="!spinner">
              <div class="mt-4 mb-0 text-white">
                <h2 class="mb-0">{{item.value | number}}</h2>
                <p class="text-white mt-1" >Aggregate Value</p>
              </div>
              <div class="mt-4 mb-0 text-white" *ngIf="today">
                <h2 class="mb-0">{{item.today|number}}</h2>
                <p class="text-white mt-1" >Today's Value</p>
              </div>
            </div>
            <div *ngIf="spinner"  class="col-9 text-center loader  center" ></div>

          </div>
        </div>

      </div>
    </div>
</div>

<div class="row mt-4">
  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
    <div class="card card-counter bg-gradient-secondary" style="height: 100%">
      <div class="card-body">
        <h3 class="card-title" >Booking Chart</h3>
        <app-annual-booking-chart></app-annual-booking-chart>
      </div>
    </div>
  </div>

  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4">
    <div class="card card-counter bg-gradient-secondary " style="height: 100%">
      <div class="card-body" >
        <div class="row">
          <div class="col-10 pl-0">
            <h3 class="card-title" >Booking Table</h3>
          </div>
          <div class="col-2 text-justify">
            <button mat-menu-item (click)="bookingRevenueDownload()" ><mat-icon>download</mat-icon></button>
          </div>
        </div>

        <app-annual-booking-table></app-annual-booking-table>
      </div>
    </div>
  </div>
</div>


<div class="row row-eq-height mt-4 mb-5">
  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mb-4">
    <div class="card card-counter bg-gradient-secondary" style="height: 100%">
      <div class="card-body">
        <h3 class="card-title" >Trip Routes</h3>
        <mat-tab-group mat-align-tabs="end">
          <mat-tab label="Graph">
            <app-trip-route-chart></app-trip-route-chart>
          </mat-tab>
          <mat-tab label="Table">
            <app-trip-route-table></app-trip-route-table>
          </mat-tab>

        </mat-tab-group>

      </div>
    </div>
  </div>

  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mb-4">
    <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
      <div class="card-body">
        <h3 class="card-title" >Origin Parks</h3>
        <mat-tab-group mat-align-tabs="end">
          <mat-tab label="Graph">
            <app-origin-park-chart></app-origin-park-chart>
          </mat-tab>
          <mat-tab label="Table">
            <app-trip-origin-table></app-trip-origin-table>

          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mb-4">
    <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
      <div class="card-body">
        <h3 class="card-title" >Parks Performance</h3>
        <app-origin-performance-chart></app-origin-performance-chart>
      </div>
    </div>
  </div>

  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4 mb-4">
    <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
      <div class="card-body">
        <h3 class="card-title" >Route Performance</h3>
        <app-route-performance-chart></app-route-performance-chart>
      </div>
    </div>
  </div>
</div>
</div>

