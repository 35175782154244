import { Component, OnInit,ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { ViewVehicleTypeComponent } from 'src/app/modals/view-vehicle-type/view-vehicle-type.component';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-vehicle-type-table',
  templateUrl: './vehicle-type-table.component.html',
  styleUrls: ['./vehicle-type-table.component.scss']
})
export class VehicleTypeTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 5;

  displayedColumns = [
    'vehicleType',
    'vehicleModel',
    'passengerCapacity',
    'editBtn',
    ];

  vehicleTypes;

  constructor(private dataService: DataServicesService,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataService.vehicleType.subscribe(vehicleType => {
      if (vehicleType){
        // console.log('Vehicle Type',vehicleType)
        this.tableData  = vehicleType;
        this.dataSource = new MatTableDataSource(vehicleType);
        this.dataLength = vehicleType.length;
        this.dataSource.paginator = this.paginator;

      }
    });
  }

  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }

  editVehicleType(vehicleType?) {
    console.log('Open dialog')
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dataService.openedVehicleType = vehicleType;
    this.dialog.open(ViewVehicleTypeComponent, dialogConfig);

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openAddVehicleType() {
    console.log('Open dialog')
    this.dataService.openedVehicleType = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(ViewVehicleTypeComponent, dialogConfig);

  }

}
