import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataServicesService } from 'src/app/services/data-services.service';
import {MatTableDataSource} from '@angular/material/table';
import * as firebase from 'firebase/compat/app';

import { MatDialog, MatDialogConfig} from '@angular/material/dialog';


import Swal from 'sweetalert2'


import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from 'src/app/services/notification.service';
import { ManagerComponent } from 'src/app/modals/manager/manager.component';
import { AddManagerComponent } from 'src/app/modals/add-manager/add-manager.component';

@Component({
  selector: 'app-station-managers',
  templateUrl: './station-managers.component.html',
  styleUrls: ['./station-managers.component.scss']
})
export class StationManagersComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData;
  pageSize = 5;


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'userName',
    'userEmail',
    'userTerminal',
    'userStatus',
    'viewBtn',
    ];

  stationManagers;
  constructor(private dataService: DataServicesService, private notificationService: NotificationService,private dialog: MatDialog){}

  ngOnInit() {
    this.dataService.stationManagersData.subscribe(stationManagers => {
      if(stationManagers){
        // console.log(stationManagers)
        this.stationManagers = stationManagers;
        this.tableData  = this.stationManagers;
        this.dataSource = new MatTableDataSource(this.stationManagers);
        this.dataLength = this.stationManagers.length;
        this.dataSource.paginator = this.paginator;

      }
    })
  }

  ngAfterViewInit() {
    if (this.tableData){
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  viewManager(manager){
    console.log('Open dialog')
    // this.currentTrip = trip;
    this.dataService.selectedManager = manager;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(ManagerComponent, dialogConfig);

  }

  openAddManager(){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(AddManagerComponent, dialogConfig);

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
