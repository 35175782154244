import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { DataServicesService } from 'src/app/services/data-services.service';
import { PrintService } from 'src/app/services/print.service';



@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  invoiceIds: string[];
  invoiceDetails: Promise<any>[];
  booking;

  constructor(route: ActivatedRoute,
              private printService: PrintService, private dataService: DataServicesService) {
    this.invoiceIds = route.snapshot.params['invoiceIds']
      .split(',');
  }

  ngOnInit() {
    this.invoiceDetails = this.invoiceIds
      .map(id => this.getInvoiceDetails(id));
    Promise.all(this.invoiceDetails)
      .then(() => this.printService.onDataReady());
  }

  getInvoiceDetails(bookingId) {
    // const amount = Math.floor((Math.random() * 100));
    if(this.dataService.bookingsData){

      const booking = this.dataService.bookingsData.find( booking => {
        return booking.bookingId === bookingId;
      });
      // console.log(booking);
      this.booking = booking;
      return new Promise(resolve =>
        setTimeout(() => resolve({booking}), 1000)
      );
    }

  }
}
