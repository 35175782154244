<div class="mb-4 graph">
  <div class="row">
    <div class="col">
      <button mat-button mat-raised-button  (click)="revenueChart()" *ngIf="type==='amount'" class="statusbtn"> Show Revenue</button>
       <button mat-button mat-raised-button  (click)="countChart()" *ngIf="type==='count'" class="statusbtn">Show Booking</button>
    </div>
    <div class="col text-right">
      <button mat-button mat-raised-button  (click)="toBar()" *ngIf="lineChartType==='line'" class="statusbtn"> To Bar Chart</button>
       <button mat-button mat-raised-button  (click)="toLine()" *ngIf="lineChartType==='bar'" class="statusbtn">To Line Graph</button>
    </div>
      <div class="col">
        <!-- <mat-form-field class="before">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate matInput placeholder="Start date" (dateChange)="startDateChange($event)">
            <input matEndDate matInput placeholder="End date" (dateChange)="endDateChange($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field> -->
      </div>
  </div>
  <div >
    <div style="display: block">
      <canvas baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins"
        >
      </canvas>
    </div>
  </div>
