import { Component, OnInit,ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-trip-origin-table',
  templateUrl: './trip-origin-table.component.html',
  styleUrls: ['./trip-origin-table.component.scss']
})
export class TripOriginTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;


  displayedColumns = ['trip_date','trip_origin', 'trip_count', 'trip_amount'];

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 10;
  tripOrigin;

  constructor(private dataService: DataServicesService) { }

  ngOnInit(): void {

    this.dataService.tripOrigin.subscribe(tripOrigin => {
      if (tripOrigin){
        this.tripOrigin  = tripOrigin;
        this.dataSource = new MatTableDataSource(this.tripOrigin);
        this.dataLength = this.tripOrigin.length;
        this.dataSource.paginator = this.paginator;
        // console.log(tripOrigin)
      }

    });

  }

  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
