import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Platform } from '@ionic/angular';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email;
  password;
  public errorMessage;
  passwordType = 'password';
  passwordshown = false;
  resetPass = false;

  constructor(public authServe: AuthServicesService, private router: Router,private platform: Platform, private dataService: DataServicesService) {
    this.authServe.logout().then(res =>{
      this.authServe.userData.next(null);
    })
  }

  ngOnInit(): void {

  }

  googleLogin(){
    this.authServe.googleLogin()
  }

  resetPassword(){
    if(this.email){
      this.authServe.forgotPassoword(this.email).then(res =>{
        this.errorMessage  = 'Reset Instruction  sent to your email';
        this.resetPass = false;
      }, err => {
        this.errorMessage = err.message.replace('Firebase:','');
        console.log(err);
    })
    } else {
      this.errorMessage  = 'Kindly enter your email'
      this.resetPass = true;
    }
  }

  resetPassword1(){
    this.resetPass = true;
  }

  login(){
    if(this.email && this.password){
      // console.log(this.email);
      // try {
        this.authServe.login(this.email,this.password)
        .then(res =>{
          // console.log(res);
          this.loginOrSignup();
        } , (err) => {
          this.errorMessage = err.message.replace('Firebase:','').replace('(auth/user-not-found)','');

      })
      // .catch(err =>{
        // this.errorMessage = err.message.replace('Firebase:','').replace('(auth/user-not-found)','');
        // console.log('error')
      //  });
      // } catch(e){
        // console.log('error 2',e);
      // }
      this.errorMessage = this.authServe.loginError;
    }

  }

  public togglePassword() {
    if (this.passwordshown) {
      this.passwordshown = false;
      this.passwordType = 'password';
    } else {
      this.passwordshown = true;
      this.passwordType = 'text';
    }

    }

  appleLogin(){}

  facebookLogin(){}

  loginOrSignup() {
    this.authServe.userData.subscribe( userData => {
      if(userData){
        // console.log(userData);
        const stationId = 'stationId';
        const Id = userData[stationId];
        this.dataService.getClosedTrips(Id);
        this.dataService.getFleet();
        this.dataService.getVehicleType();
        const managerPermission = userData['permissions'];
        const managementDashboardPermission = managerPermission.managementDashboard
        const superAdmin = managerPermission.superAdmin

        if(managementDashboardPermission ||superAdmin ){
          this.router.navigate(['dashboard']);
        } else {
          this.router.navigate(['tripmanagement']);
        }

      }
    });
  }

}
