import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';

import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';



import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HeaderComponent } from './common/header/header.component';
import { SideComponent } from './common/side/side.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { TripmanagementComponent } from './pages/tripmanagement/tripmanagement.component';
import { AnnualBookingChartComponent } from './charts/annual-booking-chart/annual-booking-chart.component';
import { ChartsModule } from 'ng2-charts';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';


import { FlutterwaveModule } from 'flutterwave-angular-v3';


import { MatGridListModule } from '@angular/material/grid-list';

import { MatNativeDateModule } from '@angular/material/core';
import { AnnualBookingTableComponent } from './table/annual-booking-table/annual-booking-table.component';
import { TripRouteChartComponent } from './charts/trip-route-chart/trip-route-chart.component';
import { OriginParkChartComponent } from './charts/origin-park-chart/origin-park-chart.component';
import { OriginPerformanceChartComponent } from './charts/origin-performance-chart/origin-performance-chart.component';
import { RoutePerformanceChartComponent } from './charts/route-performance-chart/route-performance-chart.component';
import { TripRouteTableComponent } from './table/trip-route-table/trip-route-table.component';
import { TripOriginTableComponent } from './table/trip-origin-table/trip-origin-table.component';
import { OperationsComponent } from './pages/operations/operations.component';
import { AccountingComponent } from './pages/accounting/accounting.component';
import { FleetsComponent } from './pages/fleets/fleets.component';
import { UsersComponent } from './pages/users/users.component';
import { ConfigurationsComponent } from './pages/configurations/configurations.component';
import { FleetTableComponent } from './table/fleet-table/fleet-table.component';
import { VehiclesAnalyticsChartComponent } from './charts/vehicles-analytics-chart/vehicles-analytics-chart.component';
import { VehicleTypeTableComponent } from './table/vehicle-type-table/vehicle-type-table.component';
import { FleetTripsComponent } from './table/fleet-trips/fleet-trips.component';
import { ViewVehicleTypeComponent } from './modals/view-vehicle-type/view-vehicle-type.component';
import { ViewVehicleComponent } from './modals/view-vehicle/view-vehicle.component';
import { CurrentTripsComponent } from './table/current-trips/current-trips.component';
import { BookTripComponent } from './modals/book-trip/book-trip.component';
import { TripPassengersComponent } from './table/trip-passengers/trip-passengers.component';
import { BookingsTableComponent } from './table/bookings-table/bookings-table.component';
import { PrintLayoutComponent } from './common/print-layout/print-layout.component';
import { InvoiceComponent } from './common/invoice/invoice.component';
import { ClosedTripsComponent } from './table/closed-trips/closed-trips.component';
import { OnlinePaymentsComponent } from './table/online-payments/online-payments.component';
import { OfficePaymentsComponent } from './table/office-payments/office-payments.component';
import { OnlineBookingsComponent } from './modals/online-bookings/online-bookings.component';
import { TerminalBookingsComponent } from './modals/terminal-bookings/terminal-bookings.component';
import { ManagerBookingComponent } from './modals/manager-booking/manager-booking.component';
import { StationDataComponent } from './table/station-data/station-data.component';
import { TerminalTableComponent } from './table/terminal-table/terminal-table.component';
import { AddTerminalComponent } from './modals/add-terminal/add-terminal.component';
import { ManagerComponent } from './modals/manager/manager.component';
import { StationManagersComponent } from './table/station-managers/station-managers.component';
import { AddManagerComponent } from './modals/add-manager/add-manager.component';
import { RouteTableComponent } from './table/route-table/route-table.component';
import { AddRouteComponent } from './modals/add-route/add-route.component';
import { AddTripComponent } from './modals/add-trip/add-trip.component';
import { AgencyPaymentsComponent } from './table/agency-payments/agency-payments.component';
import { DriverTableComponent } from './table/driver-table/driver-table.component';
import { ExpenseTableComponent } from './table/expense-table/expense-table.component';
import { ExpenseSummaryTableComponent } from './table/expense-summary-table/expense-summary-table.component';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    SideComponent,
    LoginComponent,
    TripmanagementComponent,
    AnnualBookingChartComponent,
    AnnualBookingTableComponent,
    TripRouteChartComponent,
    OriginParkChartComponent,
    OriginPerformanceChartComponent,
    RoutePerformanceChartComponent,
    TripRouteTableComponent,
    TripOriginTableComponent,
    OperationsComponent,
    AccountingComponent,
    FleetsComponent,
    UsersComponent,
    ConfigurationsComponent,
    FleetTableComponent,
    VehiclesAnalyticsChartComponent,
    VehicleTypeTableComponent,
    FleetTripsComponent,
    ViewVehicleTypeComponent,
    ViewVehicleComponent,
    CurrentTripsComponent,
    BookTripComponent,
    TripPassengersComponent,
    BookingsTableComponent,
    PrintLayoutComponent,
    InvoiceComponent,
    ClosedTripsComponent,
    OnlinePaymentsComponent,
    OfficePaymentsComponent,
    AgencyPaymentsComponent,
    OnlineBookingsComponent,
    TerminalBookingsComponent,
    ManagerBookingComponent,
    StationDataComponent,
    TerminalTableComponent,
    AddTerminalComponent,
    ManagerComponent,
    StationManagersComponent,
    AddManagerComponent,
    RouteTableComponent,
    AddRouteComponent,
    AddTripComponent,
    DriverTableComponent,
    ExpenseTableComponent,
    ExpenseSummaryTableComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.config),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ChartsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSortModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatGridListModule,
    FlutterwaveModule,
    MatButtonModule
  ],
  providers: [ViewVehicleTypeComponent,ViewVehicleComponent,BookTripComponent,OnlineBookingsComponent,TerminalBookingsComponent,
    ManagerBookingComponent,AddTerminalComponent,ManagerComponent,AddManagerComponent,AddRouteComponent],
  bootstrap: [AppComponent],
  entryComponents:[ ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
