import { Component, OnInit,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartType,ChartOptions } from 'chart.js';
import { SingleDataSet, Label, Color } from 'ng2-charts';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-route-performance-chart',
  templateUrl: './route-performance-chart.component.html',
  styleUrls: ['./route-performance-chart.component.scss']
})
export class RoutePerformanceChartComponent implements OnInit,OnDestroy {

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  doughnutChartLabels: Label[] = [];
  doughnutChartData: SingleDataSet = [

  ];
  doughnutChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors: Color[] = [
    {
      borderColor: ['#ed681d', '#F8CA0F', '#a32727' , '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'],
      backgroundColor: ['#ed681d', '#F8CA0F', '#a32727' , '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'],
      borderWidth: 3
    }
  ];

  reportStartTime = 0;//Date.now() - (24*60*60*1000);
  reportEndTime = Date.now() + 2*(24 * 60 * 60 * 1000);

  routePerformance;
  type = 'amount';

  private bookingSubscription$: Subscription;
  constructor(private dataService: DataServicesService) { }

  ngOnInit(): void {
    this.bookingSubscription$ = this.dataService.routePerformance.subscribe(routePerformance => {
      if (routePerformance){
        this.routePerformance  = routePerformance;
        this.getRevenueGraph();
        // console.log(tripRoutes)
      }

    });
  }
  public ngOnDestroy(): void {
    if(this.bookingSubscription$){
      this.bookingSubscription$.unsubscribe();
    }
  }

  getRevenueGraph(){
    this.type = 'amount';

    // console.log(this.routePerformance)
    this.doughnutChartLabels = [];
    this.doughnutChartData=[]
    this.routePerformance.forEach(element => {
      if(element){
        this.doughnutChartLabels.push(element.trip_route);
        this.doughnutChartData.push(element.trip_amount)
      }


    });

  }

  getCountGraph(){
    this.type = 'count';

    this.doughnutChartLabels = [];
    this.doughnutChartData=[]
    this.routePerformance.forEach(element => {
      if(element){
        this.doughnutChartLabels.push(element.trip_route);
        this.doughnutChartData.push(element.trip_count)
      }


    });
  }




  startDateChange($event){
    if ($event) {
      this.reportStartTime = new Date($event.value).valueOf();
    }

  }

  endDateChange($event){
    if ($event){
      // const lastTime = this.reportEndTime;
      this.reportEndTime = new Date($event.value).valueOf() + 2*(24 * 60 * 60 * 1000);
      this.dataService.getRoutePerformance(this.reportStartTime,this.reportEndTime);

    }

  }

}
