import { Component, OnInit,AfterViewInit ,ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-expense-table',
  templateUrl: './expense-table.component.html',
  styleUrls: ['./expense-table.component.scss']
})
export class ExpenseTableComponent implements OnInit,AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;


  bookingRevenue = [];
  bookingCount = [];

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['entry_date', 'expense_date', 'vehicle','manager','expense_category','description','expense_amount'];

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 10;
  constructor(private dataService: DataServicesService) { }

  ngOnInit(): void {

    // this.dataService.annualBookingRevenueData_.subscribe(rec  =>{
    //   this.bookingRevenue = rec;
    // });
    this.dataService.expenses.subscribe(rec=>{
      // this.bookingCount = rec;
      this.tableData = [];
      rec.forEach((val,index)=>{
        this.tableData.push(val)
      })
      // console.log(this.tableData)
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
      this.sortTable(rec)

    })



    // this.tableData  = this.dailyOrders;
    // this.dataSource = new MatTableDataSource(this.dailyOrders);
    // this.dataLength = this.dailyOrders.length;
    // this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  startDateChange($evt){

  }

  endDateChange($evt){

  }

  sortTable(expenses){
    const expenseSum = {};
    for (const expense in expenses){
      if(expenses[expense]){
        const oneExpense = expenses[expense]
        const oneExpenseDate = new Date(parseInt(oneExpense.extryDate, 10));
        const dateComponents = oneExpenseDate.toDateString().split(' ');
        const expenseMonth = dateComponents[1] + ' ' + dateComponents[3];
        const expenseDay = dateComponents[1] + ' ' + dateComponents[2] + ' ' + dateComponents[3];
        const expenseAmount = 'expenseAmount';
        const amount = oneExpense[expenseAmount];

        if (expenseSum[expenseDay]) {
           expenseSum[expenseDay].amount = expenseSum[expenseDay].amount + parseInt(amount, 10);
           expenseSum[expenseDay].entries.push(oneExpense)
        } else {
          const entryObject = {};
          entryObject['amount'] = amount;
          entryObject['entries'] = [oneExpense]
          expenseSum[expenseDay] = entryObject
        }

      }
    }
    // console.log( Object.entries(expenseSum))
    this.dataService.expenseSummary.next(Object.entries(expenseSum))

  }

}
