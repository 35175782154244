<mat-toolbar class="top-bar">
  <span class="title-text">Create Trip <span *ngIf="dislayRoute">  - {{dislayRoute}}</span></span>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>

<div class="row  mt-2 ml-1">
  <div class="col-6">
    <mat-dialog-content *ngIf="terminals">
      <form [formGroup]="form" class="normal-form">
        <mat-form-field class="example-full-width" *ngIf="terminals.length">
          <mat-label>Origin Station</mat-label>
          <mat-select [(ngModel)]="originStationAddress"  formControlName="originaddr" required class="text-input" (selectionChange)="terminalChange($event)">
            <mat-option *ngFor="let terminal of terminals; let i=index" [value]="terminals[i].stationName + ' - ' + terminals[i].stationAddress" class="text-input">
              {{terminal?.stationName}} - {{terminal?.stationAddress}}
            </mat-option>
          </mat-select>
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Route</mat-label>
          <mat-select   formControlName="route" required (selectionChange)="selectRoute($event)"  class="text-input">
            <mat-option *ngFor="let route of routes; let i=index" [value]="routes[i].originCity + ' - '+routes[i].destinationCity " class="text-input">
                {{routes[i].originCity}}[{{routes[i].originState}}] - {{routes[i].destinationCity}}[{{routes[i].destinationState}}]
            </mat-option>
          </mat-select>
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>

      <mat-form-field class="example-full-width">
          <mat-label>Bus Stops(Comma separated)</mat-label>
          <input matInput  placeholder="Stops" type="text" [(ngModel)]="stops" formControlName="stops" required class="text-input ion-text-capitalize">
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>

      <div class="row">
        <div class="col-3">
          <mat-form-field class="example-full-1">
            <mat-label>Trip date</mat-label>
            <input matInput [matDatepicker]="picker" (dateInput)="tripChange()" (dateChange)="tripChange()" formControlName="date" [(ngModel)]="date" required class="text-input" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker ></mat-datepicker>
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="example-full-1">
            <mat-label>Departure Hour</mat-label>
            <input matInput [(ngModel)]="hour" type="number" maxlength="2" min="0" max="23" (change)="tripChange()" formControlName="hour" required class="text-input">
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="example-full-1">
            <mat-label>Min</mat-label>
            <input matInput [(ngModel)]="minutes" type="number" maxlength="2" min="0" max="59" (change)="tripChange()" formControlName="minutes" required class="text-input">
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="example-full-1">
            <mat-label>Fare(NGN)</mat-label>
            <input  matInput  [(ngModel)]="tripCost" type="number" formControlName="tripCost" (keyup)="updateCommission($event)" required class="text-input">
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="example-full-1">
            <mat-label>Fleet Vehicle</mat-label>
            <mat-select   formControlName="vehicletype" required class="text-input" (selectionChange)="fleetChange($event)">
              <mat-option *ngFor="let type of fleets; let i=index" [value]="fleets[i]" class="text-input">
                {{type.fleetId}} - {{type.regState}} - {{type.regNum}}
              </mat-option>
            </mat-select>
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width">
            <mat-label>Vehicle AC</mat-label>
            <!-- <input matInput [(ngModel)]="trip.vehicleType.vehicleType" type="text"  name="vehicleType" required class="text-input" readonly> -->
            <mat-select [(ngModel)]="features"  formControlName="vehiclefeature" required class="text-input">
              <mat-option *ngFor="let type of vehicleAC; let i=index" [value]="vehicleAC[i]" class="text-input">
                {{type}}
              </mat-option>
            </mat-select>
            <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
        </div>

      </div>
      <div class="row" *ngIf="vehicleType">
        <div class="col">
          <mat-form-field class="example-full-width">
            <mat-label>Vehicle Type</mat-label>
            <input matInput [(ngModel)]="vehicleType.vehicleType" type="text"  formControlName="vehicleType" required class="text-input" readonly>
            <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width">
            <mat-label>Number of Seats</mat-label>
            <input matInput [(ngModel)]="totalPassengers" type="number" maxlength="2" formControlName="totalPassengers" required class="text-input">
            <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="example-full-width">
            <mat-label>Vehicle Registration</mat-label>
            <input matInput  placeholder="Plate number" type="text" [(ngModel)]="vehicleReg" formControlName="vehicleReg" required class="text-input ion-text-uppercase">
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width">
            <mat-label>Driver Name</mat-label>
            <input matInput  placeholder="Mr " type="text" [(ngModel)]="driverName" formControlName="driverName" required class="text-input  ion-text-capitalize">
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-form-field class="example-full-width">
        <mat-label>Message on Luggage</mat-label>
        <input matInput  placeholder="You are allowed max of two bags not more than 12Kg each" type="text" [(ngModel)]="luggageMessage" formControlName="luggageMessage" class="text-input">
        <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  </div>
  <div class="col-6">
    <mat-dialog-content >
      <div class="div-seating" *ngIf="selectedVehicleType">
         <div class="row" >
           <div [class]="cardCol(selectedVehicleType.seatColSize)" *ngFor="let seat of selectedVehicleType.seatsLayout; let i=index" [ngStyle]="{'border-right': seat.isleAfter ? 'border: 1px solid #0000;' : '0' }"  >
               <div *ngIf="i===0">
                   <img src="../../../assets/svgs/driver.png"  height="50px"   />
               </div>
             <div *ngIf="seat.seatNumber!='emptySpace'">
                 <img src="../../../assets/svgs/car-seat-with-seatbelt_2.svg"  height="50px"  *ngIf="seat.status=='Available' || !seat.status" (click)="toggleSeatNum(i)" />
                 <img src="../../../assets/svgs/car-seat-with-seatbelt_1.svg"  height="50px"  *ngIf="seat.status=='Booked'" (click)="toggleSeatNum(i)" />
                 <img src="../../../assets/svgs/car-seat-with-seatbelt_4.svg"  height="50px"  *ngIf="seat.status=='Selected'" (click)="toggleSeatNum(i)" />
                 <img src="../../../assets/svgs/car-seat-with-seatbelt.svg"  height="50px" *ngIf="seat.status=='Unavailable'"  (click)="toggleSeatNum(i)" />
                 <p class="seatNum">{{seat.seatNumber}}</p>
             </div>
         </div>
         </div>
       </div>
       <div class="hor-seperator" *ngIf="selectedVehicleType"></div>
       <div class="row" *ngIf="selectedVehicleType">
           <div class="col-3 text-center" >
               <img src="../../../assets/svgs/car-seat-with-seatbelt_1.svg"  height="50px"  />
               <p class="seatNum">Booked</p>
           </div>
           <div class="col-3 text-center">
               <img src="../../../assets/svgs/car-seat-with-seatbelt_2.svg"  height="50px"  />
               <p class="seatNum">Available</p>
           </div>
           <div class="col-3 text-center">
               <img src="../../../assets/svgs/car-seat-with-seatbelt_4.svg"  height="50px"  />
               <p class="seatNum">Selected</p>
           </div>
           <div class="col-3 text-center">
               <img src="../../../assets/svgs/car-seat-with-seatbelt.svg"  height="50px"  />
               <p class="seatNum">Unavailable</p>
           </div>
       </div>
     </mat-dialog-content>
</div>
</div>

<div class="hor-seperator"></div>
<div class="row p-flex-jc-sb mb-2" *ngIf="!dataSubmitted">
 <div col-3></div>
  <div class="button-row col-3" >
      <button mat-raised-button  (click)="onClose()" color="warn" >Cancel</button>
  </div>
  <div class="button-row col-3">
      <button mat-raised-button  (click)="addTrip()" class="btn-submit" [disabled]="!(managerPermissions?.addTrip && form.valid)" >Create Trip</button>

  </div>
  <div col-3></div>
</div>

