import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import { DataServicesService } from 'src/app/services/data-services.service';
import Swal from 'sweetalert2'

import * as firebase from 'firebase/compat/app';

import { UUID } from 'angular2-uuid';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-add-manager',
  templateUrl: './add-manager.component.html',
  styleUrls: ['./add-manager.component.scss']
})
export class AddManagerComponent implements OnInit {

  selectedManager;
  adminPermission;
  managerPermission ={
    superAdmin:false,
    terminalAgent:false,
    terminalLoadingAgent: false,
    viewTrips: false,
    viewTripDetails: false,
    cashBooking: false,
    printManifest: false,
    addTrip: false,
    addTripRoute: false,
    closeBooking: false,
    viewClosedTrip: false,
    viewFinancials: false,
    addExpense: false,
    viewSettings: false,
    viewManager:false,
    editManager: false,
    editStation: false,
    addFleet: false,
    viewFleet: false,
    editFleet: false,
    addMaintenance: false,
    dashboardUser: false,
    managementDashboard: false




  };
  terminals = [];
  log =[];
  firstName=''
  lastName =''
  email =''
  password=''
  phone=''
  nin=''
  terminal;
  terminalAddress
  constructor(public dialogRef: MatDialogRef<AddManagerComponent>, public dataService: DataServicesService,private authService: AuthServicesService,private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.authService.userData.subscribe(userDetails => {
      if(userDetails) {
        this.adminPermission = userDetails['permissions'];
      }
    })

    this.terminals = this.dataService.terminals;

  }

  onClose() {

    this.dialogRef.close();
  }

  addManager(){

    let userData =  {
      email: this.email,
      username: this.firstName + ' '+ this.lastName,
      provider: 'Email Login',
      usertype: 'stationmanager',
      phone: '+234'+this.phone,
      location: this.authService.userDetails.location,
      transporter: this.authService.userDetails.transporter,
      nin: this.nin,
      // ninURL: stationmanager.ninURL,
      status: 'active',
      permissions: this.managerPermission,
      bookedTrips : 0,
      totalAmountBooked : 0,
      availableCredit : 0,
      accountUpdateDate : Date.now(),
      firstLogin: new Date().toISOString(),
      notificationArray: [],
      terminal: this.terminal,
      terminalAddress: this.terminalAddress,
      log:[]
    }

    let userManager = {
      password: this.password,
      userData
    }

      Swal.fire({
        title: 'Create Manager',
        text: "Are you sure you want to create this user? The user will have all rights based on selected permissions",
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor:  '#4d9301',
        width:'15%',
        confirmButtonText: 'Confirm',
        background:'#d6dde0',
      }).then(async (result) => {
        if (result.isConfirmed) {

          const accessToken = await firebase.default.auth().currentUser.getIdToken();
          this.log.push(
            {
              id: UUID.UUID(),
              action: 'Station Manager create',
              userId: this.authService.userDetails.id,
              userName: this.authService.userDetails.username,
              date: Date.now(),
              details: {
                action: 'create manager - ' + this.firstName + ' '+ this.lastName,
                response: Date.now()
              }
            }
          )
          userManager.userData.log = this.log;

          this.dataService.createManager(userManager, accessToken).subscribe(
            async (res) => {
             console.log(res);
             this.notificationService.success('::'+res['status']+' - '+res['message'] );
             this.dataService.getStationManagers();
             this.onClose()

            });

        }
      })


  }

  changeTermial($evnt){
    this.terminal = $evnt.value.stationName;
    this.terminalAddress = $evnt.value.stationName + ' - ' + $evnt.value.stationAddress;
    // console.log(this.newAgent.terminalAddress);

  }

}
