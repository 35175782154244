
<mat-table #table [dataSource]="dataSource" *ngIf="dataSource" matSort>

  <ng-container matColumnDef="vehicle_reg">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Vehicle </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.vehicle_reg}}
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total_trips">
    <mat-header-cell *matHeaderCellDef mat-sort-header>  Trips </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.total_trips|number}}
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total_passengers">
    <mat-header-cell *matHeaderCellDef mat-sort-header>  Passengers </mat-header-cell>
    <mat-cell *matCellDef="let element" >
      {{element.total_passengers|number}}
       </mat-cell>
  </ng-container>



  <ng-container matColumnDef="total_income">
    <mat-header-cell *matHeaderCellDef mat-sort-header>  Revenue </mat-header-cell>
    <mat-cell *matCellDef="let element" >
     {{element.total_income|number}}
       </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>



<mat-paginator #paginator
[pageSize]="7"
[pageSizeOptions]="[7,10,14,21,28]"
[showFirstLastButtons]="true" >
</mat-paginator>



