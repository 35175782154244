import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {  BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from './../../environments/environment';

import * as firebase from 'firebase/compat/app';

export class AuthInfo {

  constructor(public $uid: string) {}

  isLoggedIn() {
      return !!this.$uid;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthServicesService {

  static UNKNOWN_USER = new AuthInfo(null);
  public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(AuthServicesService.UNKNOWN_USER);
  public userData: BehaviorSubject<{}> = new BehaviorSubject<{}>(null);
  public loginProviderId;
  public userDetails;
  public uid;
  isAdmin = false;
  loginError='';
  constructor(private fireAuth: AngularFireAuth, private router: Router, public fireStore: AngularFirestore) {
    this.fireAuth.authState.pipe(
      take(1)
      ).subscribe(user => {
          if (user) {
              this.authInfo$.next(new AuthInfo(user.uid));
          }
      });
    this.getUserId();
   }

   public logout(): Promise<void> {
    this.authInfo$.next(AuthServicesService.UNKNOWN_USER);
    this.router.navigate(['/login']);
    this.userData.next(null);
    this.userDetails = null;
    return this.fireAuth.signOut();
  }
  public checkAuth() {
      return new Promise((resolve) => {
          this.fireAuth.onAuthStateChanged(user => {
            // console.log('check auth', user);
            if (user) {
              this.userDetails = user;
              resolve(user);
            } else {
              this.router.navigate(['/login']);
            }
           });
      });
  }

  public forgotPassoword(email: string) {
    return this.fireAuth.sendPasswordResetEmail(email)
    // .then(() => {
    //   window.prompt('Password Reset Email Sent')

    // }).catch(err => window.prompt('Error'));

}

  public fbLogin(): Promise<any> {
      return this.fireAuth.signInWithPopup(new firebase.default.auth.FacebookAuthProvider());
  }


  public googleLogin(): Promise<any> {
      return this.fireAuth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());
  }

  public login(email: string, password: string) //: Promise<any> {
    // return new Promise<any>((resolve, reject) => {
      {
       return  this.fireAuth.signInWithEmailAndPassword(email, password)
        .then(res => {
            if (res.user) {
              return res.user
                // resolve(res.user);
            }
        })
        // .catch(err => {
            // console.log('error')
            // this.authInfo$.next(AuthServicesService.UNKNOWN_USER);
            // reject(`login failed ${err}`);
        // });


    // });
}


  public getUserData() {
    // console.log(environment.dbPrefix + 'stationmanagers');
    // console.log(this.uid)
    this.fireStore.collection(environment.dbPrefix + 'stationmanagers').doc(this.uid).valueChanges().subscribe(userData => {
      // console.log(userData)
      if(userData){
        const permissions = 'permissions';
        if (userData[permissions].dashboardUser === true) {
          this.authInfo$.next(new AuthInfo(this.uid));
          this.userData.next(userData);
          this.userDetails = userData;
        } else {
          window.alert('Permission Denied');
          this.router.navigate(['/login']);
          //this.presentToast(`Permission denied`, true, 'bottom', 2100);
        }
      }

    });
  }

  getUserId() {
    this.fireAuth.onAuthStateChanged(async (user) => {
      if (user) {
        // console.log('GetUserId ',user)
        this.uid = user.uid;
        this.getUserData();
      }
    });
  }
}
