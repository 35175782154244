<div class="container-fluid headerfixed">
  <app-header></app-header>
</div>
<div class="container-fluid contentone">
  <div class="row sidebarrow">
      <app-side showAccounting=true></app-side>
  </div>

  <div class="container-fluid body-card">
    <div class="row row-eq-height ">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <!-- <div class="row">
              <div class="col-12">
                <h3   class="card-title text-dark">Online Bookings</h3>
              </div>
              <div class="col-6 text-right">
              </div>
            </div> -->
            <app-online-payments></app-online-payments>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-eq-height ">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <!-- <div class="row">
              <div class="col-12">
                <h3   class="card-title text-dark">Station Bookings</h3>
              </div>
              <div class="col-6 text-right">
              </div>
            </div> -->
            <app-agency-payments></app-agency-payments>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-eq-height ">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <app-office-payments></app-office-payments>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-eq-height ">
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <app-expense-table></app-expense-table>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4 mb-4">
        <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
          <div class="card-body">
            <app-expense-summary-table></app-expense-summary-table>
          </div>
        </div>
      </div>
    </div>



  </div>




