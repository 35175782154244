import { Component, OnInit,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color,Label } from 'ng2-charts';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-annual-booking-chart',
  templateUrl: './annual-booking-chart.component.html',
  styleUrls: ['./annual-booking-chart.component.scss']
})
export class AnnualBookingChartComponent implements OnInit,OnDestroy {

  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'Booking Revenue' },
  ];
  public lineChartLabels = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      labels: {
        // fontFamily: '"Arvo", serif',
        fontSize: 14,
        fontColor: '#000000'
      }
    },
    scales: {
      yAxes: [
       {
           display: true,
           ticks: {
             fontSize: 12,
             fontColor: '#000000',


           }
       }
     ],
     xAxes: [
      {
          display: true,
          ticks: {
            fontSize: 12,
            fontColor: '#000000'
          }
      }
    ]
   },
   //
  //  maintainAspectRatio: false,

  };
  public lineChartColors = [
    {
      borderColor: '#4d9301',
      backgroundColor: '#4d9301',//'#424242',
      borderWidth: 3
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'bar';
  public lineChartPlugins = [];

  bookingRevenue = [];
  bookingCount = [];

  type  = 'amount';
  reportStartTime = 0;
  reportEndTime = Date.now();
  routes = [];
  origins = [];

  private bookingSubscription$: Subscription;

  constructor(private dataService: DataServicesService) { }

  ngOnInit(): void {
    // this.bookingSubscription$ = this.dataService.bookings.subscribe(bookings => {
    //   this.lineChartLabels = [];
    //   const bookingCounts = {};
    //   const bookingRevenue = { };
    //   const routes = {};
    //   const origins = {};
    //   if (bookings) {
    //     for (const booking in bookings) {
    //       if (bookings.hasOwnProperty(booking)) {
    //         const oneBooking = bookings[booking];
    //         const oneBookingDate = new Date(parseInt(oneBooking.paymentDate, 10));
    //         const dateComponents = oneBookingDate.toDateString().split(' ');
    //         const bookingMonth = dateComponents[1] + ' ' + dateComponents[3];
    //         const bookingDay = dateComponents[1] + ' ' + dateComponents[2] + ' ' + dateComponents[3];
    //         const amountPaid = 'amountPaid';
    //         const tripCost = oneBooking[amountPaid];
    //         const tripRoute = oneBooking['tripTitle']
    //         const tripOrigin = oneBooking['originStationAddress']
    //         if (bookingCounts[bookingDay]) {
    //           bookingCounts[bookingDay] = bookingCounts[bookingDay] + 1;
    //           bookingRevenue[bookingDay] = bookingRevenue[bookingDay] + parseInt(tripCost, 10);
    //         } else {
    //           bookingCounts[bookingDay] = 1;
    //           bookingRevenue[bookingDay] = parseInt(tripCost, 10);
    //           routes[tripRoute]=1;
    //           origins[tripOrigin] =1;
    //         }
    //       }
    //     }

    //     this.bookingCount = Object.entries(bookingCounts);
    //     this.bookingRevenue = Object.entries(bookingRevenue);

    //     this.dataService.annualBookingRevenueData=this.bookingRevenue;
    //     this.dataService.annualBookingCountData=this.bookingCount;

    //     this.dataService.annualBookingRevenueData_.next(this.bookingRevenue);
    //     this.dataService.annualBookingCountData_.next(this.bookingCount);

    //     this.revenueChart();
    //   }
    // });

    //
    this.dataService.annualBookingRevenueData_.subscribe(rec  =>{
      this.bookingRevenue = rec;
    });
    this.dataService.annualBookingCountData_.subscribe(rec=>{
      this.bookingCount = rec;
      this.lineChartLabels = [];
      this.revenueChart();
    })
  }

  public ngOnDestroy(): void {
    if(this.bookingSubscription$){
      this.bookingSubscription$.unsubscribe();
    }
  }

  revenueChart() {
    // console.log(this.bookingRevenue);
    this.lineChartLabels = [];
    this.lineChartData = [{data: [], label: 'Booking Revenue'}];
    this.bookingRevenue.forEach(record => {
      this.lineChartLabels.push(record[0]);
      this.lineChartData[0].data.push(Number(record[1]));
    });
    this.type = 'count';
  }

  countChart() {
    this.lineChartLabels = [];
    this.lineChartData = [{data:[], label: 'No of Bookings'}];
    this.bookingCount.forEach(record => {
      this.lineChartLabels.push(record[0]);
      this.lineChartData[0].data.push(Number(record[1]));
    });
    this.type = 'amount';

  }


   number_format(number, decimals, dec_point, thousands_sep) {
    // *     example: number_format(1234.56, 2, ',', ' ');
    // *     return: '1 234,56'
        number = (number + '').replace(',', '').replace(' ', '');
        var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = [''],
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    startDateChange($event){
      if ($event) {
        // console.log($event.value)
        this.reportStartTime = new Date($event.value).valueOf();
        this.dataService.timeRangeChange(this.reportStartTime, this.reportEndTime);
      }
    }

    endDateChange($event){
      if ($event){
        this.reportEndTime = new Date($event.value).valueOf() + (24 * 60 * 60 * 1000);
        this.dataService.timeRangeChange(this.reportStartTime, this.reportEndTime);
      }
    }

    toBar(){
      this.lineChartType = 'bar';
    }

    toLine(){
      this.lineChartType = 'line';

    }
}
