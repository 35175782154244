import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from './../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthServicesService } from './auth-services.service';
import * as firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';


@Injectable({
  providedIn: 'root'
})
export class DataServicesService {

  fleet: BehaviorSubject<{}> = new BehaviorSubject<{}[]>([]);
  vehicleType: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  drivers: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  expenses: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  expenseSummary: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  stationManagersData: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  bookings: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  miniCards:BehaviorSubject<any> = new BehaviorSubject<any>([]);
  vehicleAnalytics: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  fleetAnalyticsCard:BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  todayBookings: BehaviorSubject<{}> = new BehaviorSubject<{}[]>([]);
  tripRoute: BehaviorSubject<{}> = new BehaviorSubject<{}[]>([]);
  tripOrigin: BehaviorSubject<{}> = new BehaviorSubject<{}[]>([]);
  routePerformance: BehaviorSubject<{}> = new BehaviorSubject<{}[]>([]);
  originPerformance: BehaviorSubject<{}> = new BehaviorSubject<{}[]>([]);
  allBookingsData: BehaviorSubject<{}> = new BehaviorSubject<{}[]>([]);
  allAgencyBooking: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  allStationManagerBooking: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  allOnlineBooking: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  closedTrips: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  allClosedTrips: BehaviorSubject<{}> = new BehaviorSubject<{}[]>([]);

  reveueCardStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  routeCardStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  originCardStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  vehicleCardStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  tripCardStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  vehicleTripCardStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  mainDashboardView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  spinnerBehaviour: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);


  subTitle: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentSelectedTrip: BehaviorSubject<{}> = new BehaviorSubject<{}>(null);



  addVehicleStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  fleetTrip: BehaviorSubject<{}> = new BehaviorSubject<{}>({});


  transactions: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  managerOfficePayment: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  allManagerOfficePayment: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  onlinePayments: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  stationData: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  currentOfficeBooking: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  miniFleetCardData: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);

  annualBookingRevenueData_: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);
  annualBookingCountData_: BehaviorSubject<{}[]> = new BehaviorSubject<{}[]>([]);

  routesArray: BehaviorSubject<any[]> = new BehaviorSubject<[]>([]);
  originsArray: BehaviorSubject<any[]> = new BehaviorSubject<[]>([]);

  constants: BehaviorSubject<{}> = new BehaviorSubject<{}>({});


  //For Download  pages
  annualBookingRevenueData=[];
  annualBookingCountData=[];

  routeRevenueData=[];
  routeCountData=[];

  originRevenueData=[];
  originCountData=[];

  vehicleRevenueData=[];
  vehicleCountData=[];


  reveueCardStatus1 = true;
  routeCardStatus1 = true;
  originCardStatus1 = true;
  vehicleCardStatus1 = true;
  tripCardStatus1 = true;
  vehicleTripCardStatus1 = true;
  addVehicleStatus1 = true;

  bookingsData = [];
  closedTripData = [];
  userDetails;
  stationId;
  states;
  public currentBookingTrip;
  public stationDetails;

  public fleetTripCard;

  public openedVehicle;
  public openedVehicleType;

  public openedDriver;

  public sortedVehicleTableData;

  public sortedBookingTableData;

  platformConstants;
  flutterwaveKey;

  dateOnlineBooking;
  public selectedManager;
  public terminals = [];
  public routes = [];


  lgas;
  cities;

  lastCall = 0;
  last31Days = Date.now()-(30*24*60*60*1000);  //change 31 to 356

  startTime  = this.last31Days;
  endTime = Date.now();
  financialPermissions;
  constructor(private http: HttpClient, private authService: AuthServicesService, private afs: AngularFirestore) {
    this.authService.userData.subscribe(userData => {
      if (userData){
        this.userDetails = userData;
        const stationId = 'stationId';
        this.stationId = userData[stationId];

        this.getStationData(this.stationId).subscribe(stationData => {
          this.stationData.next(stationData.payload.data());
          this.stationDetails = stationData.payload.data();
          this.getConstants();

        })
        const viewFinancials = 'viewFinancials';
        const permissions = 'permissions';
        if (userData[permissions].viewFinancials) {
          this.getAccountingData();
          this.getExpenses();
          this.getAllOfficeBooking();
          this.getOnlineBookings();
        }

        const viewManagers = 'viewManagers';
        const superAdmin = 'superAdmin'
        if ((userData[permissions].viewManagers) || (userData[permissions].superAdmin)) {
          this.getStationManagers();
        }

      } else {
        this.userDetails = null;
        this.stationData.next(null);
        this.stationDetails = null;

      }
    });
  }


  getStates() {
    const url = environment.config.databaseURL + '/station/states.json';
    return this.http.get(url).subscribe(states => {
      this.states = states;
    });
  }

  getCities() {
    const url = environment.config.databaseURL + '/station/city_table.json';
    return this.http.get(url).subscribe(cities => {
      this.cities = cities;
    });

  }

  getConstants() {
    const constantCollection = environment.dbPrefix + 'constants' ;
    try {
      this.afs.collection(constantCollection).valueChanges().subscribe(constants => {
        if (constants) {
          const flutterwaveKey = 'flutterwaveKey';
          this.constants.next(constants[0]);
          this.platformConstants = constants[0];
          this.flutterwaveKey = this.platformConstants[flutterwaveKey];

        }
      });
    } catch (e) {
      console.log('get constant error - ', e);
    }
  }

  getDrivers() {
    // console.log('Search driver')
    const drivereCollection = environment.dbPrefix + 'stationdrivers';
    try {
      this.afs.collection(drivereCollection, ref => ref.where('stationId', '==', `${this.stationId}`)).valueChanges().subscribe(drivers => {
        if (drivers) {
          // console.log('Search driver'), drivers
          this.drivers.next(drivers);
        }
      });
    } catch (e) {
      console.log('get driver error - ', e);
    }
  }

  getExpenses(){
    const expenseCollection = environment.dbPrefix + 'stationExpense';
    try {
      this.afs.collection(expenseCollection, ref => ref.where('stationId', '==', `${this.stationId}`).orderBy('extryDate', 'desc')).valueChanges().subscribe(expenses => {
        if (expenses) {
          // console.log('Expenses',expenses)
          this.expenses.next(expenses);
        }
      });
    } catch (e) {
      console.log('get expense error - ', e);
    }
  }

  // getOriginPerformance(stationId,recordDate){
  //   if (stationId){
  //     this.stationId = stationId;

  //     let q =` SELECT  originStationAddress as trip_origin, COUNT(originStationAddress) as trip_count, SUM(amountPaid) as trip_amount,
  //               FROM arrive_booking_data.bookings
  //               WHERE stationId = '${stationId}'
  //               and paymentDate >= ${recordDate}
  //               GROUP BY originStationAddress
  //               ORDER BY trip_count desc;`;

  //     this.onQuery('arrive_booking_data', q).subscribe(tripRoute => {
  //       if (!tripRoute) {
  //         return;
  //       }
  //       const result = 'result';
  //       const requests = tripRoute[result][0];
  //       this.originPerformance.next(requests);
  //     });

  //   }

  // }

  // getOriginPerformanceDate(startDate,endDate){

  //   if (this.stationId){

  //     let q =` SELECT  originStationAddress as trip_origin, COUNT(originStationAddress) as trip_count, SUM(amountPaid) as trip_amount,
  //               FROM arrive_booking_data.bookings
  //               WHERE stationId = '${this.stationId}'
  //               and paymentDate >= ${startDate}
  //               and paymentDate <= ${endDate}

  //               GROUP BY originStationAddress
  //               ORDER BY trip_count desc;`;

  //     this.onQuery('arrive_booking_data', q).subscribe(tripRoute => {
  //       if (!tripRoute) {
  //         return;
  //       }
  //       const result = 'result';
  //       const requests = tripRoute[result][0];
  //       this.originPerformance.next(requests);
  //     });

  //   }

  // }

  // getRoutePerformance(stationId,recordDate){
  //   if (stationId){
  //     this.stationId = stationId;

  //     let q =` SELECT  tripTitle as trip_route, COUNT(originStationAddress) as trip_count, SUM(amountPaid) as trip_amount,
  //               FROM arrive_booking_data.bookings
  //               WHERE stationId = '${stationId}'
  //               and paymentDate >= ${recordDate}
  //               GROUP BY trip_route
  //               ORDER BY trip_count desc;`;

  //     this.onQuery('arrive_booking_data', q).subscribe(tripRoute => {
  //       if (!tripRoute) {
  //         return;
  //       }
  //       const result = 'result';
  //       const requests = tripRoute[result][0];
  //       this.routePerformance.next(requests);
  //     });

  //   }

  // }

  // getRoutePerformanceDate(startDate,endDate){
  //   if (this.stationId){

  //     let q =` SELECT  tripTitle as trip_route, COUNT(originStationAddress) as trip_count, SUM(amountPaid) as trip_amount,
  //               FROM arrive_booking_data.bookings
  //               WHERE stationId = '${this.stationId}'
  //               and paymentDate >= ${startDate}
  //               and paymentDate <= ${endDate}

  //               GROUP BY trip_route
  //               ORDER BY trip_count desc;`;

  //     this.onQuery('arrive_booking_data', q).subscribe(tripRoute => {
  //       if (!tripRoute) {
  //         return;
  //       }
  //       const result = 'result';
  //       const requests = tripRoute[result][0];
  //       this.routePerformance.next(requests);
  //     });

  //   }

  // }

  // getTripRoute(stationId,recordDate){
  //   if (stationId){
  //     this.stationId = stationId;


  //     let q = `SELECT  tripTitle, COUNT(tripTitle) as trip_count, SUM(amountPaid) as trip_amount, DATE( TIMESTAMP_MILLIS(CAST(paymentDate as INT64))) as tripDate  FROM arrive_booking_data.bookings
  //     WHERE stationId = '${stationId}' and paymentDate >= ${recordDate} GROUP BY tripTitle, tripDate ORDER BY tripDate desc;`

  //     this.onQuery('arrive_booking_data', q).subscribe(tripRoute => {
  //       if (!tripRoute) {
  //         return;
  //       }
  //       const result = 'result';
  //       const requests = tripRoute[result][0];
  //       this.tripRoute.next(requests);
  //     });

  //   }

  // }

  // getTripOrigin(stationId,recordDate){
  //   if (stationId){
  //     this.stationId = stationId;


  //     let q =
  //       `SELECT originStationAddress as trip_origin,COUNT(originStationAddress) as trip_count, SUM(amountPaid) as trip_amount,
  //         DATE( TIMESTAMP_MILLIS(CAST(paymentDate as INT64))) as trip_date
  //         FROM arrive_booking_data.bookings
  //         WHERE stationId = '${stationId}'
  //         and paymentDate >= ${recordDate}
  //         GROUP BY originStationAddress, trip_date ORDER BY trip_date desc;`

  //     this.onQuery('arrive_booking_data', q).subscribe(tripRoute => {
  //       if (!tripRoute) {
  //         return;
  //       }
  //       const result = 'result';
  //       const requests = tripRoute[result][0];
  //       this.tripOrigin.next(requests);
  //     });
  //   }
  // }

  // getTripOriginDate(startDate,endDate){

    // return this.getTripOrigin(startDate,endDate)
    // if (this.stationId){
    //   let q =
    //     `SELECT originStationAddress as trip_origin,COUNT(originStationAddress) as trip_count, SUM(amountPaid) as trip_amount,
    //       DATE( TIMESTAMP_MILLIS(CAST(paymentDate as INT64))) as trip_date
    //       FROM arrive_booking_data.bookings
    //       WHERE stationId = '${this.stationId}'
    //       and paymentDate >= ${startDate}
    //       and paymentDate <= ${endDate}
    //       GROUP BY originStationAddress, trip_date ORDER BY trip_date desc;`

    //   this.onQuery('arrive_booking_data', q).subscribe(tripRoute => {
    //     if (!tripRoute) {
    //       return;
    //     }
    //     const result = 'result';
    //     const requests = tripRoute[result][0];
    //     this.tripOrigin.next(requests);
    //   });
    // }
  // }

  // getTripRouteDates(startDate,endDate){
    // return this.getTripRoute(startDate,endDate)
    // if (this.stationId){


    //   let q = `SELECT  tripTitle, COUNT(tripTitle) as trip_count, SUM(amountPaid) as trip_amount, DATE( TIMESTAMP_MILLIS(CAST(paymentDate as INT64))) as tripDate  FROM arrive_booking_data.bookings
    //   WHERE stationId = '${this.stationId}' and paymentDate >= ${startDate} and paymentDate <= ${endDate}  GROUP BY tripTitle, tripDate ORDER BY tripDate desc;`

    //   this.onQuery('arrive_booking_data', q).subscribe(tripRoute => {
    //     if (!tripRoute) {
    //       return;
    //     }
    //     const result = 'result';
    //     const requests = tripRoute[result][0];
    //     // requests.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1);
    //     this.tripRoute.next(requests);
    //   });

    // }

  // }

  getVehicleAnalytics(){
    let q = `
      SELECT vehicle_reg, COUNT(vehicle_reg) as total_trips ,SUM(passengers) as total_passengers, SUM(total_paid) as total_income
        FROM
          (SELECT tripId as trip_id,vehicleReg as vehicle_reg,DATE( TIMESTAMP_MILLIS(CAST(tripDate as INT64))) as trip_date,
          COUNT(bookingId) as passengers ,SUM(amountPaid) as total_paid
          FROM arrive_booking_data.bookings
          WHERE stationId =  '${this.stationId}'
          GROUP BY trip_id,vehicle_reg,trip_date
          ORDER BY trip_date desc)
        GROUP BY vehicle_reg
            `;
    this.onQuery('arrive_booking_data', q).subscribe(tripRoute => {
      if (!tripRoute) {
        return;
      }
      const result = 'result';
      const requests = tripRoute[result][0];
      // requests.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1);
      this.vehicleAnalytics.next(requests);
    });
  }

  getBooking(stationId,searchDate?) {

  //  const today = Date.now();
  //  if((today - this.lastCall)<(10*1000)){

  //    return;
  //  }


  //  let recordDate = this.last31Days;

  //  if(searchDate ){
  //    recordDate = searchDate;
  //  }

  //   const managerPermission = this.userDetails['permissions'];
  //   const managementDashboardPermission = managerPermission.managementDashboard
  //   const superAdmin = managerPermission.superAdmin
  //   const terminal = this.userDetails['terminalAddress']

    // if (stationId){
    //   let q='';
    //   if(managementDashboardPermission||superAdmin){
    //     q = `SELECT distinct * FROM arrive_booking_data.bookings WHERE stationId = '${stationId}' and paymentDate >= ${recordDate} and paymentDate <= ${this.endTime}`;
    //   } else {
    //     if(terminal){
    //       q = `SELECT distinct * FROM arrive_booking_data.bookings WHERE stationId = '${stationId}' and originStationAddress = '${terminal}' and paymentDate >= ${recordDate} and paymentDate <= ${this.endTime}`;

    //     } else {
    //       q = `SELECT distinct * FROM arrive_booking_data.bookings WHERE stationId = '${stationId}' and paymentDate >= ${recordDate} and paymentDate <= ${this.endTime}`;
    //     }
    //   }


    //   this.spinnerBehaviour.next( true)
    //   this.onQuery('arrive_booking_data', q).subscribe(paidBookings => {
    //     if (!paidBookings) {
    //       return;
    //     }
    //     const result = 'result';
    //     const requests = paidBookings[result][0];
    //     requests.sort((b, a) => a.paymentDate <= b.paymentDate ? -1 : 1);
    //     this.getTodayBooking(requests);
    //     this.bookings.next(requests);
    //     this.allBookingsData.next(requests);
    //     this.bookingsData = requests;
    //     this.lastCall = Date.now();
    //   });


      //End here
      // console.log('Called 1')
      // this.spinnerBehaviour.next( false)
      // this.getTripRoute(stationId,recordDate);
      // this.getTripOrigin(stationId,recordDate);
      // this.getRoutePerformance(stationId,recordDate);
      // this.getOriginPerformance(stationId,recordDate)
    // }
  }

  async container_getBookings(){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      const myUrl = environment.containerurl  + 'station-bookings/bookings';
      const headers = { Authorization: 'Bearer ' + accessToken};
      const data = {
        env: environment.dbPrefix, //'prod_',
        startDate:this.startTime,
        endDate:this.endTime
      }
      return this.http.post(myUrl, data, {headers});
    } else {
      return
    }
  }

getBookings(){
  this.container_getBookings().then(response=>{
    response.subscribe(requests=>{
      this.getTodayBooking(requests['result']);
      // this.bookings.next(requests['result']);
      // this.allBookingsData.next(requests['result']);
      this.bookingsData = requests['result'];
      this.annualBookingRevenueData_.next(requests['bookingRevenue'])
      this.annualBookingCountData_.next(requests['bookingCount'])
      this.annualBookingRevenueData=requests['bookingRevenue'];
      this.annualBookingCountData=requests['bookingCount'];
    })

  })
}

async container_getMinicards(){
  if(await firebase.default.auth().currentUser){
    const accessToken = await firebase.default.auth().currentUser.getIdToken();
    const myUrl = environment.containerurl  + 'station-dashboard/mini-cards';
    const headers = { Authorization: 'Bearer ' + accessToken};
    const data = {
      env:environment.dbPrefix, //'prod_',
      startDate:this.startTime,
      endDate:this.endTime
    }
    return this.http.post(myUrl, data, {headers});
  } else {
    return
  }
}

getMinicards(){
  this.container_getMinicards().then(response=>{
    response.subscribe(requests=>{
      this.miniCards.next(requests['result']);
      this.bookings.next(requests['bookings'])
      this.bookingsData = requests['bookings'];
    })
  })
}

async container_bookings(startDate?,endDate?){
  if(await firebase.default.auth().currentUser){
    const accessToken = await firebase.default.auth().currentUser.getIdToken();
    const myUrl = environment.containerurl  + 'station-dashboard/mini-cards';
    const headers = { Authorization: 'Bearer ' + accessToken};
    const data = {
      env:environment.dbPrefix, //'prod_',
      startDate:startDate||this.startTime,
      endDate:endDate||this.endTime
    }
    return this.http.post(myUrl, data, {headers});
  } else {
    return
  }
}

bookingsF(startDate?,endDate?){
  this.container_bookings(startDate,endDate).then(response=>{
    response.subscribe(requests=>{
      // this.miniCards.next(requests['result']);
      this.bookings.next(requests['bookings'])
      this.bookingsData = requests['bookings'];
      // console.log(this.bookingsData)
    })
  })
}

async container_getTripRoute(startDate?,endDate?){
  if(await firebase.default.auth().currentUser){
    const accessToken = await firebase.default.auth().currentUser.getIdToken();
    const myUrl = environment.containerurl  + 'station-dashboard/trip-routes';
    const headers = { Authorization: 'Bearer ' + accessToken};
    const data = {
      env:environment.dbPrefix, //'prod_',
      startDate:startDate||this.startTime,
      endDate:endDate||this.endTime
    }
    return this.http.post(myUrl, data, {headers});
  } else {
    return
  }
}

  getTripRoute(startDate?,endDate?){
    this.container_getTripRoute(startDate,endDate).then(response=>{
      response.subscribe(requests=>{
        this.routesArray.next(requests['titles']);
        this.tripRoute.next(requests['result']);
      })
    })
  }

  async container_getTripOrigin(startDate?,endDate?){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      const myUrl = environment.containerurl  + 'station-dashboard/trip-origin';
      const headers = { Authorization: 'Bearer ' + accessToken};
      const data = {
        env:environment.dbPrefix, //'prod_',
        startDate:startDate||this.startTime,
        endDate:endDate||this.endTime
      }
      return this.http.post(myUrl, data, {headers});
    } else {
      return
    }
  }

  getTripOrigin(startDate?,endDate?){
    this.container_getTripOrigin(startDate,endDate).then(response=>{
      response.subscribe(requests=>{
        this.originsArray.next(requests['titles']);
        this.tripOrigin.next(requests['result']);
      })
    })
  }

  async container_getOriginPerformance(startDate?,endDate?){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      const myUrl = environment.containerurl  + 'station-dashboard/origin-performance';
      const headers = { Authorization: 'Bearer ' + accessToken};
      const data = {
        env:environment.dbPrefix, //'prod_',
        startDate:startDate||this.startTime,
        endDate:endDate||this.endTime
      }
      return this.http.post(myUrl, data, {headers});
    } else {
      return
    }
  }

  getOriginPerformance(startDate?,endDate?){
    this.container_getOriginPerformance(startDate,endDate).then(response=>{
      response.subscribe(requests=>{
        this.originPerformance.next(requests['result']);
      })
    })
  }

  async container_getRoutePerformance(startDate?,endDate?){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      const myUrl = environment.containerurl  + 'station-dashboard/route-performance';
      const headers = { Authorization: 'Bearer ' + accessToken};
      const data = {
        env:environment.dbPrefix, //'prod_',
        startDate:startDate||this.startTime,
        endDate:endDate||this.endTime
      }
      return this.http.post(myUrl, data, {headers});
    } else {
      return
    }
  }

  getRoutePerformance(startDate?,endDate?){
    this.container_getRoutePerformance(startDate,endDate).then(response=>{
      response.subscribe(requests=>{
        this.routePerformance.next(requests['result']);
      })
    })
  }


  getOtherInitialData(){

    this.getMinicards()
    this.getTripRoute();
    this.getTripOrigin();
    this.getRoutePerformance();
    this.getOriginPerformance()
  }

  getClosedTrips(stationId) {
    // const stationId = this.stationId;
    // console.log('StationId', stationId);
    if (stationId){
      const q = `SELECT distinct * FROM arrive_booking_data.closed_trips WHERE stationId = '${stationId}'`;
      this.onQuery('arrive_booking_data', q).subscribe(closedTrips => {
        if (!closedTrips) {
          return;
        }
        const result = 'result';
        const requests = closedTrips[result][0];
        requests.sort((b, a) => a.tripDate <= b.tripDate ? -1 : 1);
        this.closedTrips.next(requests);
        this.allClosedTrips.next(requests);
        this.closedTripData = requests;
      });
    }
  }

  getTodayBooking(requests){
    const today = new Date();
    const todayMidnight = today.valueOf() -  (today.getHours() * 60 * 60 * 1000 + today.getMinutes() * 60 * 1000);
    const todayBooking = [];
    if (requests){
      for (const request in requests) {
        if (requests.hasOwnProperty(request)) {
          const oneRequest = requests[request];
          const paymentDate = 'paymentDate';
          const requestPaymentTime = oneRequest[paymentDate];
          if(requestPaymentTime >= todayMidnight){
            todayBooking.push(oneRequest);
          }
        }
      }
      this.todayBookings.next(todayBooking);
    }

  }

  getFleet(){
   this.stationFleet().then(fleet => {
     if (fleet){
      fleet.subscribe(sFleet => {
        const stationVehicles = 'stationVehicles';
        this.fleet.next(sFleet[stationVehicles]);
      });
     }

   });
  }

  async stationFleet(){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'stationfleets';
      const headers = { Authorization: 'Bearer ' + accessToken};
      return this.http.post(myUrl, {}, {headers});
    } else {
      return
    }

  }

  getVehicleType(){

    this.stationVehicleType().then(fleetType => {
      // console.log('again', fleetType)
      if (fleetType){
        fleetType.subscribe(sFleetType => {
        //  console.log('Back...',sFleetType);
         const stationVehicleTypes = 'stationVehicleTypes';
         this.vehicleType.next(sFleetType[stationVehicleTypes]);
       });
      }

    });
   }

   getStationManagers(){

    this.stationManagers().then(managers => {
      // console.log('again', fleetType)
      if (managers){
        managers.subscribe(smanagers => {
        //  console.log('Back...',sFleetType);
         const stationManagerList = 'stationManagerList';
        //  console.log(smanagers[stationManagerList])
         this.stationManagersData.next(smanagers[stationManagerList]);
       });
      }

    });
   }

  async stationVehicleType(){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      // console.log('Access Token', accessToken)
      const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'stationvehicletype';
      const headers = { Authorization: 'Bearer ' + accessToken};
      return this.http.post(myUrl, {}, {headers});
    } else {
      return
    }

  }

  async stationManagers(){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      // console.log('Access Token', accessToken)
      const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'stationManagers';
      const headers = { Authorization: 'Bearer ' + accessToken};
      return this.http.post(myUrl, {}, {headers});
    } else {
      return
    }

  }

  getCurrentTrip(station){
    return this.afs.collection(environment.dbPrefix + 'trip',
    ref => ref.where('originStation', '==', station)//.orderBy('tripDate', 'desc')
    ).snapshotChanges() ;
  }
  getClosedTrip(station){
    return this.afs.collection(environment.dbPrefix + 'closedtrip',
    ref => ref.where('originStation', '==', station)//.orderBy('tripDate', 'desc')
    ).snapshotChanges() ;
  }

/**Last 3 days */
  getClosedTripDefault(station){
    const last72hours = Date.now() - (3*24*60*60*1000)
    return this.afs.collection(environment.dbPrefix + 'closedtrip',
    ref => ref.where('originStation', '==', station).where('tripDate','>',last72hours).orderBy('tripDate', 'desc')
    ).snapshotChanges() ;
  }

  getClosedTripRange(station,start,end){
    // const last72hours = Date.now() - (3*24*60*60*1000)
    return this.afs.collection(environment.dbPrefix + 'closedtrip',
    ref => ref.where('originStation', '==', station).where('tripDate','>=',start).where('tripDate','<=',end).orderBy('tripDate', 'desc')
    ).snapshotChanges() ;
  }

  getSingleTrip(tripId){
    return this.afs.collection(environment.dbPrefix + 'trip').doc(tripId).snapshotChanges()
  }


  getStationData(stationId){
    return this.afs.collection(environment.dbPrefix + 'station').doc(stationId).snapshotChanges()
  }

  timeRangeChange(inputTime, outputTime){

    this.startTime =  inputTime
    this.endTime =outputTime;
    const last31Days = Date.now()-(31*24*60*60*1000);
    this.getBookings()
    this.getMinicards()
    this.getTripRoute();
    this.getTripOrigin()
    this.getOriginPerformance()
    this.getRoutePerformance()
    return



    // if(inputTime <this.last31Days){
    if(inputTime && outputTime){
      console.log('Query DB')
      return this.getBooking(this.stationId,inputTime)
    }

    this.spinnerBehaviour.next( true)
    const today = new Date();
    const todayMidnight = today.valueOf() -  (today.getHours() * 60 * 60 * 1000 + today.getMinutes() * 60 * 1000);

    const allBookingsData = this.allBookingsData;
    const filteredRequest = [];

    allBookingsData.subscribe(requests => {
      if (requests){
        for (const request in requests) {
          if (requests.hasOwnProperty(request)) {
            const oneRequest = requests[request];
            const paymentDate = 'paymentDate';
            const requestPaymentTime = oneRequest[paymentDate];
            if((requestPaymentTime >= inputTime) && (requestPaymentTime <= outputTime)){
              filteredRequest.push(oneRequest);
            }
          }
        }
        this.bookings.next(filteredRequest);
        this.bookingsData = filteredRequest;
        console.log('Called  2')
        this.spinnerBehaviour.next( false)
      }
    });

    const allClosedTrips = this.allClosedTrips;
    const filteredTrips = [];
    allClosedTrips.subscribe(trips => {
      if (trips){
        for (const trip in trips){
          if (trips.hasOwnProperty(trip)){
            const oneTrip = trips[trip];
            const tripDate = 'tripDate';
            const closedTripDate = oneTrip[tripDate];
            if((closedTripDate >=  inputTime) && (closedTripDate <= outputTime)){
              filteredTrips.push(oneTrip);
            }
          }
        }

        this.closedTrips.next(filteredTrips);
        this.closedTripData = filteredTrips;
      }

    });
  }

  onQuery(datasetName: string, q: string) {
    const postParams = {
      datasetName,
      query: q
    };
    return this.http.post(environment.cloudfunctionurl + 'bigQSqlQuery', postParams);
  }

  toggleReveue() {
    if (this.reveueCardStatus1){
      this.reveueCardStatus1 = false;
      this.reveueCardStatus.next(false);
    } else {
      this.reveueCardStatus1 = true;
      this.reveueCardStatus.next(true);
    }
  }
  toggleRoute() {
    if (this.routeCardStatus1){
      this.routeCardStatus1 = false;
      this.routeCardStatus.next(false);
    } else {
      this.routeCardStatus1 = true;
      this.routeCardStatus.next(true);
    }
  }

  toggleOrigin() {
    if (this.originCardStatus1){
      this.originCardStatus1 = false;
      this.originCardStatus.next(false);
    } else {
      this.originCardStatus1 = true;
      this.originCardStatus.next(true);
    }
  }

  toggleVehicle() {
    if (this.vehicleCardStatus1){
      this.vehicleCardStatus1 = false;
      this.vehicleCardStatus.next(false);
    } else {
      this.vehicleCardStatus1 = true;
      this.vehicleCardStatus.next(true);
    }
  }

  toggleTrip(){
    if (this.tripCardStatus1){
      this.tripCardStatus1 = false;
      this.tripCardStatus.next(false);
    } else {
      this.tripCardStatus1 = true;
      this.tripCardStatus.next(true);
    }
  }

  addVehicleDialog(){

    if(this.addVehicleStatus1){
      this.addVehicleStatus.next(false);
      this.addVehicleStatus1 = false;
    } else {
      this.addVehicleStatus1 = true;
      this.addVehicleStatus.next(true);
    }
  }

  toggleVehicleTrip(){
    if(this.vehicleTripCardStatus1){
      this.vehicleTripCardStatus.next(false);
      this.vehicleTripCardStatus1 = false;
    } else {
      this.vehicleTripCardStatus1 = true;
      this.vehicleTripCardStatus.next(true);
    }

  }

  cashPayment(bookingData ,accessToken) {
    const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'cashBooking';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, bookingData, {headers});
   }

  updateTerminal(stations ,accessToken) {
  const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'updateStationTerminals';
  const headers = { Authorization: 'Bearer ' + accessToken};
  return this.http.post(myUrl, stations, {headers});
  }

  updateRoutes(routes ,accessToken) {
    const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'updateStationRoutes';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, routes, {headers});
    }

  updateManager(selectedManager ,accessToken) {
    const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'updateStationManager';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, selectedManager, {headers});
    }


  createManager(userData ,accessToken) {
    const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'createStationManager';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, userData, {headers});
  }

  createTrip(tripData ,accessToken) {
    const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'createTrip';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, tripData, {headers});
  }

  closeTrip(tripData ,accessToken) {
    const myUrl = environment.cloudfunctionurl + environment.dbPrefix + 'closeTrip';
    const headers = { Authorization: 'Bearer ' + accessToken};
    return this.http.post(myUrl, tripData, {headers});
  }

  public create(collection: string, data ): Promise<void> {
    const collection1 = environment.dbPrefix + collection;
    return this.afs.doc(`${collection1}/${data.id}`).set(data, {merge: true});
  }

  getAccountingData() {
    const accountCollection = environment.dbPrefix + 'account_transactions' ;
    try {
      this.afs.collection(accountCollection, ref => ref.where('originStation', '==', this.userDetails.location))
      .valueChanges().subscribe(acct => {
        this.transactions.next(acct);
      });

    } catch (e) {
      console.log('get account error - ', e);
    }
  }

  getOnlineBookings() {
    const bookingCollection = environment.dbPrefix + 'booking' ;
    try {
      this.afs.collection(bookingCollection, ref => ref.where('originStation', '==', this.userDetails.location)
      .where('paymentMode', '==', 'Payment Gateway').where('amountPaid', '>', 0)).valueChanges().subscribe(onlinePayment => {
      this.onlinePayments.next(onlinePayment);
      });

    }  catch (e) {
      console.log('get office booking error - ', e);
    }
  }

  getAllOfficeBooking() {
    const bookigCollection = environment.dbPrefix + 'booking' ;
    try {
      this.afs.collection(bookigCollection, ref => ref.where('originStation', '==', this.userDetails.location)
      .where('officePayment', '==', true).where('amountPaid', '>', 0)).valueChanges().subscribe(allOfficePayment => {
      allOfficePayment.sort((b, a) => a['paymentDate'] <= b['paymentDate'] ? -1 : 1);
      this.allManagerOfficePayment.next(allOfficePayment);
      });

    }  catch (e) {
      console.log('get office booking error - ', e);
    }

  }

  async container_getAllAgentBooking(startDate?,endDate?){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      const myUrl = environment.containerurl  + 'station-bookings/agency-bookings';
      const headers = { Authorization: 'Bearer ' + accessToken};
      const data = {
        env: environment.dbPrefix, //'prod_',
        startDate:startDate||this.startTime,
        endDate:endDate||this.endTime
      }
      return this.http.post(myUrl, data, {headers});
    } else {
      return
    }
  }

  getAllAgentBooking(startDate?,endDate?){
    this.container_getAllAgentBooking(startDate,endDate).then(response=>{
      response.subscribe(requests=>{
        this.allAgencyBooking.next(requests['result']);
      })
    })
  }

  async container_getAllStationBooking(startDate?,endDate?){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      const myUrl = environment.containerurl  + 'station-bookings/station-manager-bookings';
      const headers = { Authorization: 'Bearer ' + accessToken};
      const data = {
        env: environment.dbPrefix, //'prod_',
        startDate:startDate||this.startTime,
        endDate:endDate||this.endTime
      }
      return this.http.post(myUrl, data, {headers});
    } else {
      return
    }
  }

  getAllStationBooking(startDate?,endDate?){
    this.container_getAllStationBooking(startDate,endDate).then(response=>{
      response.subscribe(requests=>{
        this.allStationManagerBooking.next(requests['result']);
      })
    })
  }

  async container_getAllOnlineBooking(startDate?,endDate?){
    if(await firebase.default.auth().currentUser){
      const accessToken = await firebase.default.auth().currentUser.getIdToken();
      const myUrl = environment.containerurl  + 'station-bookings/online-bookings';
      const headers = { Authorization: 'Bearer ' + accessToken};
      const data = {
        env: environment.dbPrefix, //'prod_',
        startDate:startDate||this.startTime,
        endDate:endDate||this.endTime
      }
      return this.http.post(myUrl, data, {headers});
    } else {
      return
    }
  }

  getAllOnlineBooking(startDate?,endDate?){
    this.container_getAllOnlineBooking(startDate,endDate).then(response=>{
      response.subscribe(requests=>{
        this.allOnlineBooking.next(requests['result']);
      })
    })
  }
}


//agencyPayment
