import { Component, OnInit,AfterViewInit,  ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog'

import Swal from 'sweetalert2';


import { AuthServicesService } from 'src/app/services/auth-services.service';
import { DataServicesService } from 'src/app/services/data-services.service';

import * as firebase from 'firebase/compat/app';
import { NotificationService } from 'src/app/services/notification.service';
import { BookTripComponent } from 'src/app/modals/book-trip/book-trip.component';
import { AddTripComponent } from 'src/app/modals/add-trip/add-trip.component';


@Component({
  selector: 'app-current-trips',
  templateUrl: './current-trips.component.html',
  styleUrls: ['./current-trips.component.scss']
})
export class CurrentTripsComponent implements OnInit,AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 5;
  originalTripList = [];

  public tripList = [];
  public userDetails;

  public busImage = '../../assets/imgs/bus.png';
  public carImage = '../../assets/imgs/car.png';
  public vanImage = '../../assets/imgs/minivan.jpg';
  public coachImage = '../../assets/imgs/coach.jpg';

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'vehicleType',
    'tripTitle',
    'originDetails',
    'seats',
    // 'totalSeats',
    // 'availableSeats',
    'paidPassengers',
    'amount',
    'bookingBtn',
    // 'downloadBtn',
    // 'closeBtn'
    'view'
  ];

  closeResult: string;
  currentTrip;
  reportStartTime = 0;
  reportEndTime = Date.now();
  managerPermissions;
  createTripPermission = false;
  closeTripPermission = false;

  constructor(private dataService: DataServicesService, private authService: AuthServicesService, private dialog: MatDialog, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.authService.userData.subscribe(userDetails => {
      if (userDetails){
        this.userDetails = userDetails;
        this.managerPermissions = this.userDetails.permissions;
        if ((this.userDetails.usertype === 'stationmanager') && (this.userDetails.status === 'active')  && (this.userDetails.permissions.closeBooking === true||this.userDetails.permissions.superAdmin === true)) {
          this.closeTripPermission = true;
        } else {
          this.closeTripPermission = false;
        }

        if ((this.userDetails.usertype === 'stationmanager') && (this.userDetails.status === 'active')  && (this.userDetails.permissions.addTrip === true)) {
          this.createTripPermission = true;
        } else {
          this.createTripPermission = false;
        }
        this.dataService.getCurrentTrip(this.userDetails.location).subscribe(currentTrips => {
          if (currentTrips){
            this.tripList = [];
            currentTrips.forEach((data) => {
              if(data){
                const oneTrip = data.payload.doc.data();
                this.tripList.push(oneTrip);
              }
            });

            this.tableData  = this.tripList;
            this.originalTripList = this.tripList;
            this.dataSource = new MatTableDataSource(this.tableData);
            this.dataLength = this.tableData.length;
            this.dataSource.paginator = this.paginator;
            this.dataService.currentSelectedTrip.next(this.tableData[0]);
          }
        });

      }
    });

  }

  ngAfterViewInit() {
    if (this.dataSource){
      // this.dataSource = new MatTableDataSource(this.tableData);
      // this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  async closeTrip(trip){
    if(this.closeTripPermission){

      Swal.fire({
        title: 'Close Trip',
        text: "Are you sure you want to close this trip?",
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor:  '#4d9301',
        width:'15%',
        confirmButtonText: 'Confirm',
        background:'#d6dde0',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const accessToken = await firebase.default.auth().currentUser.getIdToken();
          this.dataService.closeTrip(trip, accessToken).subscribe(
          async (res) => {
           console.log(res);
           this.notificationService.success('::'+res['status']+' - '+res['message'] );
          });

        }})


    } else {
      alert('Permission denied')
    }

  }

  downloadManifest(trip){

    const agentConfirmation = this.userDetails.terminalAddress === trip.originStationAddress;
    if (this.userDetails.status === 'active' && (this.userDetails.permissions.printManifest === true|| agentConfirmation)) {
      const headerList = ['Passenger','Seat Number', 'Passenger Address' ,'Phone', 'Next of Kin','NOK Address','NOK Phone']
      const objArray = [];
      let passenger = '';
      let nok = '';
      let nokAddress = '';
      let nokPhone = '';
      let passengerAddr = '';
      trip.paidPassengers.forEach(req => {
        passenger = req.passengerName;
        passengerAddr = req.passengerAddress;
        nok = req.nokName
        nokAddress = req.nokAddress
        nokPhone = req.nokPhone
        passenger = passenger.replace(/,/g, ';')
        passengerAddr = passengerAddr.replace(/,/g, ';')
        nok = nok.replace(/,/g, ';')
        nokAddress = nokAddress.replace(/,/g, ';')
        nokPhone = nokPhone.replace(/,/g, ';')
        let oneRequest = {
          'Travel Date': new Date(Number(req.tripDate)).toDateString(),
          'Passenger': passenger,
          'Seat Number' : req.seatNumber,
          'Passenger Address' : passengerAddr,
          'Phone' : req.passengerPhone,
          'Next of Kin' : nok,
          'NOK Address' : nokAddress,
          'NOK Phone' : nokPhone,
          // 'LogAgg Premium': this.getOther(req.amountPaid,req.providerCost,req.excessInsurance).toFixed(2),
          // 'Net Provider Payout': this.payable(req.providerCost).toFixed(2)
        }
        objArray.push(oneRequest)
      })

      if(objArray.length){
        const data = this.ConvertToCSV(objArray,headerList);
        const fileName = trip.originStation+'_'+trip.tripTitle+'_'+trip.originStationAddress+'_'+trip.vehicleReg+ '_'+new Date(trip.tripDate).toDateString()+ '_'+new Date(trip.tripDate).toLocaleTimeString();
        this.downloadFile(data,fileName)
      }
    } else {

      this.notificationService.warn('!! Permission Denied');
    }

    }

    ConvertToCSV(objArray, headerList) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = 'S.No,';
      for (let index in headerList) {
       row += headerList[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
       let line = (i+1)+'';
       for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
       }
       str += line + '\r\n';
      }
      return str;
     }

    downloadFile(csvData, filename) {
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }

    selectTrip(trip){
      this.dataService.currentBookingTrip = trip;
      this.dataService.currentSelectedTrip.next(trip);
    }

    open( trip) {
      this.currentTrip = trip;
      this.dataService.currentBookingTrip = trip;
      this.dataService.currentSelectedTrip.next(trip);

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "60%";
      dialogConfig. panelClass ='my-custom-dialog-class';


      this.dialog.open(BookTripComponent, dialogConfig)

    }

    // selectTrip(trip){
    //   this.dataService.currentBookingTrip = trip;
    //   this.dataService.currentSelectedTrip.next(trip);
    // }


  startDateChange($event){
    if ($event) {
      this.tripList = this.originalTripList;
      this.reportStartTime = new Date($event.value).valueOf();
      const sortedTrip = this.tripList.filter(el=> {
        return (el.triptDate> this.reportStartTime) && (el.tripDate< this.reportEndTime)
      })
      this.tableData = sortedTrip;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;

    }
  }
  endDateChange($event){
    if ($event){
      this.tripList = this.originalTripList;
      this.reportEndTime = new Date($event.value).valueOf() + (24 * 60 * 60 * 1000);
      const sortedTrip = this.tripList.filter(el=> {
        return (el.tripDate> this.reportStartTime) && (el.tripDate< this.reportEndTime)
      })
      this.tableData = sortedTrip;
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  addTrip(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig. panelClass ='my-custom-dialog-class';
    this.dialog.open(AddTripComponent, dialogConfig)
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
