import { Component, OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';

import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-fleet-trips',
  templateUrl: './fleet-trips.component.html',
  styleUrls: ['./fleet-trips.component.scss']
})
export class FleetTripsComponent implements OnInit,AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  displayedColumns = ['vehicle_reg','total_trips', 'total_passengers', 'total_income'];

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 10;

  constructor(private dataService: DataServicesService) { }

  ngOnInit(): void {

    this.dataService.vehicleAnalytics.subscribe(data=> {
      if(data){
        this.dataSource = new MatTableDataSource(data);
        this.dataLength = data.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;


      }
    })
  }

  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
