<div class="example-header mt-2">
  <div class="row">
    <div class="col-2">
      <button mat-button mat-raised-button  (click)="getCountGraph()" *ngIf="type==='amount'" class="statusbtn">Number</button>
    <button mat-button mat-raised-button  (click)="getRevenueGraph()" *ngIf="type==='count'" class="statusbtn">Amount</button>
    </div>

    <div class="col-4 ml-1">
      <mat-form-field class="text-full-width-1">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate matInput placeholder="Start date" (dateChange)="startDateChange($event)">
          <input matEndDate matInput placeholder="End date" (dateChange)="endDateChange($event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="col-4">
      <form>
        <mat-form-field appearance="standard" class="text-full-width">
          <mat-label>Select Park</mat-label>
          <mat-select (selectionChange)="routeChange($event)"  multiple>
            <mat-option *ngFor="let route of availableOrigins"   name="routes" [value]="route">{{route}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="col-1">
      <button mat-menu-item (click)="originDownload()" ><mat-icon>download</mat-icon></button>
    </div>
  </div>
</div>

<!-- <div class="mb-4 ml-4" > -->
  <div class="mb-4 ml-4">
    <div style="display: block">
      <canvas baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [chartType]="barChartType"

        >
      </canvas>
    </div>
  </div>
<!-- </div> -->

