import { AfterViewInit, Component, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Subscription } from 'rxjs';

import { DataServicesService } from 'src/app/services/data-services.service';

// import {FormControl,} from '@angular/forms';
import { PrintService } from 'src/app/services/print.service';
// import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-bookings-table',
  templateUrl: './bookings-table.component.html',
  styleUrls: ['./bookings-table.component.scss']
})
export class BookingsTableComponent implements AfterViewInit, OnInit,OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 10;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'bookingDate',
    'bookingId',
    'passengerName',
    'tripTitle',
    'originStation',
    'vehicleType',
    'cost',
    'print'
  ];

  private bookingSubscription$: Subscription;
  reportStartTime = 0;
  reportEndTime = Date.now();
  constructor( private dataService: DataServicesService, public printService: PrintService) { }

  ngOnInit() {
    // this.spinner.show();
    this.bookingSubscription$ = this.dataService.bookings.subscribe(payments => {
      // console.log(payments)
      if(payments){
        const tableData = [];
        for (const payment in payments){
          if (payments.hasOwnProperty(payment)){
            const onePayment = payments[payment];
            const bookingDate1 = 'bookingDate';
            const bookingDate = onePayment[bookingDate1];
            const bookingId1 = 'bookingId';
            const bookingId = onePayment[bookingId1];

            const passengerName1 = 'passengerName';
            const passengerName = onePayment[passengerName1];

            const tripTitle1 = 'tripTitle';
            const tripTitle = onePayment[tripTitle1];

            const originStation1 = 'originStationAddress';
            const originStation = onePayment[originStation1];

            const vehicleType1 = 'vehicleType';
            const vehicleType = onePayment[vehicleType1];
            // console.log(vehicleType)

            const vehicleReg1 = 'vehicleReg';
            const vehicleReg = onePayment[vehicleReg1];

            const amountPaid1 = 'amountPaid';
            const amountPaid = onePayment[amountPaid1];

            const tripDate1 = 'tripDate';
            const tripDate = onePayment[tripDate1];

            const fleetId1 = 'fleetId';
            const fleetId = onePayment[fleetId1];

            const paymentMode1 = 'paymentMode';
            const paymentMode = onePayment[paymentMode1];

            const agencyPayment1 = 'agencyPayment';
            const agencyPayment = onePayment[agencyPayment1];
            const agency1 = 'agency';
            const agency = onePayment[agency1];

            const tableEntry = {
              bookingDate,
              bookingId,
              passengerName,
              tripTitle,
              originStation,
              vehicleType,
              vehicleReg,
              amountPaid,
              tripDate,
              paymentMode,
              agencyPayment,
              agency,
              fleetId

            };
            // console.log(tableEntry)
            tableData.push(tableEntry);
          }

        }
        this.tableData  = tableData;
        this.dataSource = new MatTableDataSource(tableData);
        this.dataLength = tableData.length;
        this.dataSource.paginator = this.paginator;
        this.dataService.sortedBookingTableData = this.tableData;
        // this.spinner.hide();
      }

    })
  }

  public ngOnDestroy(): void {
    this.bookingSubscription$.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.tableData){
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openBooking(booking){
    console.log(booking);
  }
  onPrintInvoice(booking) {
    // console.log(booking)
    const invoiceIds = [booking.bookingId];
    this.printService
      .printDocument('invoice', invoiceIds);
  }

  startDateChange($event){
    if ($event) {
      this.reportStartTime = new Date($event.value).valueOf();
    }
  }

  endDateChange($event){
    if ($event){
      this.reportEndTime = new Date($event.value).valueOf() + (24 * 60 * 60 * 1000);
      if(this.reportEndTime && this.reportEndTime){
        this.dataService.bookingsF(this.reportStartTime,this.reportEndTime)
      }
    }
  }
}
