
  <div class="row p-flex-jc-sb">
    <div class="col">
     <h3 class="mat-h2 title-text">Expense Record</h3>
    </div>
    <div class="col date-align">
     <mat-form-field >
       <mat-label>Select Date</mat-label>
       <mat-date-range-input [rangePicker]="picker">
         <input matStartDate placeholder="Start date"  (dateChange)="startDateChange($event)">
         <input matEndDate placeholder="End date"  (dateChange)="endDateChange($event)">
       </mat-date-range-input>
       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
       <mat-date-range-picker #picker></mat-date-range-picker>
     </mat-form-field>
    </div>
  </div>


<mat-table #table [dataSource]="dataSource" *ngIf="dataSource">

  <ng-container matColumnDef="entry_date">
    <mat-header-cell *matHeaderCellDef> Entry Date </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.extryDate|date:'mediumDate'}}
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expense_date">
    <mat-header-cell *matHeaderCellDef> Expense Date </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.expenseDate|date:'mediumDate'}}
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="vehicle">
    <mat-header-cell *matHeaderCellDef> Vehicle </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.vehicleReg}}({{element.fleetId}})
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="manager">
    <mat-header-cell *matHeaderCellDef> Manager </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.requesterName}} <br> ({{element.expenseStation}})
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expense_category">
    <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.expenseType}}
       </mat-cell>
  </ng-container>

  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.expenseDescription}}
       </mat-cell>
  </ng-container>
  <!-- Position Column -->
  <ng-container matColumnDef="expense_amount"  class="right" >
    <mat-header-cell *matHeaderCellDef class="mat-column-" > Amount </mat-header-cell>
    <mat-cell *matCellDef="let element"  class="mat-column-">

        N{{element.expenseAmount|number}}


       </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
[pageSize]="7"
[pageSizeOptions]="[5,7,10,14,21,28]"
             [showFirstLastButtons]="true" >
</mat-paginator>
