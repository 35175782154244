import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { DataServicesService } from 'src/app/services/data-services.service';


@Component({
  selector: 'app-online-bookings',
  templateUrl: './online-bookings.component.html',
  styleUrls: ['./online-bookings.component.scss']
})
export class OnlineBookingsComponent implements OnInit {

  bookings = [];
  constructor(public dialogRef: MatDialogRef<OnlineBookingsComponent>,private dataService: DataServicesService) { }

  ngOnInit(): void {
    // console.log(this.dataService.dateOnlineBooking)
    this.bookings = this.dataService.dateOnlineBooking;
  }

  onClose() {
    this.dialogRef.close();
  }

}
