import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';

import { UUID } from 'angular2-uuid';
import { environment } from 'src/environments/environment';

// New Payment
import {Flutterwave, AsyncPaymentOptions} from 'flutterwave-angular-v3';
import * as firebase from 'firebase/compat/app';

import Swal from 'sweetalert2'
import { NotificationService } from 'src/app/services/notification.service';
import { DataServicesService } from 'src/app/services/data-services.service';
import { AuthServicesService } from 'src/app/services/auth-services.service';
import { PrintService } from 'src/app/services/print.service';

// Date formatting
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-book-trip',
  templateUrl: './book-trip.component.html',
  styleUrls: ['./book-trip.component.scss']
})
export class BookTripComponent implements OnInit {

  public bookingId = UUID.UUID().split('-')[0] + '-' + Date.now() ;
  public paymentId =  environment.dbPrefix + UUID.UUID();
  public genderList = ['Male', 'Female'];
  public selectedGender = '';
  public tripDetails;
  public uid: string;
  booking;
  public bookings = [];
  public checkOut = false;
  public addMore = true;
  public checked = false;
  public totalPayable = 0;
  seatNumber;

  public selectSeatIndex;
  public oldSelectedSeatIndex;
  public txref;
  public isUpdate: boolean;
  public managerPermission;

  public loadSpinner = false;

  customerDetails = { name: '', email: '', phone_number: '08100000000'};

  customizations = {title: 'Arrive NG (By Applicentric)', description: 'Booking(Trip) Payment'}

  paymentData: AsyncPaymentOptions ;

  commissionType = 'Percentage';
  onlineFixedCommission = 0;
  onlineFixedCommission2 = 0;
  officeFixedCommission = 0;
  officeFixedCommission2 = 0;
  userData;

  form: FormGroup = new FormGroup({
    $key: new FormControl(null),
    passengerName: new FormControl('', Validators.required),
    passengerEmail: new FormControl('', Validators.email),
    passengerPhone: new FormControl('', [Validators.required, Validators.minLength(10),Validators.maxLength(15)]),
    passengerAddress: new FormControl(''),
    passengerGender: new FormControl(''),
    nokName: new FormControl('', Validators.required),
    nokPhone: new FormControl('', [Validators.required, Validators.minLength(10),Validators.maxLength(15)]),
    nokAddress: new FormControl('',Validators.required),
    seatNumber:new FormControl('', Validators.required)

  });

  constructor(public dialogRef: MatDialogRef<BookTripComponent>, private notificationService: NotificationService, private dataService: DataServicesService, private authService: AuthServicesService, public printService: PrintService,private flutterwave: Flutterwave) { }

  ngOnInit(): void {
    this.dataService.getSingleTrip(this.dataService.currentBookingTrip.id).subscribe(tripDetails => {
      if(tripDetails){
        this.tripDetails = tripDetails.payload.data();
        this.booking = this.newBooking();
        this.managerPermission = this.authService.userDetails.permissions;
        this.customerDetails.name = this.authService.userDetails.username;
        this.customerDetails.email = this.authService.userDetails.email;


        if (this.tripDetails.commissionType === 'Fixed') {
          this.commissionType = 'Fixed';
          this.onlineFixedCommission = this.tripDetails.onlineFixedCommission;
          this.onlineFixedCommission2 = this.tripDetails.onlineFixedCommission2;
          this.officeFixedCommission = this.tripDetails.officeFixedCommission;
          this.officeFixedCommission2 = this.tripDetails.officeFixedCommission2;
        } else {
          this.commissionType = 'Percentage';
        }
      }

    });

    this.authService.userData.subscribe(userData => {
      this.userData = userData
      this.managerPermission = this.userData.permissions;
      this.customerDetails.name = this.userData.username;
      this.customerDetails.email = this.userData.email;
     console.log(this.managerPermission.cashBooking);

    })

  }

  onSubmit() {
    if (this.form.valid) {
      this.booking.passengerName = this.form.value.passengerName;
      this.booking.passengerPhone = this.form.value.passengerPhone;
      this.booking.passengerAddress = this.form.value.passengerAddress;
      this.booking.passengerGender = this.form.value.passengerGender;
      this.booking.passengerEmail = this.form.value.passengerEmail
      this.booking.nokName = this.form.value.nokName;
      this.booking.nokPhone = this.form.value.nokPhone;
      this.booking.nokAddress = this.form.value.nokAddress;
      this.notificationService.success(':: Passengers Details Added');
    }
  }

  onClose() {
    this.form.reset();
    this.booking = this.newBooking();
    this.dialogRef.close();
  }

  onClear() {}

  cancelBooking() {
    this.booking = this.newBooking();
    this.isUpdate = false;
  }

  newBooking() {
    this.isUpdate = false;
    return {
      id: this.bookingId,
      uid: this.userData.id,
      originStation : this.tripDetails.originStation,
      tripTitle: this.tripDetails.tripTitle,
      tripStart: 0,
      tripStop: 0,
      driverName : this.tripDetails.driverName,
      vehicleType : this.tripDetails.vehicleType,
      vehicleReg : this.tripDetails.vehicleReg,
      tripDate: this.tripDetails.tripDate,
      bookingDate : Date.now(),
      tripCost : this.tripDetails.tripCost,
      route: this.tripDetails.route,
      nokName: '',
      nokAddress: '',
      nokPhone: '',
      passengerName: '',
      passengerEmail: '',
      passengerPhone: '',
      passengerAddress: '',
      bookingStatus: 'booked',
      paymentMode: '',
      amountPaid: 0,
      paymentId: this.paymentId,
      paymentDate: 0,
      tripId: this.tripDetails.id,
      statusLog: [],
      passengerGender: '',
      subaccount_id: this.tripDetails.subaccount_id,
      stationId: this.tripDetails.stationId || '',
      originStationAddress: this.tripDetails.originStationAddress ? this.tripDetails.originStationAddress: this.tripDetails.originStation,
      stationImage: this.tripDetails.image,
      color: this.tripDetails.color || '#FFFFFF',
      seatNumber: '',
      seatIndex: 0,
      officePayment: false,
      officeMode: '',
      officeCollectorName: '',

      officeCommision: Math.round(this.tripDetails.tripCost * this.tripDetails.officeCommision * 100) / 100,
      commissionType: this.commissionType ,
      officeFixedCommission: 0,
      onlineFixedCommission: this.onlineFixedCommission,
      officeFixedCommission2: 0,
      onlineFixedCommission2: this.onlineFixedCommission2,
      officeCommission2: 0,
      onlineCommission: Math.round(this.tripDetails.tripCost * this.tripDetails.onlineCommision * 100) / 100,
      onlineCommission2: 0,
      fleetId: this.tripDetails.fleetId || this.tripDetails.vehicleReg
    };
  }

  unAvailableSeat() {
    this.notificationService.success('!! Seat not available for booking');
  }
  bookedSeat() {
   this.notificationService.success('!! Seat already booking');
  }
  selectedSeat(){
    this.notificationService.success('!! Already Selected');
  }

  selectSeat(i) {
    if (this.oldSelectedSeatIndex) {
      this.tripDetails.vehicleType.seatsLayout[this.oldSelectedSeatIndex].status = 'Available';
      this.tripDetails.vehicleType.seatsLayout[this.oldSelectedSeatIndex].passengerName = '';
      this.tripDetails.vehicleType.seatsLayout[i].status = 'Selected';
      this.tripDetails.vehicleType.seatsLayout[i].passengerName = this.booking.passengerName;
      this.booking.seatNumber = this.tripDetails.vehicleType.seatsLayout[i].seatNumber;
      this.booking.seatIndex = i;
      this.oldSelectedSeatIndex = i;
      this.seatNumber = this.tripDetails.vehicleType.seatsLayout[i].seatNumber;

    } else {
      this.tripDetails.vehicleType.seatsLayout[i].status = 'Selected';
      this.tripDetails.vehicleType.seatsLayout[i].passengerName = this.booking.passengerName;
      this.oldSelectedSeatIndex = i;
      this.booking.seatNumber = this.tripDetails.vehicleType.seatsLayout[i].seatNumber;
      this.booking.seatIndex = i;
      this.seatNumber = this.tripDetails.vehicleType.seatsLayout[i].seatNumber;

    }

    console.log('Selected - ',this.tripDetails.vehicleType.seatsLayout[i].seatNumber)
  }
  cardCol(size){
    // console.log(this.tripDetails.vehicleType)
    const col = 'col-'+Math.round(size)+" text-center";

    // console.log(col.toString())
    return col.toString() ;
  }

  addMorePassenger2(){
    this.form.reset();
    this.bookingId = UUID.UUID().split('-')[0] + '-' + Date.now(); // UUID.UUID();
    this.booking = this.newBooking();
    this.oldSelectedSeatIndex = null;
    console.log(this.bookings);
    if (this.bookings.length >= (this.tripDetails.totalPassengers - this.tripDetails.paidPassengers.length)) {
    this.addMore = false;
    this. checkOut = true;
    this.bookings.forEach( (booking, index) => {
      if (index === 0) {
        this.txref = booking.id;
      } else {
        this.txref = this.txref + '_' + booking.id;
      }
    });
    this.notificationService.success('!! No more available seat');
    } else {
      this.addMore = true;
     this. checkOut = false;
    }
  }

  noMoreAdding(){
    this.addMore = false;
    this. checkOut = true;
  }

  addMorePassenger() {
    this.onSubmit();
    this.bookings.push(this.booking);
    this.form.reset();
    this.bookingId = UUID.UUID().split('-')[0] + '-' + Date.now(); // UUID.UUID();
    this.booking = this.newBooking();
    this.oldSelectedSeatIndex = null;
    console.log(this.bookings);
    if (this.bookings.length >= (this.tripDetails.totalPassengers - this.tripDetails.paidPassengers.length)) {
    this.addMore = false;
    this. checkOut = true;
    this.bookings.forEach( (booking, index) => {
      if (index === 0) {
        this.txref = booking.id;
      } else {
        this.txref = this.txref + '_' + booking.id;
      }
    });
    this.notificationService.success('!! No more available seat');
    }
  }

  checkOutBooking() {
    this.onSubmit();
    this.bookings.push(this.booking);
    this.bookingId = UUID.UUID().split('-')[0] + '-' + Date.now();//UUID.UUID();
    this.booking = this.newBooking();
    this.oldSelectedSeatIndex = null;
    this.addMore = false;
    this. checkOut = true;
    this.bookings.forEach( (booking, index) => {
      this.totalPayable = this.tripDetails.tripCost * this.bookings.length;
      if (index === 0) {
        this.txref  = booking.id;
      } else {
        this.txref = this.txref + '_' + booking.id;
      }

    });
    //console.log(this.bookings);
  }

  openForm() {
    this.addMore = true;
    this.checkOut = false;
   }

   deleteBookingItem(item,index) {
     this.bookings.splice(index, 1);
     this.tripDetails.vehicleType.seatsLayout[item.seatIndex].status = 'Available';
     this.tripDetails.vehicleType.seatsLayout[item.seatIndex].passengerName = '';
   }

   editBookingItem(item,index){
     // console.log(item)
     this.bookings.splice(index,1);
     this.booking = item;
     this.form.value.passengerName = item.passengerName;
     this.form.value.passengerPhone =  item.passengerPhone;
     this.form.value.passengerAddress =  item.passengerAddress;
     this.form.value.passengerGender = item.passengerGender;
     this.form.value.passengerEmail = item.passengerEmail;
     this.form.value.nokName = item.nokName;
     this.form.value.nokPhone = item.nokPhone;
     this.form.value.nokAddress = item.nokAddress;
     this.form.value.seatNumber = item.seatNumber;

     this.oldSelectedSeatIndex = item.seatIndex;
     this.openForm();
    }


    async payCash() {


      Swal.fire({
        title: 'Cash Payment Received',
        text: "You have received Cash payment from the passenger for full amount",
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor:  '#4d9301',
        width:'15%',
        confirmButtonText: 'Confirm',
        background:'#d6dde0',
      }).then(async (result) => {
        if (result.isConfirmed) {

          // this.util.openLoader();
          this.loadSpinner = true;
          this.isUpdate = true;
          this.prePay('Cash');
          const accessToken = await firebase.default.auth().currentUser.getIdToken();
          this.dataService.cashPayment(this.bookings, accessToken).subscribe(
            async (res) => {
            // console.log(res);
            //  this.util.confirmDialogPassingparameter(res['paymentStatus'], res['message']);
             this.notificationService.success('::'+res['paymentStatus']+' - '+res['message'] );
             if(res['paymentStatus']==='Success'){
              // this.onPrintInvoice();
             }
             this.loadSpinner = false;
             this.isUpdate = false;
             this.onClose();
            });
        }})


     }

     async payPOS() {

      Swal.fire({
        title: 'POS Payment Received',
        text: "You have received POS payment from the passenger for full amount",
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor:  '#4d9301',
        width:'15%',
        confirmButtonText: 'Confirm',
        background:'#d6dde0',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadSpinner = true;
          this.isUpdate = true;
          this.prePay('POS');
          const accessToken = await firebase.default.auth().currentUser.getIdToken();
          this.dataService.cashPayment(this.bookings, accessToken).subscribe(
            async (res) => {
              // console.log(res);
            this.notificationService.success('::'+res['paymentStatus']+' - '+res['message'] );
            if(res['paymentStatus']==='Success'){
              // this.onPrintInvoice();
            }
              this.loadSpinner = false;
              this.isUpdate = false;
            //  this.util.closeLoading();
            //  if (this.util.bluetoothPrinterConnected) {
            //   await this.printViaBluetooth();
            // }
            this.onClose()
            });
        }
      })

     }

     prePay(officeMode?) {

      try {
        const count = [];
        const bookingBody  =  [];
        this.bookings.forEach(async booking => {
        if (officeMode) {
          booking.officeMode = officeMode;
        }
        bookingBody.push(booking)
        const b1 = await this.dataService.create('booking', booking);
        count.push(b1);
        console.log();
        if (count.length === this.bookings.length) {
        }
       });

       } catch (e) {
          console.log(e);
          this.notificationService.warn('!! Error making bookings');
       }
     }

     payOnline(){

     }

     payViaPromise() {
      this.prePay();
      this.customerDetails.name = this.authService.userDetails.username;
      this.customerDetails.email = this.authService.userDetails.email;
      this.customerDetails.phone_number = this.bookings[0].passengerPhone;
      let subaccounts = [];
      if (this.commissionType === 'Fixed') {
        if (this.tripDetails.subaccount_id2) {
          if (this.tripDetails.subaccount_id2.length > 2  && this.onlineFixedCommission2) {
            subaccounts = [
              {
                id: this.tripDetails.subaccount_id,
                transaction_charge_type: 'flat_subaccount',
                transaction_charge: (this.tripDetails.tripCost - this.onlineFixedCommission - this.onlineFixedCommission2) * this.bookings.length
              },
              {
                id: this.tripDetails.subaccount_id2,
                transaction_charge_type: 'flat_subaccount',
                transaction_charge: this.onlineFixedCommission2 * this.bookings.length
              }
            ];

          } else {
            subaccounts = [
              {
                id: this.tripDetails.subaccount_id,
                transaction_charge_type: 'flat_subaccount',
                transaction_charge: (this.tripDetails.tripCost - this.onlineFixedCommission - this.onlineFixedCommission2) * this.bookings.length
              }
            ];
          }

        } else {
          subaccounts = [
            {
              id: this.tripDetails.subaccount_id,
              transaction_charge_type: 'flat_subaccount',
              transaction_charge: (this.tripDetails.tripCost - this.onlineFixedCommission - this.onlineFixedCommission2) * this.bookings.length
            }
          ];
        }


      } else {
        subaccounts = [{id: this.tripDetails.subaccount_id}];
      }

      this.paymentData = {
        public_key: this.dataService.flutterwaveKey,
        tx_ref: this.paymentId,
        amount: this.totalPayable,
        currency: 'NGN',
        payment_options: 'card,ussd',
        customer: this.customerDetails,
        customizations: this.customizations,
        subaccounts,
      };

      // console.log(this.paymentData)
      this.flutterwave.asyncInlinePay(this.paymentData).then(
        (response) => {
          console.log('Promise Res' , response);
          this.flutterwave.closePaymentModal(5);
          if(response['status'] === 'successful') {
            this.confirmPayment();
          }
        }
      );
    }


   confirmPayment( ) {
    // this.ngZone.run(() => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
      },
      buttonsStyling: true,
      heightAuto: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Payment Successful',
      text: 'Please be at the park at least 30 minutes before departure with your booking reference',
      confirmButtonText: 'Close',
      confirmButtonColor: '#4d9301',
      reverseButtons: false,
      heightAuto: false
    }).then(async () => {
      this.booking = this.newBooking();
        // this.downloadTicketPdf();
        this.onPrintInvoice()


        this.onClose()


    });
  // });
  }


  onPrintInvoice() {

    this.bookings.forEach(booking => {
      const invoiceIds = [booking.bookingId];
      this.printService
        .printDocument('invoice', invoiceIds);
    })
    // console.log(booking)

  }

downloadTicketPdf() {
  console.log('Paid', this.bookings.length)
  this.bookings.forEach(booking => {

    const time_1 = moment(new Date(booking.tripDate).toISOString());
    const time_2 = moment(new Date().toISOString());
    const tripDate = time_1.tz('Africa/Lagos').format('ddd, Do MMMM YYYY') // new Date(booking.tripDate).toDateString();
    const tripTime = time_1.tz('Africa/Lagos').format('h:mm a'); // new Date(booking.tripDate).toLocaleTimeString();
    const paymentDate = time_2.tz('Africa/Lagos').format('ddd, Do MMMM YYYY, h:mma')// new Date(booking.paymentDate).toLocaleString();


    const ticketDefinition = {
      watermark: {text: 'Arrive NG', color: 'green', opacity: 0.2, bold: true},
      content : [
        {
          columns: [
            {
              text: booking.originStation,
              style: 'header'
            },
            {
              text: paymentDate,
              alignment: 'right'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Name:',
              alignment: 'left',
              width: '30%'
            }, {
              text: booking.passengerName,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Phone:',
              alignment: 'left',
              width: '30%'
            }, {
              text: booking.passengerPhone,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Park Address:',
              alignment: 'left',
              width: '30%'
            }, {
              text: booking.originStationAddress,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Route:',
              alignment: 'left',
              width: '30%'
            }, {
              text: booking.tripTitle,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Seat :',
              alignment: 'left',
              width: '30%'
            }, {
              text: booking.seatNumber,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Date :',
              alignment: 'left',
              width: '30%'
            }, {
              text: tripDate,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Time :',
              alignment: 'left',
              width: '30%'
            }, {
              text: tripTime,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Cost :',
              alignment: 'left',
              width: '30%'
            }, {
              text: `N ${booking.tripCost}`,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Booking ID :',
              alignment: 'left',
              width: '30%'
            }, {
              text: booking.id,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Agent :',
              alignment: 'left',
              width: '30%'
            }, {
              text: this.authService.userDetails.username,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Others :',
              alignment: 'left',
              width: '30%'
            }, {
              text: this.tripDetails.features,
              alignment: 'left',
              style: 'details',
              width: '70%'
            }
          ]
        },
        {
          text: this.tripDetails.luggageMessage || ''
        },
        {
          text: '\n\nTHANKS FOR YOUR PATRONAGE',
          alignment: 'center',
        }

      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        details: {
          bold: true,
          fontSize: 12,
          color: 'black'
        }

      }

    };

    });

}


}
