import { Component, OnInit,AfterViewInit ,ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataServicesService } from 'src/app/services/data-services.service';

@Component({
  selector: 'app-annual-booking-table',
  templateUrl: './annual-booking-table.component.html',
  styleUrls: ['./annual-booking-table.component.scss']
})
export class AnnualBookingTableComponent implements OnInit,AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;


  bookingRevenue = [];
  bookingCount = [];

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['trip_date', 'trip_count', 'trip_revenue'];

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 10;
  constructor(private dataService: DataServicesService) { }

  ngOnInit(): void {

    this.dataService.annualBookingRevenueData_.subscribe(rec  =>{
      this.bookingRevenue = rec;
    });
    this.dataService.annualBookingCountData_.subscribe(rec=>{
      this.bookingCount = rec;
      this.tableData = [];
      rec.forEach((val,index)=>{
        this.tableData.push({
          trip_date:val[0],
          trip_revenue:this.bookingRevenue[index][1],
          trip_count: val[1]
        })
      })
      // console.log(this.tableData)
      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;


    })



    // this.tableData  = this.dailyOrders;
    // this.dataSource = new MatTableDataSource(this.dailyOrders);
    // this.dataLength = this.dailyOrders.length;
    // this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
