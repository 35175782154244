<div class="container-fluid headerfixed">
  <app-header></app-header>
</div>
<div class="container-fluid contentone">
  <div class="row sidebarrow">
      <app-side showFleet=true></app-side>
  </div>
</div>

<div class="container-fluid body-card">

  <div class="row">
    <div class="col-xl-3 col-lg-6 col-md-12 col-sm-12 mt-4" *ngFor="let item of miniCardData">
      <div class="card card-counter bg-gradient-secondary" [ngStyle]="cardStyle(item.color)">
        <div class="card-body">
          <h3 class="card-title text-white" >{{item.title}}</h3>
          <div class="row">
            <div class="col-3 pl-0 pr-0">
              <mat-icon class="mt-3 mb-0 text-white icon">{{item.icon}}</mat-icon>
            </div>
            <div class="col-9 text-center pl-0 pr-0" >
                <div class="mt-4 mb-0 text-white">
                  <h2 class="mb-0" *ngIf="item.isCurrency">{{item.value | currency:'N ':'symbol':'0.0' }}</h2>
                  <h2 class="mb-0" *ngIf="!item.isCurrency">{{item.value | number}}</h2>
                  <p class="text-white mt-1" >Aggregate Value</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  </div>


  <div class="row row-eq-height ">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 mt-4 mb-4">
      <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
        <div class="card-body">
          <!-- <div class="row">
            <div class="col-6">
              <h3   class="card-title text-dark">Vehicle </h3>
            </div>
            <div class="col-6 text-right">
              <ion-button mat-icon-button class="text-right"  aria-label="Toggle menu" (click)="openAddVehicle()">
                <mat-icon>add</mat-icon>
              </ion-button>
            </div>
          </div> -->
          <app-fleet-table></app-fleet-table>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4 mb-4">
      <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
        <div class="card-body">
          <h3 class="card-title text-dark" >Vehicles Finance Outlook </h3>
          <app-vehicles-analytics-chart></app-vehicles-analytics-chart>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4 mb-4">
      <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
        <div class="card-body">

          <!-- <div class="row">
            <div class="col-6">
              <h3   class="card-title text-dark">Vehicle Type </h3>
            </div>
            <div class="col-6 text-right">
              <ion-button mat-icon-button class="text-right"  aria-label="Toggle menu" (click)="openAddVehicleType()">
                <mat-icon>add</mat-icon>
              </ion-button>
            </div>
          </div> -->

          <app-vehicle-type-table></app-vehicle-type-table>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4 mb-4">
      <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
        <div class="card-body">
          <h3 class="card-title text-dark" >Fleet Trips </h3>
          <app-fleet-trips></app-fleet-trips>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4 mb-4">

      <div class="card card-counter bg-gradient-secondary"  style="height: 100%">
        <div class="card-body">
          <!-- <h3 class="card-title text-dark" >Drivers </h3> -->
          <app-driver-table></app-driver-table>
        </div>
      </div>

    </div>

  </div>
</div>





