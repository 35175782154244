import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tripmanagement',
  templateUrl: './tripmanagement.component.html',
  styleUrls: ['./tripmanagement.component.scss']
})
export class TripmanagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
