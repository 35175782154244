import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { DataServicesService } from 'src/app/services/data-services.service';
import { AuthServicesService } from 'src/app/services/auth-services.service';

import { NgForm } from '@angular/forms';

import { MatDialog, MatDialogConfig} from '@angular/material/dialog'
import { ManagerBookingComponent } from 'src/app/modals/manager-booking/manager-booking.component';
import { TerminalBookingsComponent } from 'src/app/modals/terminal-bookings/terminal-bookings.component';

@Component({
  selector: 'app-agency-payments',
  templateUrl: './agency-payments.component.html',
  styleUrls: ['./agency-payments.component.scss']
})
export class AgencyPaymentsComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{}>;
  dataLength: number;
  tableData = [];
  pageSize = 5;

  reportStartTime = 0;
  reportEndTime = Date.now();

  financialPermissions = false;


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'date',
    'cashBooking',
    'cashAmount',
    'posBooking',
    'posAmount',
    // 'totalBooking',
    'totalAmount',
    // 'commisionDue',
    'btnView',
    'btnTerminalView',
    'btnDownload'

    ];

    sortedAllManagerPayments = [];
    allManagerPayments = [];
    allManagerBooking = [];
    stationData;

  constructor(private dataService: DataServicesService, private authService: AuthServicesService, private dialog: MatDialog){

  }
  ngOnInit() {
    this.dataService.stationData.subscribe(stationData =>{
      this.stationData = stationData;
    })
    const viewFinancials = 'viewFinancials';
    const permissions = 'permissions';
    this.authService.userData.subscribe(userData => {
      if(userData){
        this.financialPermissions = userData[permissions].viewFinancials;
        // console.log(userData[permissions].viewFinancials)
        if (this.financialPermissions) {
          this.dataService.allAgencyBooking.subscribe(allManagerBooking => {
            // console.log(allManagerBooking)
            if (allManagerBooking.length) {
              this.allManagerBooking = allManagerBooking;
              this.sortAllManagerBooking(allManagerBooking);
            } else{
              console.log('Called')
              this.dataService.getAllAgentBooking()
            }
          });

        }
      }
    })

  }

  ngAfterViewInit() {
    if (this.sortedAllManagerPayments){
      this.dataSource = new MatTableDataSource(this.sortedAllManagerPayments);
      this.dataLength = this.tableData.length;
      this.dataSource.paginator = this.paginator;
    }

  }


  sortAllManagerBooking(allManagersBooking){
    // const sortedPayment = {};
    // for (const booking in allManagersBooking) {
    //   if (allManagersBooking.hasOwnProperty(booking)) {
    //     const oneBooking = allManagersBooking[booking];
    //     const oneBookingDate = new Date(parseInt(oneBooking.paymentDate, 10));
    //     const dateComponents = oneBookingDate.toDateString().split(' ');
    //     // console.log(dateComponents)
    //     const paymentDay = dateComponents[0] + ', ' + dateComponents[1] + ' ' + dateComponents[2] + ' ' + dateComponents[3];
    //     const mode = oneBooking.paymentMode;
    //     const paymentAmount = Number(oneBooking.amountPaid);

    //     const day = 'day';
    //     const cash = 'cash';
    //     const cashCount = 'cashCount';
    //     const pos = 'pos';
    //     const posCount = 'posCount';
    //     const bookings = 'bookings';
    //     const total = 'total';

    //     const tripCost = oneBooking['tripCost'];
    //     const commissionType = 'commissionType'
    //     const commission = 'commission';
    //     let arriveCommission = 0;

    //     if(oneBooking[commissionType]==='Fixed'){
    //       arriveCommission = oneBooking['officeFixedCommission'] || oneBooking['onlineFixedCommission']

    //     } else {
    //       if((paymentAmount===tripCost) ||( oneBooking.arriveDiscount)){
    //         arriveCommission = oneBooking['officeCommision'] || tripCost * this.stationData.officeCommision
    //       } else {
    //         arriveCommission = oneBooking['officeCommision'] || paymentAmount * this.stationData.officeCommision
    //       }
    //     }




    //     oneBooking.day = paymentDay;
    //     if (sortedPayment[paymentDay]) {
    //       sortedPayment[paymentDay].bookings.push(oneBooking);
    //       sortedPayment[paymentDay].total = sortedPayment[paymentDay].total + paymentAmount;
    //       sortedPayment[paymentDay].commission = Number(sortedPayment[paymentDay].commission) + Number(arriveCommission);

    //       if ( mode.startsWith('Cash Payment')) {
    //         sortedPayment[paymentDay][cash] = sortedPayment[paymentDay][cash] + paymentAmount;
    //         sortedPayment[paymentDay][cashCount] = sortedPayment[paymentDay][cashCount] + 1;
    //       } else {
    //         sortedPayment[paymentDay][pos] = sortedPayment[paymentDay][pos] + paymentAmount;
    //         sortedPayment[paymentDay][posCount] = sortedPayment[paymentDay][posCount] + 1;
    //       }
    //     } else {
    //       const bookingObject = {};
    //       bookingObject[cash] = 0;
    //       bookingObject[cashCount] = 0;
    //       bookingObject[pos] = 0;
    //       bookingObject[posCount] = 0;
    //       bookingObject[total] = paymentAmount;
    //       bookingObject[day] = paymentDay;
    //       bookingObject[bookings] = [oneBooking];
    //       bookingObject[commission]= Number(arriveCommission);

    //       if ( mode === 'Cash') {
    //         bookingObject[cash] = paymentAmount;
    //         bookingObject[cashCount] = 1;
    //       } else {
    //         bookingObject[pos] = paymentAmount;
    //         bookingObject[posCount] = 1;
    //       }
    //       sortedPayment[paymentDay] = bookingObject;
    //     }
    //   }
    // }
    this.sortedAllManagerPayments = allManagersBooking;
    this.dataSource = new MatTableDataSource(this.sortedAllManagerPayments);
    this.dataLength = this.tableData.length;
    this.dataSource.paginator = this.paginator;
  }

  startDateChange($event){
    if ($event) {
      this.reportStartTime = new Date($event.value).valueOf();
      // this.dataService.allManagerOfficePayment.subscribe(onlineTransactions => {
      //   const sortedPayment = onlineTransactions.filter(el=> {
      //     return (el['paymentDate']> this.reportStartTime) && (el['paymentDate']< this.reportEndTime)
      //   })
      //   this.sortAllManagerBooking(sortedPayment);
      // })

    }
  }

  endDateChange($event){
    if ($event){
      this.reportEndTime = new Date($event.value).valueOf() + (24 * 60 * 60 * 1000);
      // this.dataService.allManagerOfficePayment.subscribe(onlineTransactions => {
      //   const sortedPayment = onlineTransactions.filter(el=> {
      //     return (el['paymentDate']> this.reportStartTime) && (el['paymentDate']< this.reportEndTime)
      //   })
      //   this.sortAllManagerBooking(sortedPayment);
      // })
      if(this.reportEndTime && this.reportStartTime ){
        this.dataService.getAllAgentBooking(this.reportStartTime,this.reportEndTime);
      }

    }
  }

  viewBookings(bookings){
    // console.log(bookings.bookings)
  }

  open(officeBooking) {
    // this.managerGroupings(officeBooking);
    // console.log(officeBooking)
    this.dataService.currentOfficeBooking.next(officeBooking);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(ManagerBookingComponent,dialogConfig)

  }

  openTerminal(officeBooking){
    this.dataService.currentOfficeBooking.next(officeBooking);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.panelClass ='my-custom-dialog-class';
    this.dialog.open(TerminalBookingsComponent,dialogConfig)

  }

  download(officeBooking){
    const headerList = ['Passenger','Trip', 'Travel Date' ,'Vehicle', 'Agency','Payment Mode','Amount']
      const objArray = [];
      let passenger = '';
      let vehicle = '';
      let tripTitle = '';
      let manager = '';

      officeBooking[1].bookings.forEach(req => {
        passenger = req.passengerName;
        tripTitle = req.tripTitle;
        vehicle = req.vehicleReg
        manager = req.officeCollectorName || req.agency

        passenger = passenger.replace(/,/g, ';')
        tripTitle = tripTitle.replace(/,/g, ';')
        vehicle = vehicle.replace(/,/g, ';')
        manager = manager.replace(/,/g, ';')

        const oneBookingDate = new Date(req.tripDate);
        const dateComponents = oneBookingDate.toLocaleString().split(' ');
        // console.log(dateComponents)
        const tripDay = dateComponents[0] + ', ' + dateComponents[1] + ' ' + dateComponents[2] + ' ' + dateComponents[3];
        let oneRequest = {
          'Travel Date': dateComponents[1] + ' '+ new Date(Number(req.tripDate)).toDateString(),
          'Passenger': passenger,
          'Trip' : tripTitle,
          'Vehicle' : vehicle ,
          'Agency' : manager,
          'Amount' : req.amountPaid,
          'Payment Mode' : req.paymentMode,

        }
        objArray.push(oneRequest)
      })

      if(objArray.length){
        const data = this.ConvertToCSV(objArray,headerList);
        const fileName = 'Agency Booking For '+officeBooking[0];
        this.downloadFile(data,fileName)
      }

  }



  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
     row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
     let line = (i+1)+'';
     for (let index in headerList) {
      let head = headerList[index];
      line += ',' + array[i][head];
     }
     str += line + '\r\n';
    }
    return str;
   }

  downloadFile(csvData, filename) {
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }


}
