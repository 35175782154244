
<div class="small-table">
  <!-- <mat-grid-list cols="4" rowHeight="50px" class="p-flex-jc-sb">
      <mat-grid-tile colspan=2>
        <h1 class="mat-h1 justify">Trips</h1>
      </mat-grid-tile>
      <mat-grid-tile colspan=2>
        <mat-form-field appearance="fill" >
          <mat-label>Select Date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date"  (dateChange)="startDateChange($event)">
            <input matEndDate placeholder="End date"  (dateChange)="endDateChange($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list> -->

   <div class="row p-flex-jc-sb">
     <div class="col">
      <h3 class="mat-h2 title-text">Station Bookings</h3>
     </div>
     <div class="col date-align">
      <mat-form-field >
        <mat-label>Select Date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date"  (dateChange)="startDateChange($event)">
          <input matEndDate placeholder="End date"  (dateChange)="endDateChange($event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
     </div>
   </div>
  <table mat-table [dataSource]="dataSource" class="full-width-table" aria-label="Elements" *ngIf="dataSource" >
  <!-- Id Column -->
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef >Date</mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'">
      {{row[0]}}
    </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="cashBooking">
    <mat-header-cell *matHeaderCellDef [ngClass]="'th-center'">Cash#</mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-center'"> {{row[1].cashCount}}

    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="cashAmount">
    <mat-header-cell *matHeaderCellDef >Cash </mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'"> {{row[1].cash | number: '0.2-2'}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="posBooking">
    <mat-header-cell *matHeaderCellDef [ngClass]="'th-center'">POS$</mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-center'"> {{row[1].posCount}}

    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="posAmount">
    <mat-header-cell *matHeaderCellDef >POS </mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'"> {{row[1].pos | number: '0.2-2'}}
    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="commisionDue">
    <mat-header-cell *matHeaderCellDef >Commision</mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'"> {{row[1].commission| number: '0.2-2' }}
    </mat-cell>
  </ng-container> -->

  <ng-container matColumnDef="totalAmount">
    <mat-header-cell *matHeaderCellDef >Total</mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'"> {{row[1].total| number: '0.2-2' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="btnView">
    <mat-header-cell *matHeaderCellDef ></mat-header-cell>
    <mat-cell *matCellDef="let row">
        <button mat-stroked-button (click)="open(row[1])" class="btn-submit">Agent View</button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="btnTerminalView">
    <mat-header-cell *matHeaderCellDef ></mat-header-cell>
    <mat-cell *matCellDef="let row">
        <button mat-stroked-button (click)="openTerminal(row[1])" class="btn-submit-p">Terminal View</button>
    </mat-cell>
  </ng-container>


  <ng-container matColumnDef="btnDownload">
    <mat-header-cell *matHeaderCellDef ></mat-header-cell>
    <mat-cell *matCellDef="let row">
        <button mat-stroked-button (click)="download(row)" class="btn-submit"><mat-icon>download</mat-icon></button>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>

<mat-paginator paginator [length]="dataLength" [pageIndex]="0" [pageSize]="pageSize" [pageSizeOptions]="[5,10,15,20,25]" showFirstLastButtons></mat-paginator>
</div>

