<mat-toolbar class="top-bar">
  <span class="title-text" *ngIf="!isUpdate">Add Vehicle </span>
  <span class="title-text" *ngIf="isUpdate">{{dataService.openedVehicle?.fleetId}} - {{dataService.openedVehicle?.regNum}}</span>
  <span class="fill-remaining-space"></span>
  <span *ngIf="isUpdate" class="title-text"> Enlisted - {{dataService.openedVehicle?.dateEnlisted|date}} </span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>

<div class="row">
  <div class="col-6">
      <mat-dialog-content >
          <form [formGroup]="form" class="normal-form">
              <mat-grid-list cols="2" rowHeight="300px">
                  <mat-grid-tile>
                      <div class="controles-container">
                        <mat-form-field >
                          <mat-label>Registration State</mat-label>
                          <mat-select  formControlName="regState">
                            <mat-option *ngFor="let state of states; let i = index" [(value)]="states[i].name" >
                              {{state.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                          <input formControlName="fleetId" matInput placeholder="Fleet Identification" >
                          <mat-error>This field is mandatory.</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                          <input formControlName="currentDriver" matInput placeholder="Current Driver" >
                          <mat-error>This field is mandatory.</mat-error>
                        </mat-form-field>

                        <mat-form-field >
                            <mat-label>Vehicle Type</mat-label>
                            <mat-select  formControlName="vehicleType" (selectionChange)="selectVehicle($event)">
                              <mat-option *ngFor="let type of vehicleTypes; let i = index" [(value)]="vehicleTypes[i]" >
                                  {{type.vehicleModel}} - {{type.vehicleType}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                      </div>
                  </mat-grid-tile>
                  <mat-grid-tile>
                      <div class="controles-container">
                        <mat-form-field>
                          <input formControlName="regNum" matInput placeholder="Registration Number*" >
                          <mat-error>This field is mandatory.</mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input formControlName="initialCapital" matInput placeholder="Vehicle Capital" type="number">
                            <mat-error>This field is mandatory.</mat-error>
                          </mat-form-field>
                        <mat-form-field>
                            <input formControlName="owner" matInput placeholder="Vehicle Owner" >
                            <mat-error>This field is mandatory.</mat-error>
                          </mat-form-field>

                          <mat-form-field>
                              <input formControlName="totalPassengers" matInput placeholder="Passenger Capacity" type="number" [(value)]="totalPassengers" readonly>
                              <mat-error>This field is mandatory.</mat-error>
                            </mat-form-field>

                      </div>
                  </mat-grid-tile>
              </mat-grid-list>
              <div class="controles-container">
                <mat-form-field>
                  <input formControlName="importantNotes" matInput placeholder="Important Note" [(value)]="importantNotes" >
                  <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
              </div>
              <mat-grid-list cols="2" rowHeight="40px">
                  <mat-grid-tile>
                      <!-- <div class="controles-container">
                        <mat-form-field>
                          <input formControlName="importantNotes" matInput placeholder="Important Note" [(value)]="importantNotes" >
                          <mat-error>This field is mandatory.</mat-error>
                        </mat-form-field>
                      </div> -->
                  </mat-grid-tile>
                  <mat-grid-tile>
                    <!-- <mat-form-field  *ngIf="!isUpdate">
                      <mat-label>Commission Date</mat-label>
                      <input matInput [matDatepicker]="picker1" formControl="dateEnlisted" (dateChange)="startDateChange($event)" [(value)]="dateEnlisted">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field> -->
                  </mat-grid-tile>
              </mat-grid-list>

              <div class="row">
                <div class="col">
                  <div class="button-row p-flex-jc-sb">
                    <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="chooseImage($event)" />
                    <button mat-raised-button color="primary" (click)="csvInput.click()" >Add Document</button>
                  </div>
                  <div>
                      <span  *ngIf="documents.length"><a *ngFor="let doc of documents; let i=index;" target="_blank" href="{{doc.link}}" class="mr-2">{{i+1}}</a></span>
                  </div>
                </div>
                <div class="col">
                  <div class="button-row p-flex-jc-sb">
                    <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="chooseImage($event,'image')" />
                    <button mat-raised-button color="accent" (click)="csvInput1.click()" >Upload Image</button>
                  </div>
                  <div>
                      <span  *ngIf="image"><a  target="_blank" href="{{image}}" class="mr-2">Image</a></span>
                  </div>
                </div>
              </div>


          </form>

      </mat-dialog-content>
  </div>
  <div class="col-6">
    <mat-dialog-content >
     <div class="div-seating" *ngIf="selectedVehicleType">
        <div class="row" >
          <div [class]="cardCol(selectedVehicleType.seatColSize)" *ngFor="let seat of selectedVehicleType.seatsLayout; let i=index" [ngStyle]="{'border-right': seat.isleAfter ? 'border: 1px solid #0000;' : '0' }"  >
              <div *ngIf="i===0">
                  <img src="../../../assets/svgs/driver.png"  height="50px"   />
              </div>
            <div *ngIf="seat.seatNumber!='emptySpace'">
                <img src="../../../assets/svgs/car-seat-with-seatbelt_2.svg"  height="50px"  *ngIf="seat.status=='Available' || !seat.status" (click)="selectSeat(i)" />
                <img src="../../../assets/svgs/car-seat-with-seatbelt_1.svg"  height="50px"  *ngIf="seat.status=='Booked'" (click)="bookedSeat()" />
                <img src="../../../assets/svgs/car-seat-with-seatbelt_4.svg"  height="50px"  *ngIf="seat.status=='Selected'" (click)="selectedSeat()" />
                <img src="../../../assets/svgs/car-seat-with-seatbelt.svg"  height="50px" *ngIf="seat.status=='Unavailable'"  (click)="unAvailableSeat()" />
                <p class="seatNum">{{seat.seatNumber}}</p>
            </div>
        </div>
        </div>
      </div>

      <div class="row" *ngIf="selectedVehicleType">
          <div class="col-3 text-center" >
              <img src="../../../assets/svgs/car-seat-with-seatbelt_1.svg"  height="50px"  />
              <p class="seatNum">Booked</p>
          </div>
          <div class="col-3 text-center">
              <img src="../../../assets/svgs/car-seat-with-seatbelt_2.svg"  height="50px"  />
              <p class="seatNum">Available</p>
          </div>
          <div class="col-3 text-center">
              <img src="../../../assets/svgs/car-seat-with-seatbelt_4.svg"  height="50px"  />
              <p class="seatNum">Selected</p>
          </div>
          <div class="col-3 text-center">
              <img src="../../../assets/svgs/car-seat-with-seatbelt.svg"  height="50px"  />
              <p class="seatNum">Unavailable</p>
          </div>
      </div>
    </mat-dialog-content>
  </div>
</div>

<div class="hor-seperator"></div>
<div class="row p-flex-jc-sb" >
 <div col-3></div>
  <div class="button-row col-3" >
      <button mat-raised-button  (click)="onClose()" color="warn" >Cancel</button>
  </div>
  <div class="button-row col-3">
      <button mat-raised-button  (click)="addVehicle()" color="primary" [disabled]="!(managerPermission?.addFleet && form.valid)" *ngIf="!isUpdate">Add Vehicle</button>
      <button mat-raised-button  (click)="updateVehicle()" color="primary" [disabled]="!(managerPermission?.editFleet)" *ngIf="isUpdate">Update Vehicle</button>

  </div>
  <div col-3></div>
</div>
<mat-spinner *ngIf="loading" class="text-center"></mat-spinner>
