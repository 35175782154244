<div class="">

  <div class="row">
    <div class="col-6">
      <h3 class="mat-h2 title-text">Station Managers</h3>
     </div>
    <div class="col-6">
        <mat-form-field *ngIf="dataSource">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Name" #input>
      </mat-form-field>
    </div>
  </div>

  <mat-table class="full-width-table" [dataSource]="dataSource"  matSort aria-label="Elements" *ngIf="dataSource">
    <!-- Id Column -->
    <ng-container matColumnDef="userName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text">{{row.username}}</mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="userEmail">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text">{{row.email}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="userTerminal">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Terminal</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text">{{row.terminal}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="userStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let row" class="table-main-text">
        <!-- {{row.status}} -->
        <span *ngIf="row.status=='active'" class="manager-status ion-text-right"> {{row?.status}}</span>  <span *ngIf="row.status!='active'" class="manager-statusred ion-text-right"> {{row?.status}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="viewBtn">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-stroked-button (click)="viewManager(row)" class="btn-submit">View</button>

      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <div class="row">
    <div class="col-4  mt-2 align-content-center">
      <button   mat-stroked-button class="btn-submit" (click)="openAddManager()" >Add Manager</button>
    </div>
    <div class="col-8 text-right" >
      <mat-paginator paginator [length]="dataLength" [pageIndex]="0" [pageSize]="pageSize" [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
    </div>
  </div>


</div>
