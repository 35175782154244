<div class="small-table">


   <div class="row p-flex-jc-sb">
     <div class="col">
      <h3 class="title-text">Closed Trips</h3>
     </div>
     <div class="col date-align">
      <mat-form-field  >
        <mat-label>Select Date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date"  (dateChange)="startDateChange($event)">
          <input matEndDate placeholder="End date"  (dateChange)="endDateChange($event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
     </div>
   </div>
  <mat-table [dataSource]="dataSource" class="full-width-table" aria-label="Elements" *ngIf="tableData.length" >
  <!-- Id Column -->
  <ng-container matColumnDef="vehicleType">
    <mat-header-cell *matHeaderCellDef >Vehicle Type</mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="'th-left'">
      <div>
        {{row.vehicleType.vehicleType}}
        <p class="table-text" >{{row.vehicleReg}} - {{row.fleetId}} </p>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="tripTitle">
    <mat-header-cell *matHeaderCellDef >Route</mat-header-cell>
    <mat-cell *matCellDef="let row"  [ngClass]="'th-left'">
      <div>
        {{row.tripTitle}}
        <h4 class="table-text">{{row.tripDate | date:'EEE, MMM d, h:mm a'}} </h4>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="originDetails">
    <mat-header-cell *matHeaderCellDef >Origin Park</mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="'th-left'">
      <!-- {{row.originStationAddress}} -->
      {{ (row.originStationAddress.length>25)? (row.originStationAddress | slice:0:24)+'..':(row.originStationAddress) }}

    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="destinationDetails">
    <mat-header-cell *matHeaderCellDef >Stops</mat-header-cell>
    <mat-cell *matCellDef="let row"  [ngClass]="'th-left'">{{row.stops}}
    </mat-cell>
  </ng-container> -->

  <ng-container matColumnDef="totalSeats">
    <mat-header-cell *matHeaderCellDef >Total Seats</mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="'th-center'">
      <div >
        {{row.totalPassengers}}

      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="availableSeats">
    <mat-header-cell *matHeaderCellDef >Vacant Seats</mat-header-cell>
    <mat-cell *matCellDef="let row" >{{row.totalPassengers - row.paidPassengers?.length}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="paidPassengers">
    <mat-header-cell *matHeaderCellDef >Occupied</mat-header-cell>
    <mat-cell *matCellDef="let row" >{{row.paidPassengers?.length}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
    <mat-header-cell *matHeaderCellDef >Amount(NGN)</mat-header-cell>
    <mat-cell *matCellDef="let row" >{{row.tripCost|number}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef >Created Date</mat-header-cell>
      <mat-cell *matCellDef="let row" >{{row.creationDate|date:'EEE, MMM d, HH:mm'}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="downloadBtn">
    <mat-header-cell *matHeaderCellDef ></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="text-right">
        <button mat-stroked-button (click)="downloadManifest(row)" class="btn-submit-add">Manifest</button>

      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<mat-paginator paginator [length]="dataLength" [pageIndex]="0" [pageSize]="pageSize" [pageSizeOptions]="[5,10,15,20,25]" showFirstLastButtons *ngIf="dataSource"></mat-paginator >
</div>
