<div class="invoice" *ngFor="let invoice of invoiceIds; let i = index">
  <!-- <h1>Booking Ref. {{ invoice }}</h1>
  Passenger Name: {{ (invoiceDetails[i] | async)?.booking.passengerName }}
  Amount: {{ (invoiceDetails[i] | async)?.booking.amountPaid }} -->
  <div class="container" width="400px" id="invoice" >
    <div class="invoice-div">
      <div class="row">
        <div class="col-xs-12 heading patronage-text">
          <strong>BOOKING TICKET</strong>
        </div>
      </div>
        <table>
          <p></p>
          <tr><td>Passeger Name:</td><td>{{booking?.passengerName}}</td></tr>
          <tr><td>Transport Company:</td><td>{{booking?.originStation}}</td></tr>
          <tr><td>Seat No.:</td><td>{{booking?.seatNumber}}</td></tr>
          <tr><td>Destination:</td><td>{{booking?.destinationCity}} - {{booking?.destinationState}}</td></tr>
          <tr><td>Origin:</td><td>{{booking?.originStationAddress}}</td></tr>
          <tr><td>Vehicle Id:</td><td>{{booking?.fleetId}}</td></tr>
          <tr><td>Trip Date:</td><td>{{booking?.tripDate| date }}</td></tr>
          <tr><td>Payment Date:</td><td>{{booking?.paymentDate | date}}</td></tr>
          <tr><td>Amount:</td><td>N{{booking?.amountPaid |number}}</td></tr>
          <tr><td>Status:</td><td>Paid</td></tr>
          <tr><td>Ticket Number:</td><td>{{booking?.bookingId}}</td></tr>
          <div class="row mt-3">
            <div class="col patronage-text">
                <strong>THANKS FOR YOUR PATRONAGE</strong>
            </div>
          </div>
        </table>


   </div>
  </div>
</div>
