import { Component, OnInit } from '@angular/core';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { AddTripComponent } from 'src/app/modals/add-trip/add-trip.component';


@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  createTrip(){
    console.log('Open dialog')
    // this.dataService.openedVehicleType = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.panelClass ='my-custom-dialog-class';

    this.dialog.open(AddTripComponent, dialogConfig);

  }

}
