<mat-toolbar class="top-bar">
  <span class="title-text"> Create Route </span>

  <span class="fill-remaining-space"></span>

  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>

<div  class="mb-1">
  <form [formGroup]="form" class="normal-form">
    <div class="controles-container">
      <mat-form-field>
        <mat-label>Origin State</mat-label>
          <mat-select formControlName="originState" (selectionChange)="statechangeOrigin($event)" required>
            <mat-option *ngFor="let state of states; let i = index" [value]="states[i].name" >
              {{state.name}}
            </mat-option>
          </mat-select>
          <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Origin City</mat-label>
        <mat-select formControlName="originCity"  required>
          <mat-option *ngFor="let city of listedCitiesOrigin; let i=index" [value]="listedCitiesOrigin[i].city" >
            {{city.city}}
          </mat-option>
        </mat-select>
        <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Destination State</mat-label>
        <mat-select formControlName="destinationState" (selectionChange)="statechange($event)" required>
          <mat-option *ngFor="let state of states;let i=index" [value]="states[i].name" >
            {{state.name}}
          </mat-option>
        </mat-select>
        <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Destination City</mat-label>
          <mat-select formControlName="destinationCity"  required>
            <mat-option *ngFor="let city of listedCities;let i=index" [value]="listedCities[i].city" >
              {{city.city}}
            </mat-option>
          </mat-select>
        <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-select formControlName="originTerminal"  required>
          <mat-option *ngFor="let terminal of terminals;let i=index" [value]="terminals[i].stationName">
            {{terminal.stationName}}
          </mat-option>
        </mat-select>
        <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input formControlName="stops" matInput placeholder="Stops" required>
        <mat-error>This field is mandatory.</mat-error>
      </mat-form-field>

    </div>
  </form>
</div>

<div class="row p-flex-jc-sb mb-1 pb-1" >
  <div col-3></div>

  <div class="button-row col-3" >
    <button mat-raised-button  (click)="onClose()" color="warn" >Cancel</button>
  </div>

  <div class="button-row col-3" >
    <button mat-raised-button  (click)="onAdd()" class="btn-submit" [disabled]="!(form.valid)" >Add</button>
  </div>
  <div col-3></div>
</div>
