<div class="maindiv" *ngIf="permission">
  <div [ngClass] ="{'active' : showHome}"
  class="ion-padding-top"

  >
    <ion-button (click)="goDashboard()">
        <mat-icon aria-hidden="false" aria-label="Chat" >dashboard</mat-icon>
        <p>Dashboard</p>
    </ion-button>
  </div>

  <div [ngClass]="{'active' : showOperation}"

  >
    <ion-button  (click)="goOperations()">
      <mat-icon aria-hidden="false" aria-label="Chat" >build</mat-icon>
      <p>Operations</p>
    </ion-button>
  </div>
  <div [ngClass]="{'active' : showAccounting}"

  >
    <ion-button (click)="goFinance()" >
      <mat-icon aria-hidden="false" aria-label="Chat" >account_balance_wallet</mat-icon>
      <p>Acounting</p>

    </ion-button>
  </div>
  <div [ngClass]="{'active' : showFleet}"

  >
    <ion-button  (click)="goFleet()">
      <mat-icon aria-hidden="false" aria-label="Chat" >directions_bus</mat-icon>
      <p>Fleet</p>

    </ion-button>
  </div>
  <!-- <div [ngClass]="{'active' : showUsers}"

  >
    <ion-button (click)="goUser()">
      <mat-icon aria-hidden="false" aria-label="Chat" >person_outline</mat-icon>
      <p>Users</p>

    </ion-button>
  </div> -->
  <div [ngClass]="{'active' : showConfiguration}"

  >
    <ion-button (click)="goConfiguration()">
      <mat-icon aria-hidden="false" aria-label="Chat" >settings_applications</mat-icon>
      <p>Settings</p>

    </ion-button>
  </div>
</div>

