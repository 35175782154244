<div class="mb-4 ml-4" >
  <div class="mb-4 ml-4">
    <div style="display: block">
      <canvas baseChart width="400" height="400"
        [data]="doughnutChartData"
        [labels]="doughnutChartLabels"
        [chartType]="doughnutChartType"
        [colors]="barChartColors"

        >
      </canvas>
    </div>
  </div>
</div>
