<div class="mainheader">
  <div class="logodiv">
    <ion-button href="/" style="    --background: transparent;--box-shadow: none;height: auto;"><img  src="./assets/images/logo.png" ></ion-button>
    <p class="transporter-text">{{transporter}}</p>
  </div>
  <div class="otherdiv">
    <!-- <ion-searchbar mode="md" showCancelButton="focus"></ion-searchbar> -->
    <mat-form-field class="before" *ngIf="showHome">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate matInput placeholder="Start date" (dateChange)="startDateChange($event)">
        <input matEndDate matInput placeholder="End date" (dateChange)="endDateChange($event)">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <ion-button><mat-icon aria-hidden="false" aria-label="Chat" >notifications</mat-icon></ion-button>
    <ion-button><mat-icon aria-hidden="false" aria-label="Chat" >chat_bubble</mat-icon></ion-button>
    <ion-button class="profile"
    [matTooltip]="displayName"
    matTooltipClass="example-tooltip-red"
    [matMenuTriggerFor]="menu"
    >{{loggedInInitials}}</ion-button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
</div>

