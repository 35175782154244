<mat-toolbar class="top-bar">
  <span class="title-text">{{selectedManager?.username}} <span *ngIf="selectedManager.status=='active'" class="manager-status ion-text-right"> [{{selectedManager?.status}}]</span>  <span *ngIf="selectedManager.status!='active'" class="manager-statusred ion-text-right"> [{{selectedManager?.status}}]</span> </span>
  <span class="fill-remaining-space"></span>
  <button mat-raised-button  (click)="activateManager()" class="btn-submit"  *ngIf="selectedManager.status!='active'">Activate Manager</button>
  <button mat-raised-button  (click)="deactivateManager()" color="warn"  *ngIf="selectedManager.status=='active'">Deactivate Manager</button>

  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>
<mat-dialog-content *ngIf="adminPermission" class="mr-1 ml-1">
<div class="row mt-2">
  <div class="col">
    <h4 class="table-main-text">
      Manager Name
    </h4>
    <h4 class="table-main-text2">
      {{selectedManager?.username}}
    </h4>
  </div>
  <div class="col">
    <h4 class="table-main-text">
      Manager Email
    </h4>
    <h4 class="table-main-text2">
      {{selectedManager?.email}}
    </h4>
  </div>
</div>

<div class="row mt-1">
  <div class="col">
    <h4 class="table-main-text">
      Manager Phone
    </h4>
    <h4 class="table-main-text2">
      {{selectedManager?.phone}}
    </h4>
  </div>
  <div class="col">
    <h4 class="table-main-text">
      Terminal
    </h4>
    <h4 class="table-main-text2">
      {{selectedManager?.terminal}}
    </h4>
  </div>
</div>
<div class="hor-seperator"></div>
<h4 class="table-title-text"> Permissions</h4>
<div class="hor-seperator"></div>

<h2 class="permission-category"> Terminal Agents</h2>
  <div class="row">
      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.terminalAgent">Terminal Booking Agent</mat-checkbox>
      </div>

      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.terminalLoadingAgent">Terminal Loading Agent</mat-checkbox>
      </div>
  </div>
  <div class="hor-seperator"></div>
  <h2 class="permission-category"> Trip Booking</h2>
  <div class="row">
    <div class="col-6">
      <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.viewTrips">View Trip</mat-checkbox>
    </div>
    <div class="col-6">
      <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.viewTripDetails">View Trip Details</mat-checkbox>
    </div>
    <div class="col-6">
      <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.cashBooking">Cash Booking</mat-checkbox>
    </div>
    <div class="col-6">
      <mat-checkbox [disabled]="!adminPermission.superAdmin"  class="example-margin" [(ngModel)]="managerPermission.printManifest">Print Manifest</mat-checkbox>
    </div>
  </div>
  <div class="hor-seperator"></div>
  <h2 class="permission-category"> Trip Administration</h2>
    <div class="row">
      <div class="col-6">
        <mat-checkbox [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.addTrip">Add Trip</mat-checkbox>
      </div>
      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.addTripRoute">Add Trip Route</mat-checkbox>
      </div>
      <div class="col-6">
          <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.closeBooking">Close Booking</mat-checkbox>
        </div>
      <div class="col-6">
        <mat-checkbox [disabled]="!adminPermission.superAdmin"  class="example-margin" [(ngModel)]="managerPermission.viewClosedTrip">View Closed Trip</mat-checkbox>
      </div>
    </div>
    <div class="hor-seperator"></div>

    <h2 class="permission-category"> Finance / Accounting</h2>
    <div class="row">
      <div class="col-6">
          <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.viewFinancials">View Financials</mat-checkbox>
        </div >
        <div class="col-6">
          <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.addExpense">Add Expense</mat-checkbox>
        </div >
    </div>
    <div class="hor-seperator"></div>
    <h2 class="permission-category"> Administration</h2>
    <div class="row">
      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.viewSettings">View Settings</mat-checkbox>
      </div >
      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.viewManager">View Manager</mat-checkbox>
      </div >
      <div class="col-6">
        <mat-checkbox [disabled]="!adminPermission.superAdmin"  class="example-margin" [(ngModel)]="managerPermission.editManager">Edit Manager</mat-checkbox>
      </div >
      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.editStation">Edit Station</mat-checkbox>
      </div >

      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.addFleet">Add Fleet</mat-checkbox>
      </div >
      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.viewFleet">View Fleet</mat-checkbox>
      </div >
      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.editFleet">Edit Fleet</mat-checkbox>
      </div >

      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.addMaintenance">Add Maintenance</mat-checkbox>
      </div >
      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.dashboardUser">Dashboard User</mat-checkbox>
      </div >

      <div class="col-6">
        <mat-checkbox  [disabled]="!adminPermission.superAdmin" class="example-margin" [(ngModel)]="managerPermission.superAdmin">Super User</mat-checkbox>
      </div >

    </div>
      <div class="hor-seperator"></div>
    <div class="row">
      <div class="col-8">
        <mat-form-field class="example-full-width" *ngIf="terminals.length">
          <mat-label class="example-margin"> Manager Terminal</mat-label>
          <mat-select  name="originaddr" required class="text-input" (selectionChange)="changeTermial($event)" >
            <mat-option *ngFor="let type of terminals; let i=index" [value]="terminals[i]" class="example-margin">
              {{type.stationName}} - {{type.stationAddress}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="hor-seperator"></div> -->

    </mat-dialog-content>

    <div class="hor-seperator"></div>
  <div class="row p-flex-jc-sb" >
  <div col-3></div>
    <div class="button-row col-3" >
        <button mat-raised-button  (click)="onClose()" color="warn" >Cancel</button>
    </div>
    <div class="button-row col-3">
        <button mat-raised-button  (click)="updateManager()" class="btn-submit" >Update Manager</button>

    </div>
    <div col-3></div>
  </div>
