
<div class=" small-table">


   <div class="row p-flex-jc-sb">
     <div class="col">
      <h3 class="title-text">Online Bookings</h3>
     </div>
     <div class="col date-align">
      <mat-form-field >
        <mat-label>Select Date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date"  (dateChange)="startDateChange($event)">
          <input matEndDate placeholder="End date"  (dateChange)="endDateChange($event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
     </div>
   </div>
   <div *ngIf="dataSource">


  <table mat-table [dataSource]="dataSource" class="full-width-table" aria-label="Elements"  >
  <!-- Id Column -->
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef >Date  </mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'">
      {{row.date}}
    </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="totalBooking">
    <mat-header-cell *matHeaderCellDef [ngClass]="'th-center'">Bookings(Num) </mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-center'"> {{row.totalBooking}}

    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amountPaid">
    <mat-header-cell *matHeaderCellDef >Amount Paid </mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'">N {{row.amountPaid| number: '0.2-2'}}
    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="amountDue">
    <mat-header-cell *matHeaderCellDef >Amount Due </mat-header-cell>
    <mat-cell *matCellDef="let row" class="table-main-text" [ngClass]="'th-left'">N {{row.amountDue | number: '0.2-2' }}
    </mat-cell>
  </ng-container> -->

  <ng-container matColumnDef="btnView">
    <mat-header-cell *matHeaderCellDef >   </mat-header-cell>
    <mat-cell *matCellDef="let row">
        <button mat-stroked-button (click)="viewBookings(row)" class="btn-submit-p">View</button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="btnDownload">
    <mat-header-cell *matHeaderCellDef > </mat-header-cell>
    <mat-cell *matCellDef="let row">
        <button mat-stroked-button (click)="download(row)" class="btn-submit"><mat-icon>download</mat-icon></button>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>
</div>

<mat-paginator paginator [length]="dataLength" [pageIndex]="0" [pageSize]="pageSize" [pageSizeOptions]="[5,10,15,20]" showFirstLastButtons></mat-paginator>
</div>

